import { isBluesightEmail } from 'common';
import moment from 'moment';

const getSubdomain = hostName => hostName.split('.')[0];

// Register user properties for Pendo
const setupAnalytics = (currentUser, allUsers) => {
  const { location, pendo } = window;
  const {
    caseSafeAccountId,
    created,
    email,
    enabled,
    firstName,
    fullName,
    lastName,
    permissions,
    roles,
  } = currentUser;
  const allUsersCreated = allUsers
    .filter(u => !RegExp(/VIEW ONLY/, 'g').test((u.roles || []).join('')))
    .map(u => u.created);

  try {
    // Used for Pendo
    const userProperties = {
      // App user roles
      userRoles: roles.join(', '),
      // User name
      userName: fullName,
      // User firstName
      firstName: firstName,
      // User lastName
      lastName: lastName,
      // User currently enabled?
      isEnabled: enabled,
      // Protenus employee? (detect based on email)
      isProtenusEmployee: isBluesightEmail(email),
      // View only Protenus Employee, using the View Only role
      isViewOnly: roles.includes('VIEW ONLY'),
      // Privacy user? (detect based on permission)
      isPrivacyUser: permissions.includes('PRIVACY_ASSESSMENT_VIEW'),
      // Diversion user? (detect based on permission)
      isDiversionUser: permissions.includes('MEDICATION_STATISTICS_VIEW'),
      // GRC user? (detect based on permission)
      isGRCUser: permissions.includes('CASE_CREATE_GRC'),
      // App user creation date
      userCreated: moment(created).toISOString(),
      // Earliest app user creation date (all users -> filter by not having VIEW_ONLY role -> pick min creation date)
      earliestUserCreated: moment(
        allUsersCreated.reduce(
          (pre, cur) => (moment(pre).isAfter(moment(cur)) ? cur : pre),
          []
        )
      ).toISOString(),
      // customer.protenus.com -> customer
      Account: getSubdomain(location.hostname),
    };

    // Identify Pendo User
    if (roles && permissions) {
      pendo.initialize({
        visitor: {
          id: email,
          ...userProperties,
        },
        account: {
          id: getSubdomain(location.hostname),
          ...(caseSafeAccountId
            ? { case_safe_account_id__c: caseSafeAccountId }
            : {}),
        },
        // set so that we do not pass any text from the UI to pendo for security reasons
        excludeAllText: true,
        allowedText: [
          'Add Action',
          'Add Attachments',
          'Add note',
          'Add Note From Template',
          'Resolve Case',
          'Send an Email from Template',
          'Set Final Assessment',
          'My Account',
          'Logout',
        ],
      });
    }
  } catch (e) {
    // uncomment below to debug
    // console.error(e);
  }
};

export default setupAnalytics;
