import queryString from 'query-string';
import { bluesightDomains, PagedStore } from 'common';
import { action, computed, decorate, observable } from 'mobx';
import { CREATE_PATH, VIEW_PATH, UPDATE_PATH } from '../../const';

const DEFAULT_PAGE_SIZE = 1000;
const DEFAULT_SORT = 'lastName,asc';

class CustomerAdministeredAccountsListStore extends PagedStore {
  constructor({ appUserClient, permissionStore }) {
    super();

    this.appUserClient = appUserClient;
    this.permissionStore = permissionStore;

    this.refresh();
  }

  pageSize = DEFAULT_PAGE_SIZE;
  sort = DEFAULT_SORT;
  hideProtenusUsers = false;

  // Computed
  get appUsers() {
    return this.results;
  }

  get sortBy() {
    const { sortBy } = this.parseSort(this.sort);
    return sortBy;
  }

  get sortDir() {
    const { sortDir } = this.parseSort(this.sort);
    return sortDir;
  }

  get isAdmin() {
    return this.permissionStore.inRole('ADMIN');
  }

  get showHideProtenusUsersLinkText() {
    return `${this.hideProtenusUsers ? 'Show' : 'Hide'} Bluesight Users`;
  }

  get showHideProtenusUsersLinkQueryString() {
    let queryString = this.getFiltersAsQueryString();
    if (!this.hideProtenusUsers) {
      queryString += '&hideProtenusUsers=true';
    } else {
      queryString = queryString.replace(
        'hideProtenusUsers=true',
        'hideProtenusUsers=false'
      );
    }

    return queryString;
  }

  get viewRoute() {
    return `${VIEW_PATH}${this.getFiltersAsQueryString()}`;
  }

  get createRoute() {
    return `${CREATE_PATH}${this.getFiltersAsQueryString()}`;
  }

  get updateRoute() {
    return `${UPDATE_PATH}${this.getFiltersAsQueryString()}`;
  }

  // Actions
  setFilters = query => {
    const { sort, hideProtenusUsers } = query;

    this.sort = sort || DEFAULT_SORT;
    this.hideProtenusUsers = hideProtenusUsers === 'true';

    this.refresh();
  };

  // Helpers/Overrides
  fetch() {
    const params = {
      sort: this.sort,
      size: this.pageSize,
      regex:
        !this.isAdmin || (this.isAdmin && this.hideProtenusUsers)
          ? `^((?!@(${bluesightDomains.join('|')})).)*$`
          : '',
    };

    return this.appUserClient.findByEmailRegex(params);
  }

  parseSort(sort) {
    const splits = sort.split(',');
    return {
      sortBy: splits[0],
      sortDir: splits[1],
    };
  }

  getFiltersAsQueryString() {
    const query = queryString.stringify({
      sort: this.sort || DEFAULT_SORT,
      size: this.pageSize || DEFAULT_PAGE_SIZE,
      hideProtenusUsers: this.hideProtenusUsers || undefined,
    });

    return `?${query}`;
  }
}

decorate(CustomerAdministeredAccountsListStore, {
  sort: observable,
  hideProtenusUsers: observable,
  appUsers: computed,
  isAdmin: computed,
  sortBy: computed,
  sortDir: computed,
  viewRoute: computed,
  createRoute: computed,
  updateRoute: computed,
  showHideProtenusUsersLinkText: computed,
  showHideProtenusUsersLinkQueryString: computed,
  setFilters: action,
});

export { CustomerAdministeredAccountsListStore };
