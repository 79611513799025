import React from 'react';
import PropTypes from 'prop-types';
import { DoubleLineCell } from '../../..';
import moment from 'moment';

const CreatedByCell = ({ date, person }) => {
  const lineOne = moment(date).format('l');
  // when the created by is empty that means that it was Bluesight created
  const lineTwo =
    person === null
      ? 'Bluesight'
      : `${person.firstName || 'Unknown'} ${person.lastName || 'Unknown'}`;

  return <DoubleLineCell lineOne={lineOne} lineTwo={lineTwo} />;
};

export default CreatedByCell;

CreatedByCell.propTypes = {
  date: PropTypes.string,
  person: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
};

CreatedByCell.defaultProps = {
  date: '',
  person: null,
};
