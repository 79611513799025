import moment from 'moment';

function matchAssessment(minEventDate, maxEventDate, analyticAssessmentLogs) {
  return (analyticAssessmentLogs || []).filter(
    r =>
      moment(r.parameters?.dateRange[0]).isSame(minEventDate, 'day') &&
      moment(r.parameters?.dateRange[1]).isSame(maxEventDate, 'day')
  );
}

/**
 * @param caseCreated
 * @param minEventDate
 * @param maxEventDate
 * @param analyticAssessmentLogs
 * @returns {moment.Moment}
 */
export function computeAssessmentAddedDate(
  caseCreated,
  minEventDate,
  maxEventDate,
  analyticAssessmentLogs
) {
  const matched = matchAssessment(
    minEventDate,
    maxEventDate,
    analyticAssessmentLogs || []
  );
  return matched.length
    ? moment(matched[0].created).utc()
    : moment(caseCreated)
        .add(1, 's')
        .utc();
}

/**
 * @param minEventDate
 * @param maxEventDate
 * @param analyticAssessmentLogs
 * @returns {String} createdBy fullName
 */
export function computeAssessmentAddedBy(
  minEventDate,
  maxEventDate,
  analyticAssessmentLogs
) {
  const matched = matchAssessment(
    minEventDate,
    maxEventDate,
    analyticAssessmentLogs || []
  );
  return matched.length && matched[0] && matched[0].createdBy
    ? matched[0].createdBy.fullName
    : 'Bluesight';
}
