import React from 'react';
import { AsidePanel, TimePlusCalendarSelector } from '../../ui';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { DropdownList } from 'react-widgets';
import DropdownItem from '../../incident/IncidentAdvancedFilters/DropdownItem';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import moment from 'moment';

/* eslint-disable no-unused-vars */
const AuditLogAdvancedFilters = ({
  toggleOpen,
  history,
  dateFilterOptions,
  query,
  isAdvancedFiltersOpen,
  selectedUsers,
  selectedEmployees,
  selectedPatients,
  selectedRange,
  setSelectedPeople,
  setSelectedDateRange,
  setSelectedCalendarRange,
  protenusUsers,
}) => {
  const formatFilterDate = date => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    return { value: formattedDate };
  };

  return (
    <AsidePanel
      title="Advanced Filters"
      leftAnimation
      isOpen={isAdvancedFiltersOpen}
      onClose={toggleOpen}
    >
      <div className={styles.values}>
        <DropdownList
          placeholder="Bluesight User"
          data={protenusUsers}
          value={selectedUsers[0]}
          onChange={user => {
            setSelectedPeople('userFullNames', user, history.push);
          }}
          textField="label"
          valueField="value"
        />
        <TimePlusCalendarSelector
          CustomListItem={DropdownItem}
          filterKey="range"
          createdInformation={[selectedRange.after, selectedRange.before]}
          label="Time"
          options={dateFilterOptions.slice()}
          defaultValue={selectedRange.default}
          onCalendarChange={(date, valid, beforeOrAfter) => {
            if (valid) {
              setSelectedCalendarRange(
                formatFilterDate(date),
                history.push,
                beforeOrAfter
              );
            }
          }}
          onChangeDropdown={date => setSelectedDateRange(date, history.push)}
          hiddenCalendar={selectedRange.default === 'custom'}
          restrictFutureDate
        />
      </div>
    </AsidePanel>
  );
};

AuditLogAdvancedFilters.propTypes = {
  query: PropTypes.shape({
    range: PropTypes.string,
    rangeAfter: PropTypes.string,
    rangeBefore: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  dateFilterOptions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.string),
  userFilterOptions: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({})
  ),
  toggleOpen: PropTypes.func.isRequired,
  isAdvancedFiltersOpen: PropTypes.bool.isRequired,
  selectedUsers: PropTypes.array,
  selectedEmployees: PropTypes.array,
  selectedPatients: PropTypes.array,
  selectedRange: PropTypes.shape({
    default: PropTypes.string,
    after: PropTypes.string,
    before: PropTypes.string,
  }),
  setSelectedPeople: PropTypes.func,
  setSelectedDateRange: PropTypes.func,
  setSelectedCalendarRange: PropTypes.func,
  protenusUsers: PropTypes.array,
};

export default AuditLogAdvancedFilters;
