import { action, computed, decorate, observable, reaction } from 'mobx';

import ChartStore from '../ChartStore';
import RelationshipStore from '../../../stores/RelationshipStore';
import Store from '../Store';

class SmartReferenceStore {
  constructor() {
    reaction(
      () => [ChartStore.yKeys],
      () => {
        if (this.correctFocus && Store.userIdParam) {
          this.sync();
        }
      }
    );
    // this func can be called from the sys alert banner. Bind to make sure has the proper 'this'
    this.reset = this.reset.bind(this);
  }
  // Observable
  smartReference = JSON.parse(localStorage.getItem('smartReference'));
  smartReferenceMap = new Map();

  // Action
  reset() {
    localStorage.removeItem('smartReference');
    this.smartReference = {};
    this.smartReferenceMap.clear();
  }

  // Action
  setSmartReference(smartReferenceObj) {
    localStorage.setItem('smartReference', JSON.stringify(smartReferenceObj));
    this.smartReference = smartReferenceObj;
  }

  // Computed
  get ids() {
    return this.smartReference?.ids;
  }

  // Computed
  get caseLink() {
    return this.smartReference.caseLink;
  }

  // Computed
  get caseNumber() {
    return this.smartReference.caseNumber;
  }

  // Computed
  get caseUserId() {
    return this.smartReference.caseUserId;
  }

  // Computed
  get description() {
    return this.smartReference.description;
  }

  // Computed
  // Note: this property is only provided from ui-factory multi-incident smart references,
  // since multi-incident cases are only fully-functional in ui-factory.
  get isMultiIncident() {
    return this.smartReference?.isMultiIncident ?? false;
  }

  // Computed
  get correctFocus() {
    if (!Object.keys(this.smartReference || {}).length) return false;
    const { type } = this.smartReference;
    return type === this.otherFocus;
  }

  get otherFocus() {
    return Store.focus === 'patient' ? 'user' : 'patient';
  }

  userAliases(userId) {
    const relationships = RelationshipStore.userRelationships.get(userId);
    if (relationships) {
      return relationships.filter(r => r.relationships.includes('alias'));
    }
    return null;
  }

  isAliasPair = (userId1, userId2) => {
    const aliases = this.userAliases(userId1) || [];
    if (!aliases.length) return userId1 === userId2;
    return aliases.some(alias => alias.ids.some(entry => entry.id === userId2));
  };

  // Make sure that the user hasn't navigated away from the user attached to the case or any of their aliases users.
  // if they have, clear out the smart references
  sync = () => {
    if (!Object.keys(this.smartReference || {}).length) return;
    const aliases = this.userAliases(this.caseUserId);
    if (!aliases || !aliases.length) return;

    if (!this.isAliasPair(this.caseUserId, Store.userIdParam)) this.reset();
  };

  // Call this method when the activity view user changes. We will leave things
  // be if the new user is an alias of the old one. Otherwise, reset the
  // smart reference data.
  userChanged = (oldUserId, newUserId) => {
    if (!this.isAliasPair(oldUserId, newUserId)) this.reset();
  };

  isSmartReference(rowData) {
    if (!this.ids) return false;
    const { patient } = rowData.values[0];
    if (!patient) return false;
    if (this.ids.find(smartRefId => smartRefId === patient.id)) return true;
    return false;
  }

  setMap = rows => {
    rows.forEach(row => {
      if (this.isSmartReference(row)) this.smartReferenceMap.set(row.key, row);
    });
  };
}

decorate(SmartReferenceStore, {
  reset: action,
  setSmartReference: action,
  userChanged: action,
  caseLink: computed,
  caseUserId: computed,
  correctFocus: computed,
  description: computed,
  isMultiIncident: computed,
  smartReference: observable,
  smartReferenceMap: observable,
});

export default new SmartReferenceStore();
