import {
  action,
  autorun,
  computed,
  decorate,
  observable,
  reaction,
} from 'mobx';
import moment from 'moment';
import queryString from 'query-string';

import {
  RouterContainer,
  DownloadUtils,
  BaseURL,
  DateHelpers,
  PermissionStore,
  PagedStore,
  LoginStore,
  CaseClient,
  AlertCategoryStore,
  UserInfoStore,
  PatientInfoStore,
  GroupStore,
  convertMultiValue,
} from 'common';
import FiltersBannerStore from '../../../stores/FiltersBannerStore';
import CaseResultsFiltersStore from '../../../stores/CaseResultsFiltersStore';
import {
  prepForDisplay,
  queryForSelectionChange,
  queryForToggle,
} from '../../../stores/CaseFilterHelpers';
import commaSeparatedToArray from '../../../utils/commaSeparatedToArray';
import PinnedTimezoneStore from '../../../stores/PinnedTimezoneStore';

const EXPORT_CASE_COUNT = 10000;

class CaseResultsStore extends PagedStore {
  constructor({
    alertCategoryStore,
    caseResultsFiltersStore,
    pinnedTimezoneStore = {},
  }) {
    super();
    this.alertCategoryStore = alertCategoryStore;
    this.caseResultsFiltersStore = caseResultsFiltersStore;
    this.pinnedTimezoneStore = pinnedTimezoneStore;

    autorun(() => {
      this.configureTimeToResolve();
    });

    reaction(
      () => [
        this.allowFetch,
        this.params,
        this.sort,
        this.pinnedTimezoneStore.storedTimezone,
      ],
      () => {
        this.refresh();
      }
    );

    reaction(
      () => ({
        filters: this.stringifiedFiltersForLocalStorage,
        rememberFilters: this.rememberFilters,
        showFilters: this.showFilters,
      }),
      ({ filters, rememberFilters, showFilters }) => {
        const key = 'advancedFilters';
        if (!showFilters) {
          // If we are not showing advanced filters, take no action. We don't
          // want to save or remove quick filters.
          return;
        }

        // Save current filters if rememberFilters is on. Otherwise, clear them.
        // We want to clear them only when the advanced filters are showing
        // and you have explicitly unchecked the box.
        if (rememberFilters) {
          localStorage.setItem(key, filters);
        } else {
          localStorage.removeItem(key);
        }
      }
    );

    reaction(
      () => this.user && UserInfoStore.fetch(this.user),
      () => {
        if (!this.user) return;
        this.userInfo = UserInfoStore.infoById.get(this.user);
      }
    );

    reaction(
      () => this.patient && PatientInfoStore.fetch(this.patient),
      () => {
        if (!this.patient) return;
        this.patientInfo = PatientInfoStore.infoById.get(this.patient);
      }
    );
  }

  removeAdvFilters() {
    const params = { resolution: 'null' };
    if (PermissionStore.has('CASE_MODIFY_NOTES_OWNED'))
      params.owner = LoginStore.id;
    if (FiltersBannerStore.shouldShowMsg) FiltersBannerStore.dismissMsg();

    RouterContainer.go('/cases', params);
  }

  // Actions
  toggleAdvFilters = e => {
    e.preventDefault();

    if (this.showFilters) {
      this.removeAdvFilters();
    } else {
      this.navigateToAdvancedFilters(RouterContainer.query);
    }
  };

  // Observables
  allowFetch = false;
  owner = null;
  user = null;
  userInfo = null;
  patient = null;
  patientInfo = null;
  name = null;
  resolution = null;
  resolutionDescription = null;
  sort = 'lastModified,desc';

  // Advanced Filters
  showFilters = false;
  excludeFilters = null;
  ownerExclude = false;
  createdByExclude = false;
  resolvedByExclude = false;
  bundleExclude = false;
  createdExclude = false;
  lastModifiedExclude = false;
  violationDateExclude = false;
  resolutionDateExclude = false;
  resolutionDescriptionExclude = false;
  timeToResolveExclude = false;
  actionsTakenExclude = false;
  resolutionExclude = false;
  userTypeExclude = false;
  userTagsExclude = false;
  suspicionScoreExclude = false;
  groupExclude = false;

  // The routing strategy used will ensure that either the range *or* the
  // After/Before observables will be set. Calculations are handled in the
  // setDateParams function.
  createdRange = null;
  createdAfter = null;
  createdBefore = null;

  lastModifiedRange = null;
  lastModifiedAfter = null;
  lastModifiedBefore = null;

  violationDateRange = null;
  violationDateAfter = null;
  violationDateBefore = null;

  resolutionDateRange = null;
  resolutionDateAfter = null;
  resolutionDateBefore = null;

  timeToResolve = null;
  timeToResolveLessThan = null;
  timeToResolveGreaterThan = null;

  // Advanced Filters -- Case Detail
  createdBy = null;
  group = null;
  category = null;
  bundle = null;
  resolvedBy = null;
  actionsTaken = null;

  actionsDateRange = null;
  actionsDateAfter = null;
  actionsDateBefore = null;
  suspicionScore = null;

  // Advanced Filters -- EMR Users
  userOrganization = null;
  userDepartment = null;
  userType = null;
  userTags = null;

  // Advanced Filters -- Remembering
  // Set initial value to what is saved in localStorage.
  rememberFilters = (() => {
    try {
      return JSON.parse(localStorage.getItem('rememberFilters')) || false;
    } catch (e) {
      return false;
    }
  })();

  queryForToggle = (filter, forFilter) =>
    queryForToggle(filter, forFilter, this);

  queryForSelectionChange = (filter, values) =>
    queryForSelectionChange(filter, values, this.excludeFilters);

  // Computed
  get displayOwner() {
    return prepForDisplay(this.owner, this.caseResultsFiltersStore.caseOwners);
  }

  // Computed
  get displayCreatedBy() {
    return prepForDisplay(
      this.createdBy,
      this.caseResultsFiltersStore.caseCreators
    );
  }

  // Computed
  get displayCategory() {
    return prepForDisplay(
      this.category,
      this.caseResultsFiltersStore.alertCategoryNames
    );
  }

  // Computed
  get displayBundle() {
    return prepForDisplay(
      this.bundle,
      this.caseResultsFiltersStore.caseBundles
    );
  }

  // Computed
  get displayResolution() {
    return prepForDisplay(
      this.resolution,
      this.caseResultsFiltersStore.resolutions
    );
  }

  get displayResolutionDescription() {
    return prepForDisplay(
      this.resolutionDescription,
      this.caseResultsFiltersStore.resolutionDescriptionOptions
    );
  }

  // Computed
  get displayResolvedBy() {
    return prepForDisplay(
      this.resolvedBy,
      this.caseResultsFiltersStore.caseResolvers
    );
  }

  // Computed
  get displayActions() {
    return prepForDisplay(
      this.actionsTaken,
      this.caseResultsFiltersStore.caseActions
    );
  }

  // Computed
  get displayDepartment() {
    return prepForDisplay(
      this.userDepartment,
      this.caseResultsFiltersStore.userDepartments
    );
  }

  // Computed
  get displayOrganization() {
    return prepForDisplay(
      this.userOrganization,
      this.caseResultsFiltersStore.userOrganizations
    );
  }

  // Computed
  get displayUserType() {
    return prepForDisplay(
      this.userType,
      this.caseResultsFiltersStore.userTypes
    );
  }

  // Computed
  get allowedGroupsFromSelection() {
    return commaSeparatedToArray(this.group).filter(id =>
      (this.caseResultsFiltersStore.groups || []).find(group => group.id === id)
    );
  }

  // Computed
  get displayGroup() {
    return prepForDisplay(
      this.allowedGroupsFromSelection,
      this.caseResultsFiltersStore.groups
    );
  }

  // Computed
  get displayTags() {
    return prepForDisplay(this.userTags, this.caseResultsFiltersStore.userTags);
  }

  configureTimeToResolve() {
    const timeToResolve = this.timeToResolve;

    /**
     * Convert a number of days to milliseconds
     * @param {Number} days   - the number of days to convert to milliseconds
     * @return {Number} the number of milliseconds
     */
    function daysToMS(days) {
      return days * (24 * 3600) * 1000; // 24 hours in a day, 3600 seconds in an hour, 1000 milliseconds in a second
    }

    switch (timeToResolve) {
      case 'lessThan7':
        this.timeToResolveLessThan = daysToMS(7);
        this.timeToResolveGreaterThan = null;
        break;
      case 'lessThan30':
        this.timeToResolveLessThan = daysToMS(30);
        this.timeToResolveGreaterThan = null;
        break;
      case 'lessThan60':
        this.timeToResolveLessThan = daysToMS(60);
        this.timeToResolveGreaterThan = null;
        break;
      case 'lessThan90':
        this.timeToResolveLessThan = daysToMS(90);
        this.timeToResolveGreaterThan = null;
        break;
      case 'moreThan90':
        this.timeToResolveLessThan = null;
        this.timeToResolveGreaterThan = daysToMS(90);
        break;
      default:
        this.timeToResolveLessThan = null;
        this.timeToResolveGreaterThan = null;
    }
  }
  /**
   * Computed
   * Getter method for the request parameters. Useful if there are
   * multiple methods that need to make a request (for example, fetch and
   * downloading a CSV file).
   * @return {Object} parameters
   */
  get params() {
    /**
     * Helper method for setting date parameters on the request, interpreted from
     * the range query parameter. Will look introspectively at the properties on
     * the instance of the store to determine the proper values.
     *
     * @param {Object} context      - the execution context for the method;
     *                                usually a reference to the store instance
     * @param {String} attr         - the attribute name of the range to interpret
     * @param {Object} dest         - the filter parameters Object for the request
     * @param {String} requestAttr  - (Optional) alternate attribute root to use for
     *                                the parameter (useful for attributes like
     *                                actionsDate => actions.date)
     *
     * @example
     *   this.createdRange = 'week';
     *   setDateParams(this, 'created', {owner: this.owner, ...});
     *   // >> {owner: this.owner, createdAfter: '2016-09-21', createdBefore: '2016-09-28'}
     */
    function setDateParams(context, attr, dest, requestAttr) {
      requestAttr = requestAttr || attr;

      if (context[`${attr}After`] || context[`${attr}Before`]) {
        dest[`${requestAttr}After`] = moment(context[`${attr}After`]);
        if (context[`${attr}Before`]) {
          dest[`${requestAttr}Before`] = moment(context[`${attr}Before`]);
        }
      } else if (context[`${attr}Range`]) {
        const range = DateHelpers.rangeForPeriod(
          context[`${attr}Range`],
          [moment().subtract(1, 'month'), moment()],
          [null, null]
        );
        dest[`${requestAttr}After`] = range[0];
        dest[`${requestAttr}Before`] = range[1];
      }
    }

    /**
     * Mutator helper method to set the correct values for the violationDate
     * filter, since it is the only filter to use completely different request
     * parameters than the properties being managed here in the store.
     *
     * @param {Object} context      - the execution context for the method;
     *                                usually a reference to the store instance
     * @param {Object} params - The request parameter Object to mutate.
     */
    function setViolationDateParams(context, params) {
      // reassign the violationDate properties to minEventDate and maxEventDate
      setDateParams(context, 'violationDate', params);

      if (params.violationDateAfter) {
        params.minEventDateAfter = params.violationDateAfter;
        delete params.violationDateAfter;
      }

      if (params.violationDateBefore) {
        params.maxEventDateBefore = DateHelpers.getStartOfNextDay(
          params.violationDateBefore
        );
        delete params.violationDateBefore;
      }
    }

    const params = {
      user: this.user,
      patient: this.patient,
      name: this.name,
      sort: this.sort,

      // Advanced Filters -- Time Range
      timeToResolveLessThan: this.timeToResolveLessThan,
      timeToResolveGreaterThan: this.timeToResolveGreaterThan,
    };

    params.owner = convertMultiValue(this.owner);
    params.createdBy = convertMultiValue(this.createdBy);
    params.group = convertMultiValue(this.group);
    params['category.name'] = convertMultiValue(this.category);
    params.bundle = convertMultiValue(this.bundle);
    params.resolvedBy = convertMultiValue(this.resolvedBy);
    params['actions.type'] = convertMultiValue(this.actionsTaken);

    if (this.suspicionScore) {
      params.suspicionScoreGreaterThan = this.suspicionScore;
      params.suspicionScoreLessThan = 1.0;
    }

    // Advanced Filters -- Get Times
    setDateParams(this, 'created', params);
    setDateParams(this, 'lastModified', params);
    setDateParams(this, 'resolutionDate', params);
    setDateParams(this, 'actionsDate', params, 'actions.date');

    setViolationDateParams(this, params);

    params.departments = convertMultiValue(this.userDepartment);

    params['userSnapshot.roles.organization'] = convertMultiValue(
      this.userOrganization
    );
    params.userType = convertMultiValue(this.userType);
    params.userTags = convertMultiValue(this.userTags);
    params.resolutionDescription = convertMultiValue(
      this.resolutionDescription
    );

    if (this.resolution) {
      params.resolution = convertMultiValue(this.resolution);
    }

    if (this.excludeFilters) {
      const excFilterParams = convertMultiValue(this.excludeFilters);
      const actionsIdx = excFilterParams.indexOf('actionsTaken');
      const violationDateIdx = excFilterParams.indexOf('violationDate');

      if (actionsIdx > -1) {
        params['actions.excludeFilters'] = ['actions'];
      }

      if (params.minEventDateAfter) {
        if (violationDateIdx > -1) {
          excFilterParams.splice(violationDateIdx, 1, 'minEventDate');
        }
      }

      if (params.maxEventDateBefore) {
        if (violationDateIdx > -1) {
          excFilterParams.splice(violationDateIdx > -1, 1, 'maxEventDate');
        }
      }

      params.excludeFilters = excFilterParams;
    }

    Object.keys(params).forEach(p => {
      if (!params[p]) delete params[p];
    });

    const timezone = this.pinnedTimezoneStore.storedTimezone;
    DateHelpers.shiftDateEntry('minEventDateAfter', params, timezone);
    DateHelpers.shiftDateEntry('maxEventDateBefore', params, timezone);

    return params;
  }

  fetch() {
    return CaseClient.getReport(this.params, [
      'minEventDateAfter',
      'maxEventDateBefore',
    ]);
  }

  refresh() {
    if (this.allowFetch) {
      return super.refresh();
    }
  }

  /**
   * Retrieve all of the IDs for a specific case query (as defined by the
   * params). This is used primarily for building up the list necessary for a
   * bulk case action.
   * @param {Number} pageSize - (optional) pageSize value that can override the default.
   * @return {Promise}        - an XmlHttpRequest Promise Object.
   */
  fetchIds() {
    return CaseClient.getIds(this.params, this.sort);
  }

  // Computed
  get resultsCount() {
    if (!this.response) return 0;
    const { page } = this.response;
    return page.totalElements || 0;
  }

  // Computed
  get cases() {
    return this.results instanceof Array ? this.results : [];
  }

  // Computed
  get stringifiedFiltersForLocalStorage() {
    return JSON.stringify({
      owner: this.owner,
      user: this.user,
      resolution: this.resolution,
      resolutionDescription: this.resolutionDescription,
      sort: this.sort,
      name: this.name,
      patient: this.patient,
      showFilters: this.showFilters,
      createdRange: this.createdRange,
      createdAfter: this.createdAfter,
      createdBefore: this.createdBefore,
      lastModifiedRange: this.lastModifiedRange,
      lastModifiedAfter: this.lastModifiedAfter,
      lastModifiedBefore: this.lastModifiedBefore,
      violationDateRange: this.violationDateRange,
      violationDateBefore: this.violationDateBefore,
      violationDateAfter: this.violationDateAfter,
      resolutionDateRange: this.resolutionDateRange,
      resolutionDateAfter: this.resolutionDateAfter,
      resolutionDateBefore: this.resolutionDateBefore,
      timeToResolve: this.timeToResolve,
      createdBy: this.createdBy,
      group: this.group,
      category: this.category,
      bundle: this.bundle,
      resolvedBy: this.resolvedBy,
      actionsTaken: this.actionsTaken,
      actionsDateRange: this.actionsDateRange,
      actionsDateAfter: this.actionsDateAfter,
      actionsDateBefore: this.actionsDateBefore,
      suspicionScore: this.suspicionScore,
      userOrganization: this.userOrganization,
      userDepartment: this.userDepartment,
      userType: this.userType,
      userTags: this.userTags,
      excludeFilters: this.excludeFilters,
    });
  }

  // Computed
  get sortAttr() {
    return (this.sort || '').split(',')[0];
  }

  // Computed
  get sortDir() {
    return (this.sort || '').split(',')[1];
  }

  // Action
  setAllowFetch = allowFetch => {
    this.allowFetch = allowFetch;
  };

  // Action
  reset = () => {
    this.owner = null;
    this.user = null;
    this.resolution = null;
    this.resolutionDescription = null;
    this.sort = 'lastModified,desc';
    this.name = null;
    this.patient = null;
    this.excludeFilters = null;
    // Advanced Filters
    this.showFilters = null;

    this.createdRange = null;
    this.createdAfter = null;
    this.createdBefore = null;

    this.lastModifiedRange = null;
    this.lastModifiedAfter = null;
    this.lastModifiedBefore = null;

    this.violationDateRange = null;
    this.violationDateAfter = null;
    this.violationDateBefore = null;

    this.resolutionDateRange = null;
    this.resolutionDateAfter = null;
    this.resolutionDateBefore = null;

    this.timeToResolve = null;
    this.timeToResolveLessThan = null;
    this.timeToResolveGreaterThan = null;

    // Advanced Filters -- Case Detail
    this.createdBy = null;
    this.group = null;
    this.category = null;
    this.bundle = null;
    this.resolvedBy = null;
    this.actionsTaken = null;
    this.actionsDateRange = null;
    this.actionsDateAfter = null;
    this.actionsDateBefore = null;
    this.suspicionScore = null;

    // this.Advanced Filters -- EMR Users
    this.userOrganization = null;
    this.userDepartment = null;
    this.userType = null;
    this.userTags = null;

    // Reset underlying store state.
    super.clear();
    this.response = null;
  };

  // Action
  setFilters = query => {
    this.owner = query.owner || null;
    this.user = query.user;
    this.resolution = query.resolution;
    this.resolutionDescription = query.resolutionDescription;
    this.sort = query.sort || this.sort;
    this.name = query.name;
    this.patient = query.patient;

    // Advanced Filters
    this.showFilters = query.showFilters;

    // Advanced Filters -- Time Range
    this.createdRange = query.createdRange;
    this.createdAfter = query.createdAfter;
    this.createdBefore = query.createdBefore;
    this.lastModifiedRange = query.lastModifiedRange;
    this.lastModifiedAfter = query.lastModifiedAfter;
    this.lastModifiedBefore = query.lastModifiedBefore;
    this.violationDateRange = query.violationDateRange;
    this.violationDateBefore = query.violationDateBefore;
    this.violationDateAfter = query.violationDateAfter;
    this.resolutionDateRange = query.resolutionDateRange;
    this.resolutionDateAfter = query.resolutionDateAfter;
    this.resolutionDateBefore = query.resolutionDateBefore;
    this.timeToResolve = query.timeToResolve;

    // Advanced Filters -- Case Detail
    this.createdBy = query.createdBy;
    this.group = query.group;
    this.category = query.category;
    this.bundle = query.bundle;
    this.resolvedBy = query.resolvedBy;
    this.actionsTaken = query.actionsTaken;
    this.actionsDateRange = query.actionsDateRange;
    this.actionsDateAfter = query.actionsDateAfter;
    this.actionsDateBefore = query.actionsDateBefore;
    this.suspicionScore = query.suspicionScore;

    // this.Advanced Filters -- EMR Users
    this.userOrganization = query.userOrganization;
    this.userDepartment = query.userDepartment;
    this.userType = query.userType;
    this.userTags = query.userTags;

    // lazy load departments/organizations
    if (this.userDepartment) this.caseResultsFiltersStore.loadDepartments();
    if (this.userOrganization) this.caseResultsFiltersStore.loadOrganizations();

    // Reset all exclusions, then set any that are explicitly requested.
    Object.keys(this).forEach(key => {
      if (key.endsWith('Exclude')) {
        this[key] = false;
      }
    });

    const excludeFilters = query.excludeFilters || [];
    if (typeof excludeFilters === 'string') {
      this.excludeFilters = [excludeFilters];
      this[`${excludeFilters}Exclude`] = true;
    } else {
      this.excludeFilters = excludeFilters.slice();
      this.excludeFilters.forEach(filter => (this[`${filter}Exclude`] = true));
    }
  };

  mergeParams(current, newQuery) {
    const merged = Object.assign({}, current, newQuery);
    for (const i in merged) {
      if (
        merged[i] === null ||
        merged[i] === undefined ||
        (Object.prototype.hasOwnProperty.call(newQuery, i) &&
          newQuery[i] === current[i])
      ) {
        delete merged[i];
      }

      if (merged[i] instanceof Array) merged[i] = merged[i].map(a => a);
    }

    // remove the "name" param if entering the advanced filtering mode since it's
    // only used for display purposes
    if (merged.showFilters && merged.name) delete merged.name;

    return merged;
  }

  path(newQuery) {
    const merged = this.mergeParams(RouterContainer.query, newQuery || {});
    const params = queryString.stringify(merged);

    return params ? `/cases?${params}` : '/cases';
  }

  downloadCSV() {
    const params = Object.assign(
      { size: EXPORT_CASE_COUNT },
      this.params || {}
    );
    const csvHref = BaseURL.service(
      'cases/export',
      DateHelpers.paramDatesToISO(params)
    );
    DownloadUtils.downloadFromServer(csvHref, 'Cases.csv');
  }

  navigateToAdvancedFilters(defaultQuery) {
    let filters;
    // If we have saved filters, use those. Otherwise use the provided default
    // query.
    try {
      filters = JSON.parse(localStorage.getItem('advancedFilters'));
    } catch (e) {
      // Swallow error.
    }

    filters = filters || defaultQuery;

    // If there are no groups remembered and no groups set on the query string already, then this
    // means all the user's groups are selected, so add all of them to the query string so they appear
    // in the advanced filters.
    if (!filters.group) filters.group = GroupStore.groupIds;

    RouterContainer.go(
      '/cases',
      this.mergeParams(filters, { showFilters: true })
    );
  }

  // Action
  toggleRememberFilters = () => {
    this.rememberFilters = !this.rememberFilters;
    localStorage.setItem(
      'rememberFilters',
      JSON.stringify(this.rememberFilters)
    );
  };
}

decorate(CaseResultsStore, {
  allowFetch: observable,
  owner: observable,
  user: observable,
  userInfo: observable,
  patient: observable,
  patientInfo: observable,
  name: observable,
  resolution: observable,
  resolutionDescription: observable,
  sort: observable,

  showFilters: observable,
  excludeFilters: observable,
  ownerExclude: observable,
  createdByExclude: observable,
  groupExclude: observable,
  categoryExclude: observable,
  resolvedByExclude: observable,
  bundleExclude: observable,
  createdExclude: observable,
  lastModifiedExclude: observable,
  violationDateExclude: observable,
  resolutionDateExclude: observable,
  timeToResolveExclude: observable,
  actionsTakenExclude: observable,
  resolutionExclude: observable,
  resolutionDescriptionExclude: observable,
  userTypeExclude: observable,
  userTagsExclude: observable,
  suspicionScoreExclude: observable,

  createdRange: observable,
  createdAfter: observable,
  createdBefore: observable,

  lastModifiedRange: observable,
  lastModifiedAfter: observable,
  lastModifiedBefore: observable,

  violationDateRange: observable,
  violationDateAfter: observable,
  violationDateBefore: observable,

  resolutionDateRange: observable,
  resolutionDateAfter: observable,
  resolutionDateBefore: observable,

  timeToResolve: observable,
  timeToResolveLessThan: observable,
  timeToResolveGreaterThan: observable,

  suspicionScore: observable,

  createdBy: observable,
  group: observable,
  category: observable,
  bundle: observable,
  resolvedBy: observable,
  actionsTaken: observable,

  actionsDateRange: observable,
  actionsDateAfter: observable,
  actionsDateBefore: observable,

  userOrganization: observable,
  userDepartment: observable,
  userType: observable,
  userTags: observable,
  rememberFilters: observable,

  allowedGroupsFromSelection: computed,
  displayOwner: computed,
  displayCreatedBy: computed,
  displayGroup: computed,
  displayCategory: computed,
  displayBundle: computed,
  displayResolution: computed,
  displayResolutionDescription: computed,
  displayResolvedBy: computed,
  displayActions: computed,
  displayDepartment: computed,
  displayOrganization: computed,
  displayUserType: computed,
  params: computed,
  resultsCount: computed,
  cases: computed,
  stringifiedFiltersForLocalStorage: computed,
  sortAttr: computed,
  sortDir: computed,

  setAllowFetch: action,
  reset: action,
  setFilters: action,
  toggleRememberFilters: action,
  toggleAdvFilters: action,
});

export { CaseResultsStore };
export default new CaseResultsStore({
  alertCategoryStore: AlertCategoryStore,
  caseResultsFiltersStore: CaseResultsFiltersStore,
  pinnedTimezoneStore: PinnedTimezoneStore,
});
