import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import { computed, decorate, observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { DropdownList } from 'react-widgets';
import {
  LineSegment,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryLegend,
  VictoryLine,
  VictoryPie,
  VictoryStack,
} from 'victory';

import {
  DateHelpers,
  formatCategoryName,
  SettingsStore,
  RouterContainer,
} from 'common';

import { StandardTheme } from '../../../ui';
import SettingsChartStore from '../../stores/SettingsChartStore';
import SettingsLimitStore from '../../stores/SettingsLimitStore';
import DateIntervalSelect from '../../../ui/DateIntervalSelect';
import TimePlusCalendarSelector from '../../../ui/TimePlusCalendarSelector';
import Tooltip from '../../../ui/Tooltip';
import ResizeWatcher from '../../../utils/ResizeWatcher';
import getWidthofElement from '../../../utils/ChartUtils';
const {
  TIME_PERIOD_VALUES: { WEEK, MONTH, NINETY_DAYS, QUARTER, YEAR, CUSTOM },
} = DateHelpers;

const CategoryComponent = ({ item }) => {
  if (item && item.group) {
    return (
      <span>
        <strong>Category: {item && item.group} </strong>
        {item && item.name}
      </span>
    );
  }
  return (
    <span>
      <strong>Category: </strong>
      {item && item.name}
    </span>
  );
};

CategoryComponent.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    group: PropTypes.string,
  }),
};

// Detect IE
const isIE = navigator.userAgent.indexOf('Trident') !== -1;

function calendarChange(date, valid, beforeOrAfter) {
  if (valid) {
    const params = Object.assign({}, RouterContainer.query || {}, {
      [`settingsCreated${beforeOrAfter}`]: moment(date).format('YYYY-MM-DD'),
    });
    localStorage.setItem(
      `settingsCreated${beforeOrAfter}`,
      moment(date).format('YYYY-MM-DD')
    );
    SettingsChartStore.go(params);
  }
}

const SettingsChart = observer(
  class extends React.Component {
    // Observables
    chartWidth = 600;
    pieWidth = 250;
    disposers = [];

    // Refs
    chartContainer = React.createRef();

    componentDidMount() {
      reaction(
        () => [
          SettingsChartStore.category,
          SettingsChartStore.customEnd,
          SettingsChartStore.customStart,
          SettingsChartStore.interval,
          SettingsChartStore.range,
          SettingsStore.categories,
          SettingsStore.size,
        ],
        () => {
          if (SettingsChartStore.category) SettingsChartStore.refresh();
        },
        { fireImmediately: true }
      );

      this.resizeWatcher = new ResizeWatcher(
        this.chartContainer.current,
        this.onResize
      );
      this.disposers.push(() => this.resizeWatcher.off());
      this.onResize();
    }

    componentWillUnmount() {
      this.disposers.forEach(d => d());
    }

    onResize() {
      this.chartWidth = getWidthofElement('.settings__chart__container', 600);
      this.pieWidth = getWidthofElement('.settings__pie__container', 250);
    }

    // Computed
    get showLimitLine() {
      return (
        SettingsChartStore.interval === 'week' &&
        SettingsChartStore.category !== 'All'
      );
    }

    // Computed
    get tickFormat() {
      return (x, y, array) => {
        let format = 'MMM YY';
        const suffix =
          moment(x).diff(SettingsChartStore.dateRange[0], 'days') < 0
            ? '*'
            : '';

        if (
          SettingsChartStore.range === 'custom' &&
          moment(array[0]).isSame(moment(array[array.length - 1]), 'year') ===
            true
        ) {
          // custom range, same year
          format = 'MMM D';
        } else if (
          SettingsChartStore.range !== 'year' &&
          (SettingsChartStore.interval === 'week' ||
            SettingsChartStore.interval === 'day')
        ) {
          format = 'MMM D';
        }

        return `${moment(x).format(format)}${suffix}`;
      };
    }

    // Computed
    get partialText() {
      if (
        SettingsChartStore.tickValues.length &&
        moment(SettingsChartStore.tickValues[0]).diff(
          SettingsChartStore.dateRange[0],
          'days'
        ) < 0
      ) {
        return `* = partial ${SettingsChartStore.tickInterval}`;
      }

      return '';
    }

    // Computed
    get legend() {
      const legend = [
        { name: 'Violation', symbol: { fill: '#f24335' } },
        { name: 'Not a Violation', symbol: { fill: '#16b434' } },
        { name: 'Unresolved', symbol: { fill: '#e5e5e5' } },
      ];

      if (this.showLimitLine)
        legend.push({ name: '7-Day Limit', symbol: { fill: '#000000' } });

      return legend;
    }

    // Computed
    get legendOffset() {
      return this.showLimitLine ? 250 : 150;
    }

    dropdownChange(e) {
      const params = Object.assign({}, RouterContainer.query || {}, {
        range: e.value,
      });

      localStorage.setItem('settingsRange', e.value);
      SettingsChartStore.go(params);
    }

    print() {
      window.print();
    }

    buildRangeString() {
      let range = SettingsChartStore.range;
      if (range === 'ninetyDays') range = '90 days';
      if (range === 'all') range = 'All Time';
      return `${
        range === 'custom' || range === 'All Time' ? 'Range: ' : 'Past: '
      } ${range[0].toUpperCase()}${range.slice(1)}, `;
    }

    buildPrintChartInfo() {
      const interval = SettingsChartStore.interval;
      let string = '';
      let category = SettingsStore.categories.find(
        cat => cat.id === SettingsChartStore.category
      );

      if (category) {
        category = formatCategoryName(category);
      } else category = 'All';

      string += `Category: ${category}, `;
      string += this.buildRangeString();
      string += `By: ${interval[0].toUpperCase()}${interval.slice(1)}`;

      return string;
    }

    renderHeader() {
      // Account for a delay in categories being loaded. We might be trying to
      // select a localStorage-stashed category before we have fetched
      // the categories.
      const selectedCategory = SettingsChartStore.categoriesList.find(
        c => c.id === SettingsChartStore.category
      ) || { name: 'All' };
      return (
        <header>
          <div className="print-only">
            <p className="float-right">{this.buildPrintChartInfo()}</p>
          </div>
          <h2>History</h2>
          <DropdownList
            className="settings__chart__category"
            data={SettingsChartStore.categoriesList}
            value={selectedCategory}
            valueField="id"
            textField="name"
            onChange={value => {
              localStorage.setItem('settingsCategory', value.id);
              SettingsChartStore.go({ category: value.id });
            }}
            valueComponent={CategoryComponent}
            itemComponent={CategoryComponent}
            data-cy="settings-category-dropdown"
          />
          <TimePlusCalendarSelector
            createdInformation={[
              RouterContainer.query.settingsCreatedAfter ||
                moment().subtract(1, 'month'),
              RouterContainer.query.settingsCreatedBefore || moment(),
            ]}
            label="Range"
            defaultValue={localStorage.getItem('settingsRange') || MONTH}
            options={[WEEK, MONTH, NINETY_DAYS, QUARTER, YEAR, CUSTOM]}
            onCalendarChange={calendarChange}
            onChangeDropdown={this.dropdownChange}
            hiddenCalendar={localStorage.getItem('settingsRange') === CUSTOM}
            data-cy="settings-range-dropdown"
          />
          <Tooltip content="Export Case History CSV" placement="left">
            <div
              className={classnames('settings__chart__download prot-a', {
                hidden: !SettingsChartStore.totalCases,
              })}
              onClick={e => {
                e.preventDefault();
                SettingsChartStore.downloadCSV();
              }}
            >
              <i
                className="material-icons icon-get_app"
                data-cy="settings-case-history-csv"
              />
            </div>
          </Tooltip>
          <Tooltip content="Print Chart" placement="left">
            <div
              onClick={this.print}
              className={classnames('settings__chart__print prot-a', {
                hidden: !SettingsChartStore.totalCases,
              })}
              data-cy="settings-print"
            >
              <i className="material-icons icon-print" />
            </div>
          </Tooltip>
        </header>
      );
    }

    renderBarChart() {
      return (
        <div
          className={classnames('settings__chart__container', {
            'settings__chart__container-empty': !SettingsChartStore.totalCases,
          })}
          ref={this.chartContainer}
        >
          <VictoryChart
            domain={{
              x: [
                moment(SettingsChartStore.dateRange[0]).startOf(
                  SettingsChartStore.interval
                ),
                SettingsChartStore.dateRange[1],
              ],
            }}
            domainPadding={90}
            padding={{
              top: 5,
              left: 55,
              right: 20,
              bottom: 50,
            }}
            height={300}
            width={this.chartWidth}
            theme={StandardTheme}
          >
            <VictoryAxis
              dependentAxis
              label="Number of Cases"
              axisComponent={<LineSegment style={{ strokeWidth: '0px' }} />}
              axisLabelComponent={<VictoryLabel dy={-15} />}
              tickFormat={y => {
                const int = parseInt(y, 10);
                return y === int ? int : '';
              }}
            />
            <VictoryAxis
              gridComponent={<LineSegment style={{ strokeWidth: '0px' }} />}
              scale="time"
              tickValues={SettingsChartStore.tickValues}
              tickFormat={this.tickFormat}
            />
            <VictoryStack>
              <VictoryBar data={SettingsChartStore.violationsByDate} />
              <VictoryBar data={SettingsChartStore.notViolationsByDate} />
              <VictoryBar data={SettingsChartStore.unresolvedByDate} />
            </VictoryStack>
            {this.renderLineChart()}
            <VictoryLegend
              gutter={30}
              x={this.chartWidth / 2 - this.legendOffset}
              y={280}
              orientation="horizontal"
              data={this.legend}
            />
            <VictoryLabel
              style={{
                fill: '#321f7d',
                fontSize: 14,
              }}
              x={50}
              y={290}
              text={this.partialText}
            />
          </VictoryChart>
        </div>
      );
    }

    renderLineChart() {
      if (
        SettingsChartStore.interval === 'week' &&
        SettingsLimitStore.limitsByDate.length > 1
      ) {
        return (
          <VictoryLine
            interpolation="stepAfter"
            data={SettingsLimitStore.limitsByDate}
            style={{ data: { stroke: '#000000' } }}
          />
        );
      }
      return null;
    }

    renderPieChart() {
      return (
        <div className="settings__pie__container">
          <svg
            viewBox={`0 0 ${this.pieWidth} 250`}
            width={isIE ? this.pieWidth : null}
            height={isIE ? 250 : null}
          >
            <VictoryPie
              data={SettingsChartStore.totalCasesByResolution}
              height={250}
              innerRadius={80}
              labels={() => null}
              padding={0}
              standalone={false}
              theme={StandardTheme}
              width={this.pieWidth}
            />
            <VictoryLabel
              textAnchor="middle"
              style={{
                color: '#321f7d',
                fontSize: 64,
              }}
              x={this.pieWidth / 2}
              y={110}
              text={SettingsChartStore.totalCases}
            />
            <VictoryLabel
              textAnchor="middle"
              style={{
                color: '#321f7d',
                fontSize: 18,
              }}
              x={this.pieWidth / 2}
              y={150}
              text="Total Cases"
            />
          </svg>
        </div>
      );
    }

    renderCharts() {
      return (
        <section>
          <section>
            <header>
              <h3>New Case History</h3>
              <span>{`${SettingsChartStore.dateRange[0].format(
                'll'
              )} - ${SettingsChartStore.dateRange[1].format('ll')}`}</span>
              <DateIntervalSelect
                className="settings__chart__interval float-right"
                disabled={SettingsChartStore.disabledIntervals}
                exclude={['year']}
                onChange={value => {
                  localStorage.setItem('settingsInterval', value.value);
                  SettingsChartStore.go({ interval: value.value });
                }}
                prefix="By"
                value={SettingsChartStore.interval}
                data-cy="settings-interval-dropdown"
              />
            </header>
            {this.renderBarChart()}
          </section>
          <aside>
            <header>
              <h3>Totals</h3>
              {this.renderPieChart()}
            </header>
          </aside>
        </section>
      );
    }

    render() {
      return (
        <div className="settings__charts">
          {this.renderHeader()}
          {this.renderCharts()}
        </div>
      );
    }
  }
);

decorate(SettingsChart, {
  chartWidth: observable,
  pieWidth: observable,
  partialText: computed,
  showLimitLine: computed,
  tickFormat: computed,
  legend: computed,
  legendOffset: computed,
});

SettingsChart.displayName = 'SettingsChart';

export default SettingsChart;
