import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

import {
  LoginStore,
  LoginClient,
  ResetPasswordStore,
  RouterContainer,
} from 'common';
import LoginFooter from '../LoginFooter';
import { ApplyBodyClassName, DocumentTitle, Input } from '../../ui';

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const LostPassword = observer(
  class LostPassword extends React.Component {
    state = {
      email: null,
      emailValid: false,
      failure: false,
      submitted: false,
    };

    handleSubmit(e) {
      e.preventDefault();

      this.setState({
        submitted: true,
      });

      LoginClient.resetPassword(this.state.email)
        .then(() => {
          this.setState({
            success: true,
          });
        })
        .fail(() => {
          this.setState({
            email: '',
            emailValid: false,
            failure: true,
            submitted: false,
          });
        });
    }

    componentDidMount() {
      // just ensure that people can't get here if they are already logged in
      if (LoginStore.loggedIn) {
        RouterContainer.go('/');
      }
    }

    onEmailChange = text => {
      const isValid = this.emailValidator(text);
      this.setState({
        emailValid: isValid,
        email: text,
        failure: false,
      });
    };

    emailValidator = text => EMAIL_REGEX.test(text);

    get mailto() {
      let body = 'Please describe the issue you are experiencing.  \n\n';
      body += `---------\n${window.location.href}`;
      return `mailto:support@protenus.com?${$.param(
        {
          subject: 'I need help resetting my password',
          body,
        },
        true
      )}`;
    }

    renderInstructions() {
      if (this.state.failure || this.state.success) {
        return null;
      } else if (LoginStore.samlRequired) {
        return <p>{ResetPasswordStore.passwordPolicy}</p>;
      }
    }

    renderFailure() {
      if (this.state.failure) {
        return (
          <p className="login-form--error">
            A communications error occurred. Please wait a few minutes and try
            again, or <a href={this.mailto}>contact us</a>.
          </p>
        );
      }
      return null;
    }

    renderSuccess() {
      if (this.state.success) {
        return (
          <span>
            <h4 className="form--success">SUCCESS!</h4>
            <p className="success--message">
              We have sent <strong>{this.state.email}</strong> an email with
              password reset instructions. If the email doesn&#39;t arrive soon,
              please check your spam filter for messages from{' '}
              <strong>support@protenus.com</strong>, or contact a system
              administrator.
            </p>
          </span>
        );
      }
    }

    renderForm() {
      const { email, emailValid, submitted } = this.state;
      const error = email !== null && !emailValid;

      return (
        <form className="form">
          <ul>
            <Input
              autoFocus
              borderRadius="sm"
              darkBackground
              coloredFont
              error={error}
              label="Email Address"
              name="email"
              onChange={this.onEmailChange}
              type="email"
              value={email || ''}
            />
          </ul>
          <p>
            <input
              className="button"
              type="submit"
              value="Reset Password"
              onClick={this.handleSubmit.bind(this)}
              disabled={!emailValid || submitted}
            />
          </p>
        </form>
      );
    }

    render() {
      const content = this.renderSuccess() || this.renderForm();

      return (
        <section className="login">
          <DocumentTitle text="Bluesight Login" />
          <ApplyBodyClassName className="login_view" />
          <article className="login-container">
            <header>
              <h1>Password Reset</h1>
              <p>
                Enter your <strong>Email Address</strong> below and we will send
                you a link to reset your password, just keep an eye on your
                inbox.
              </p>
              {this.renderInstructions()}
            </header>
            {!LoginStore.samlRequired && (
              <span>
                {this.renderFailure()}
                {content}
              </span>
            )}
            <div>
              <small>
                <Link to="/login">Return to Login</Link>
              </small>
              <small>
                Still having trouble? <a href={this.mailto}>Contact us.</a>
              </small>
            </div>
          </article>
          <LoginFooter />
        </section>
      );
    }
  }
);

LostPassword.displayName = 'LostPassword';

export default LostPassword;
