// Radar integration
export const RADAR_INTEGRATION = 'RadarIntegration';
export const RADAR_EXPLANATION =
  'Radar is an incident response tool used to assess risk and automate response decisions to privacy incidents. Enabling this integration will allow a manual one-way flow of Bluesight cases into the Radar platform.';

export const HIDE_TOKEN = '*'.repeat(100);

// Generic messages - integration type agnostic
export const POST_SUCCESS = 'Integration settings successfully created.';

export const PATCH_SUCCESS = 'Integration settings successfully updated.';

export const DELETE_SUCCESS = 'Integration successfully deleted.';

export const ERROR = 'Something went wrong, please try again later.';

export const CREATE_IN_PROGRESS = 'Creating incident';

export const SYNC_IN_PROGRESS = 'Syncing incident';

export const SYNC_SUCCESS = 'Successfully synced the incident';

export const SYNC_ERROR =
  'Something went wrong when syncing the Bluesight case, please try again later.';

export const TIMEZONES = [
  { key: 'MOUNTAIN DAYLIGHT TIME (MST7MDT)', value: 'America/Denver' },
  { key: 'ARIZONA: MOUNTAIN STANDARD TIME (MST)', value: 'America/Phoenix' },
  { key: 'ALASKA DAYLIGHT TIME (AKST9AKDT)', value: 'America/Anchorage' },
  { key: 'PACIFIC DAYLIGHT TIME (PST8PDT)', value: 'America/Los_Angeles' },
  { key: 'CENTRAL DAYLIGHT TIME (CST6CDT)', value: 'America/Chicago' },
  { key: 'EASTERN DAYLIGHT TIME (EST5EDT)', value: 'America/New_York' },
  { key: 'HAWAII STANDARD TIME (HST)', value: 'Pacific/Honolulu' },
  {
    key: 'PUERTO RICO: ATLANTIC STANDARD TIME (AST)',
    value: 'America/Puerto_Rico',
  },
  { key: 'COORDINATED UNIVERSAL TIME (UTC)', value: 'UTC' },
];

// SAI 360 integration
export const SAI_360_INTEGRATION = 'SAI360Integration';
export const SAI_360_EXPLANATION =
  'SAI 360 is an incident response tool used to assess risk and automate response decisions to privacy incidents. Enabling this integration will allow a manual one-way flow of Bluesight cases into the SAI 360 platform.';

// labels
export const SAI_360_LABEL = 'SAI 360 Incident';
export const RADAR_LABEL = 'Radar Incident';
export const ORIGAMI_LABEL = 'Origami Incident';

// Origami integration
export const ORIGAMI_INTEGRATION = 'OrigamiIntegration';

export const ORIGAMI_EXPLANATION =
  'Origami is an incident response tool used to assess risk and automate response decisions to privacy incidents. Enabling this integration will allow a manual one-way flow of Bluesight cases into the Origami platform.';
