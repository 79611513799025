import { action, computed, decorate, observable, reaction, toJS } from 'mobx';
import {
  DateHelpers,
  DownloadUtils,
  PagedStore,
  makeSessionStorageForKey,
} from 'common';
import { mapOptionsByParam } from './helpers';
import $ from 'jquery';
import moment from 'moment';
import ensureArray from '../../../utils/ensureArray';

const DEFAULT_FILTER = { status: 'ACTIVE', createdBy: 'UserOnly' };
const DEFAULT_SORT = 'patientFullName,asc';
const SELECTING = 'selecting';
const DESELECTING = 'deselecting';

const parseSort = sort => {
  const [sortBy, sortDir] = sort.split(',');
  return {
    sortBy,
    sortDir,
  };
};

const encase = (key, value) => (value ? { [key]: value } : {});

class VipsStore extends PagedStore {
  constructor({
    allUsersStore,
    flashBannerStore,
    permissionStore,
    notificationStore,
    vipClient,
  }) {
    super();

    this.allUsersStore = allUsersStore;
    this.permissionStore = permissionStore;
    this.notificationStore = notificationStore;
    this.vipClient = vipClient;

    this.mapOptionsByParam = mapOptionsByParam;
    this.sq = makeSessionStorageForKey('sq-vips');

    reaction(
      () =>
        JSON.stringify([
          this.allUsersStore.allUsers,
          this.canView,
          this.createdAfter,
          this.createdBefore,
          this.createdBy,
          this.createdRange,
          this.dob,
          this.endDateAfter,
          this.endDateBefore,
          this.endDateRange,
          this.createdExclude,
          this.createdByExclude,
          this.endDateExclude,
          this.lastModifiedExclude,
          this.lastModifiedByExclude,
          this.typesExclude,
          this.initialized,
          this.lastModifiedAfter,
          this.lastModifiedBefore,
          this.lastModifiedBy,
          this.lastModifiedRange,
          this.name,
          this.pageSize,
          this.showFilters,
          this.sort,
          this.status,
          this.types,
        ]),
      () => {
        this.refresh();
      }
    );

    reaction(
      () => this.failure,
      failure => {
        if (failure) flashBannerStore.add(failure, 'danger');
      }
    );
  }

  // NOT Observable
  pageSize = 30;

  // Observables
  createdBy;
  dob = null;
  createdExclude = false;
  createdByExclude = false;
  endDateExclude = false;
  lastModifiedExclude = false;
  lastModifiedByExclude = false;
  typesExclude = false;
  initialized = false;
  lastModifiedBy;
  name = '';
  selected = new Map();
  showFilters = false;
  sort = DEFAULT_SORT;
  status;
  types;

  // The routing strategy used will ensure that either the range *or* the
  // After/Before observables will be set. Calculations are handled in the
  // onChangeDropdown function.
  createdRange = null;
  createdAfter = null;
  createdBefore = null;

  lastModifiedRange = null;
  lastModifiedAfter = null;
  lastModifiedBefore = null;

  endDateRange = null;
  endDateAfter = null;
  endDateBefore = null;

  // This value will store the filters selected through the advanced filter form.
  // It is used to compute the query params needed to navigate to the search
  // results upon submitting the form.
  advancedFormFields = {};

  // Computed
  get allPatients() {
    return this.results || [];
  }

  //Computed
  get noFilters() {
    return !(
      this.lastModifiedBy ||
      this.createdBy ||
      this.status ||
      Object.keys(this.advancedFormFields).length
    );
  }

  // Computed
  get sortBy() {
    const { sortBy } = parseSort(this.sort);
    return sortBy;
  }

  // Computed
  get sortDir() {
    const { sortDir } = parseSort(this.sort);
    return sortDir;
  }

  // Computed
  get statusFilterOptions() {
    return {
      param: 'status',
      label: 'Status',
      options: [
        { value: null, name: 'Any' },
        { value: 'ACTIVE', name: 'Active' },
        { value: 'EXPIRED', name: 'Expired' },
      ],
    };
  }

  // Computed
  get typesFilterOptions() {
    return {
      param: 'types',
      label: 'VIP Tag',
      options: ['Online References', 'EHR VIP'],
    };
  }

  // Computed
  get createdByFilterOptions() {
    return this.mapOptionsByParam({
      param: 'createdBy',
      filterOptions: this.allUsersStore.findByPermission(
        'VIP_REGISTRATION_CREATE'
      ),
      additionalOptions: [
        { name: 'User Only', value: 'UserOnly' },
        { name: 'Bluesight Only', value: 'ProtenusOnly' },
      ],
      includeAny: !this.showFilters,
    });
  }

  // Computed
  get lastModifiedByFilterOptions() {
    return this.mapOptionsByParam({
      param: 'lastModifiedBy',
      filterOptions: this.allUsersStore.findByPermission(
        'VIP_REGISTRATION_MODIFY'
      ),
      includeAny: !this.showFilters,
    });
  }

  // Computed
  get canView() {
    return this.permissionStore.has('VIP_REGISTRATION_SEARCH_ALL');
  }

  // Observable
  manuallySelectingOrDeselecting = DESELECTING;
  // Observable
  manuallyCheckedIds = [];
  // Observable
  bulkActionComplete = false;

  // Computed
  get allSelected() {
    return (
      // We are in "deselecting" mode and have not deselected anything.
      (this.manuallySelectingOrDeselecting === DESELECTING &&
        this.manuallyCheckedIds.length === 0) ||
      // We are in "selecting" mode and have selected all of the records.
      (this.manuallySelectingOrDeselecting === SELECTING &&
        this.manuallyCheckedIds.length === this.totalElements)
    );
  }

  // Computed
  get noneSelected() {
    return (
      // We are in "deselecting" mode and have deselected everything.
      (this.manuallySelectingOrDeselecting === DESELECTING &&
        this.manuallyCheckedIds.length === this.totalElements) ||
      // We are in "selecting" mode and have not selected anything.
      (this.manuallySelectingOrDeselecting === SELECTING &&
        this.manuallyCheckedIds.length === 0)
    );
  }

  // Computed
  get someSelected() {
    return !this.allSelected && !this.noneSelected;
  }

  // Computed
  get numberOfVipRegistrationsToUpdate() {
    // Check for loaded data.
    if (!this.totalElements) return 0;

    // Either all records minus those manually unchecked, or just those manually
    // checked.
    return this.manuallySelectingOrDeselecting === DESELECTING
      ? this.totalElements - this.manuallyCheckedIds.length
      : this.manuallyCheckedIds.length;
  }

  isSelected = vipRegistration => {
    return (
      this.allSelected ||
      (this.manuallySelectingOrDeselecting === SELECTING &&
        this.manuallyCheckedIds.includes(vipRegistration.id)) ||
      (this.manuallySelectingOrDeselecting === DESELECTING &&
        !this.manuallyCheckedIds.includes(vipRegistration.id))
    );
  };

  // Action
  toggleSelect = vipRegistration => {
    if (this.manuallyCheckedIds.includes(vipRegistration.id)) {
      this.manuallyCheckedIds = this.manuallyCheckedIds.filter(
        id => id !== vipRegistration.id
      );
    } else {
      this.manuallyCheckedIds = [
        ...this.manuallyCheckedIds,
        vipRegistration.id,
      ];
    }
  };

  // Action
  toggleSelectAll() {
    if (this.allSelected) {
      this.manuallySelectingOrDeselecting = SELECTING;
    } else if (this.noneSelected) {
      this.manuallySelectingOrDeselecting = DESELECTING;
    } else {
      // Some were selected. Toggle the previous state.
      if (this.manuallySelectingOrDeselecting === SELECTING) {
        this.manuallySelectingOrDeselecting = DESELECTING;
      } else {
        this.manuallySelectingOrDeselecting = SELECTING;
      }
    }
    // Start again with a fresh set of manually checked vip registration ids.
    this.manuallyCheckedIds = [];
  }

  // Actions
  // This is only called in response to query params changing from logic within
  // a component near the top of the page hierarchy.
  // Setting these filters will kick off a reaction that fetches fresh data to
  // show in the table
  setFilters = query => {
    const {
      createdAfter,
      createdBefore,
      createdBy,
      createdRange,
      dob,
      endDateAfter,
      endDateBefore,
      endDateRange,
      createdExclude,
      createdByExclude,
      endDateExclude,
      lastModifiedExclude,
      lastModifiedByExclude,
      typesExclude,
      lastModifiedAfter,
      lastModifiedBefore,
      lastModifiedBy,
      lastModifiedRange,
      name = '',
      showFilters,
      sort = DEFAULT_SORT,
      status,
      types = [],
    } = query;

    this.createdAfter = createdAfter;
    this.createdBefore = createdBefore;
    this.createdByExclude = createdByExclude;
    this.createdExclude = createdExclude;
    this.createdRange = createdRange;
    this.dob = dob;
    this.endDateAfter = endDateAfter;
    this.endDateBefore = endDateBefore;
    this.endDateExclude = endDateExclude;
    this.endDateRange = endDateRange;
    this.lastModifiedAfter = lastModifiedAfter;
    this.lastModifiedBefore = lastModifiedBefore;
    this.lastModifiedByExclude = lastModifiedByExclude;
    this.lastModifiedExclude = lastModifiedExclude;
    this.lastModifiedRange = lastModifiedRange;
    this.showFilters = showFilters;
    this.sort = sort;
    this.status = status;
    this.types = typesExclude;

    this.initialized = true;

    this.createdBy = showFilters ? ensureArray(createdBy) : createdBy;
    this.lastModifiedBy = showFilters
      ? ensureArray(lastModifiedBy)
      : lastModifiedBy;
    this.name = this.sq.get(name);
    this.types = ensureArray(types).filter(type =>
      this.typesFilterOptions.options.includes(type)
    );

    // Since this is set only on query param change, we can set the advanced
    // form filters to the query param values. That way we reflect starting
    // state on page refresh.
    this.advancedFormFields = showFilters
      ? {
          ...query,
          // Specifically set the few fields that we updated above.
          ...encase('createdBy', this.createdBy),
          ...encase('lastModifiedBy', this.lastModifiedBy),
          ...encase('name', this.name),
          ...encase('types', this.types),
        }
      : {};
  };

  clear() {
    super.clear();
    this.sort = DEFAULT_SORT;
  }

  setDateParams(attr, params) {
    // Be ready to convert certain dates to utc. We convert endDate* fields
    // because those do not have a meaningful time component.
    function formatDate(momentDate, time) {
      if (attr === 'endDate') {
        // For end date make sure to set it +1 day to encapsulate a full 24 hours when a user sets custom date range to single day
        if (params.excludeFilters?.includes(attr)) {
          if (time === 'end')
            return moment(momentDate)
              .utc()
              .add(1, 'day')
              .format('YYYY-MM-DD');
          if (time === 'start')
            return moment(momentDate)
              .utc()
              .subtract(1, 'day')
              .format('YYYY-MM-DD');
        }
        return moment(momentDate).format('YYYY-MM-DD');
      }
      if (time === 'end') return moment(momentDate).endOf('day');
      if (time === 'start') return moment(momentDate).startOf('day');
    }
    // Either we have before/after dates from custom ranges,
    if (this[`${attr}After`] && this[`${attr}Before`]) {
      params[`${attr}After`] = formatDate(this[`${attr}After`], 'start');
      params[`${attr}Before`] = formatDate(this[`${attr}Before`], 'end');
    } else if (this[`${attr}Range`]) {
      // or we might have a built-in range selected.
      const range = DateHelpers.rangeForPeriod(this[`${attr}Range`]);
      params[`${attr}After`] = formatDate(range[0], 'start');
      params[`${attr}Before`] = formatDate(range[1] || moment(), 'end');
    }
  }

  gatherParams = (params = {}) => {
    const excludableFilters = [
      'created',
      'createdBy',
      'endDate',
      'lastModified',
      'lastModifiedBy',
      'types',
    ];

    params.excludeFilters = excludableFilters.filter(
      filter => this[`${filter}Exclude`]
    );

    if (this.status) params.status = this.status;
    // A real array is needed for proper $.param serialization.
    if (this.types && this.types.length > 0) params.types = toJS(this.types);
    // createdBy could either be a single id string or an array.
    if (this.createdBy) {
      params.createdBy = ensureArray(toJS(this.createdBy)).flatMap(id => {
        if (id === 'ProtenusOnly') return 'null';
        if (id === 'UserOnly') return 'notNull';
        return id;
      });
    }

    if (this.lastModifiedBy) params.lastModifiedBy = toJS(this.lastModifiedBy);
    if (this.name) params.patientFullName = this.name;
    if (this.dob)
      params.dateOfBirth = moment(this.dob)
        .utc()
        .startOf('day')
        .toISOString();
    if (this.createdRange || this.createdAfter || this.createdBefore)
      this.setDateParams('created', params);
    if (
      this.lastModifiedRange ||
      this.lastModifiedAfter ||
      this.lastModifiedBefore
    )
      this.setDateParams('lastModified', params);

    // don't mess with the date/times set
    params = DateHelpers.paramDatesToISO(params, true);

    if (this.endDateRange || this.endDateAfter || this.endDateBefore)
      this.setDateParams('endDate', params);

    return params;
  };

  fetch = () => {
    if (this.initialized && this.canView) {
      const params = {
        sort: this.sort,
        size: this.pageSize,
      };

      return this.vipClient.getReport(this.gatherParams(params));
    }
    return Promise.resolve([]);
  };

  downloadCSV = () => {
    const params = {
      size: 10000,
    };

    const csvHref = this.vipClient.url('export', this.gatherParams(params));
    DownloadUtils.downloadFromServer(csvHref, `VIPRegistry.csv`);
  };

  showFiltersLink() {
    // closing the advanced filters, reset to the default filters
    if (this.showFilters) {
      return `/vips?${$.param(
        {
          ...DEFAULT_FILTER,
        },
        true
      )}`;
    }

    // opening the advanced filters, take the applied filters and move them to the advanced filter panel
    return `/vips?${$.param(
      this.mergeParams({
        status: this.status,
        createdBy: this.createdBy,
        lastModifiedBy: this.lastModifiedBy,
        showFilters: true,
      }),
      true
    )}`;
  }

  // Computed
  get advancedFiltersPath() {
    const cleanFields = toJS(this.advancedFormFields);
    if (cleanFields.name) {
      // Patient name is sensitive data. Stash in storage as to no expose it on the URL.
      const storageKey = this.sq.timeSet(cleanFields.name);
      cleanFields.name = storageKey;
    }

    return `/vips?${$.param(
      {
        ...cleanFields,
        ...encase('showFilters', this.showFilters),
      },
      true
    )}`;
  }

  mergeParams(current, newQuery) {
    const merged = Object.assign({}, current, newQuery);

    // Remove empty fields so they don't get included in serialized query params.
    return Object.entries(merged)
      .filter(([, value]) => {
        return !!value;
      })
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  }

  // Action
  recordFilterChanges = newFilters => {
    this.advancedFormFields = this.mergeParams(
      this.advancedFormFields,
      newFilters
    );
  };

  onToggleExclusion = value => {
    const param = {
      [`${value}Exclude`]: this.advancedFormFields[`${value}Exclude`]
        ? null
        : true,
    };
    this.recordFilterChanges(param);
  };

  onDOBChange = date => {
    if (!date) {
      this.recordFilterChanges({ dob: null });
    } else {
      this.recordFilterChanges({
        dob: moment(date).format('YYYY-MM-DD'),
      });
    }
  };

  onCalendarChange = (date, valid, beforeOrAfter, filterKeyRoot) => {
    if (valid) {
      this.recordFilterChanges({
        [`${filterKeyRoot}${beforeOrAfter}`]: moment(date).format('YYYY-MM-DD'),
      });
    }
  };

  onChangeDropdown = (val, filterKey) => {
    const filter = {};
    const _filterKey = filterKey.replace(/Range/, '');
    if (val.value === 'all') {
      filter[`${_filterKey}After`] = null;
      filter[`${_filterKey}Before`] = null;
      filter[filterKey] = null;
    } else if (val.name === 'Any') {
      filter[filterKey] = null;
    } else if (val.value) {
      filter[filterKey] = val.value;

      if (val.value === 'custom') {
        filter[`${_filterKey}After`] = moment()
          .subtract(1, 'month')
          .format('YYYY-MM-DD');
        filter[`${_filterKey}Before`] = moment().format('YYYY-MM-DD');
      } else if (filterKey.match(/Range/)) {
        filter[`${_filterKey}After`] = null;
        filter[`${_filterKey}Before`] = null;
      }
    } else if (filterKey === 'createdBy' || filterKey === 'lastModifiedBy') {
      // Pull the person id out of the {name,value} objects.
      filter[filterKey] = (val || []).map(({ value }) => value);
    } else {
      filter[filterKey] = val === '' ? null : val;
    }

    if (
      this.advancedFormFields[`${_filterKey}Exclude`] &&
      (val.value === 'all' ||
        val.name === 'Any' ||
        (Array.isArray(val) && val.length === 0))
    ) {
      // We were previously excluding a value here but we've now chosen "all."
      // We need to remove the exclusion filter.
      filter[`${_filterKey}Exclude`] = null;
    }
    this.recordFilterChanges(filter);
  };

  buildCheckboxQuery = () => {
    const query = this.gatherParams({
      // make a copy of the ids because jQuery param will not recognize a mobXObservable array it as an array and so will not format properly
      _id: this.manuallyCheckedIds.slice(),
    });
    // if the user is deselecting ids add _id to the exclusion list
    if (this.manuallySelectingOrDeselecting === DESELECTING)
      query.excludeFilters.push('_id');
    return query;
  };

  onExpire = () => {
    const query = this.buildCheckboxQuery();

    // only update active registrations, if there is already a status applied, keep that and add Active
    query.status = query.status ? [query.status, 'ACTIVE'] : 'ACTIVE';
    this.doBulkUpdate('endDate', query, {
      endDate: moment()
        .subtract(1, 'd')
        .format('YYYY-MM-DD'),
    });
  };

  onModify = date => {
    const query = this.buildCheckboxQuery();
    this.doBulkUpdate('endDate', query, {
      endDate: date ? moment(date).format('YYYY-MM-DD') : null,
    });
  };

  onReason = reason => {
    const query = this.buildCheckboxQuery();

    this.doBulkUpdate('reason', query, { reason });
  };

  // Action
  doBulkUpdate = (action, filters, params) => {
    const { numberOfVipRegistrationsToUpdate } = this;
    return this.vipClient
      .bulkUpdate(action, filters, params)
      .then(() => {
        this.notificationStore.addSuccess(
          `${numberOfVipRegistrationsToUpdate} VIP registrations successfully changed.`
        );
        // Setting this boolean results in a redirect back to the main vips page.
        // This store will then be garbage collected.
        this.bulkActionComplete = true;
      })
      .catch(() => {
        this.notificationStore.addError(
          'There was an error updating your VIP registrations.'
        );
      });
  };
}

decorate(VipsStore, {
  // Observables
  advancedFormFields: observable,
  bulkActionComplete: observable,
  createdAfter: observable,
  createdBefore: observable,
  createdBy: observable,
  createdByBasicFilter: observable,
  createdRange: observable,
  dob: observable,
  endDateAfter: observable,
  endDateBefore: observable,
  endDateRange: observable,
  createdExclude: observable,
  createdByExclude: observable,
  endDateExclude: observable,
  lastModifiedExclude: observable,
  lastModifiedByExclude: observable,
  typesExclude: observable,
  initialized: observable,
  lastModifiedAfter: observable,
  lastModifiedBefore: observable,
  lastModifiedBy: observable,
  lastModifiedByBasicFilter: observable,
  lastModifiedRange: observable,
  manuallyCheckedIds: observable,
  manuallySelectingOrDeselecting: observable,
  name: observable,
  selected: observable,
  showFilters: observable,
  sort: observable,
  status: observable,
  types: observable,
  // Computeds
  advancedFiltersPath: computed,
  allPatients: computed,
  allSelected: computed,
  canView: computed,
  createdByFilterOptions: computed,
  lastModifiedByFilterOptions: computed,
  noFilters: computed,
  noneSelected: computed,
  numberOfVipRegistrationsToUpdate: computed,
  someSelected: computed,
  sortBy: computed,
  sortDir: computed,
  statusFilterOptions: computed,
  typesFilterOptions: computed,
  // Actions
  deselectAll: action,
  doBulkUpdate: action,
  recordFilterChanges: action,
  selectCheckbox: action,
  selectAll: action,
  setFilters: action,
  toggleSelect: action,
  toggleSelectAll: action,
});

export { VipsStore };
