import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../ui';
import { Authenticated } from 'common';

const Actions = ({
  dirty,
  disabled,
  handleSubmit,
  renew,
  // eslint-disable-next-line no-unused-vars
  renewing,
  reset,
  status,
}) => (
  <Authenticated permission="VIP_REGISTRATION_MODIFY">
    <li className="form__actions">
      {disabled && status === 'EXPIRED' && (
        <Button
          type="button"
          value="Renew Registration"
          onClick={() => renew()}
        />
      )}
      {!disabled && (
        <Button
          className="button--cta"
          type="button"
          disabled={!dirty}
          onClick={() => handleSubmit()}
          value="Update VIP Registration"
        />
      )}
      {status === 'ACTIVE' && (
        <Button
          className="button--link"
          type="button"
          onClick={() => handleSubmit(true)}
          value="Expire Now"
        />
      )}
      {dirty && (
        <Button
          className="button--link"
          type="button"
          onClick={() => reset()}
          value="Cancel"
        />
      )}
    </li>
  </Authenticated>
);

export default Actions;

Actions.propTypes = {
  disabled: PropTypes.bool,
  handleSubmit: PropTypes.func,
  renewing: PropTypes.bool,
  status: PropTypes.string,
  dirty: PropTypes.bool,
  reset: PropTypes.func,
  renew: PropTypes.func,
};
