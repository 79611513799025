import React, { useState, useEffect, useRef } from 'react';
import { Authenticated, FeatureFlags } from 'common';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import NavBarItem from '../NavBarItem';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Modal } from '../../ui';
import BuildVersionInfo from './BuildVersionInfo';

const UserMenu = ({
  amIOnAnApprovedDomain,
  adminHref,
  flags,
  setFlags,
  logout,
  servicesVersionInfo,
}) => {
  const menuRef = useRef();
  const [showUserMenu, setShowUserMenu] = useState(false);

  const appliedFlagValues = flags.map(flag => flag.replace('featureFlag-', ''));

  const allFlags = Object.entries(FeatureFlags).map(([key, displayName]) => {
    return {
      displayName,
      key,
      isApplied: appliedFlagValues.includes(displayName),
    };
  });

  const toggleShowMenu = () => {
    setShowUserMenu(!showUserMenu);
  };

  const handleClickOutside = event => {
    if (!menuRef?.current?.contains(event.target) && showUserMenu) {
      return toggleShowMenu();
    }
  };

  const addFlag = flagName => {
    const fullName = `featureFlag-${flagName}`;
    localStorage.setItem(fullName, 'true');
    setFlags([...flags, fullName]);
  };

  const removeFlag = flagName => {
    const fullName = `featureFlag-${flagName}`;
    localStorage.removeItem(fullName);
    setFlags(flags.filter(flag => flag !== fullName));
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  function openUIVersionInfoModal() {
    const {
      REACT_APP_GIT_SHA,
      REACT_APP_COMMIT_TIME,
      REACT_APP_REF,
    } = process.env;
    Modal.show(
      <BuildVersionInfo
        versionInfo={{
          branch: REACT_APP_REF?.split('heads/')?.[1] || '',
          commit: {
            id: REACT_APP_GIT_SHA,
            time: REACT_APP_COMMIT_TIME,
          },
        }}
      />
    );
  }

  function openServicesVersionInfoModal() {
    Modal.show(<BuildVersionInfo versionInfo={servicesVersionInfo} />);
  }

  return (
    <div className="user_menu" ref={menuRef}>
      <div className="user_menu--button prot-a">
        <i
          className="material-icons icon-account_circle"
          onClick={toggleShowMenu}
        />
      </div>
      <div
        className={classnames('user_menu__dropdown', {
          active: showUserMenu,
          inactive: !showUserMenu,
        })}
      >
        <ul>
          {amIOnAnApprovedDomain && (
            <NavBarItem route="/account">My Account</NavBarItem>
          )}
          <Authenticated appRole="admin">
            <li>
              <a href={adminHref}>Administration</a>
            </li>
          </Authenticated>
          <Authenticated appRole="admin">
            <li>
              <Link to="/auditLog">Audit Log</Link>
            </li>
          </Authenticated>
          <NavBarItem onClick={logout}>Logout</NavBarItem>
          <Authenticated appRole="admin">
            <NavBarItem onClick={openUIVersionInfoModal}>
              UI Version Info
            </NavBarItem>
          </Authenticated>
          <Authenticated appRole="admin">
            <NavBarItem onClick={openServicesVersionInfoModal}>
              Services Version Info
            </NavBarItem>
          </Authenticated>
          <Authenticated appRole="admin">
            {allFlags.length > 0 && (
              <div className={styles.flags}>
                {allFlags.map(flagInfo => {
                  return (
                    <NavBarItem
                      key={flagInfo.key}
                      onClick={e => {
                        e.preventDefault();
                        flagInfo.isApplied
                          ? removeFlag(flagInfo.displayName)
                          : addFlag(flagInfo.displayName);
                      }}
                      className={styles.flagLinkContainer}
                    >
                      <span>
                        {flagInfo.isApplied ? 'Remove' : 'Add'} Feature:
                      </span>
                      <span>{flagInfo.displayName}</span>
                    </NavBarItem>
                  );
                })}
              </div>
            )}
          </Authenticated>
        </ul>
      </div>
    </div>
  );
};

UserMenu.displayName = 'UserMenu';

export default UserMenu;

UserMenu.propTypes = {
  amIOnAnApprovedDomain: PropTypes.bool,
  adminHref: PropTypes.string,
  flags: PropTypes.array,
  logout: PropTypes.func,
  setFlags: PropTypes.func,
  versionInfo: PropTypes.any,
};
