import React from 'react';
import { observer } from 'mobx-react';

import EncounterStore from '../../stores/EncounterStore';
import TimeEntryStore from '../../stores/TimeEntryStore';
import ChartStore from '../../stores/ChartStore';
import PinnedTimezoneStore from '../../../stores/PinnedTimezoneStore';

import XAxisChart from '../XAxisChart';

export function mapProps({
  EncounterStore,
  TimeEntryStore,
  ChartStore,
  PinnedTimezoneStore,
}) {
  return {
    encounters: EncounterStore.encounters,
    timeEntries: TimeEntryStore.timeEntries,
    brushedXScale: ChartStore.brushedXScale,
    width: ChartStore.width,
    tickFormat: ChartStore.tickFormat,
    margin: ChartStore.margin,
    padding: ChartStore.padding,
    timezone: PinnedTimezoneStore.storedTimezone,
  };
}

const Index = observer(() => (
  <XAxisChart
    {...mapProps({
      EncounterStore,
      TimeEntryStore,
      ChartStore,
      PinnedTimezoneStore,
    })}
  />
));

export default Index;
