import { DIVERSION_PRODUCT, PRIVACY_PRODUCT } from 'common';
import { action, computed, decorate, observable, reaction } from 'mobx';
import {
  prepForDisplay,
  queryForSelectionChange,
  queryForToggle,
} from '../../../stores/CaseFilterHelpers';
import { CREATED, LAST_MODIFIED, EVENT_DATE, RESOLUTION_DATE } from '../const';
import commaSeparatedToArray from '../../../utils/commaSeparatedToArray';
export const DEFAULT_CASES_BY = CREATED;
export const DEFAULT_DATE_RANGE = 'month';
const KEY = 'caseReportFilters';

function rememberFiltersFromStorage() {
  try {
    return JSON.parse(localStorage.getItem('rememberReportFilters')) || false;
  } catch (e) {
    return false;
  }
}

export class CaseReportsFilterSelectionsStore {
  /**
   * Constructor
   * @param caseResultsFiltersStore The store that houses the filter options.
   */
  constructor({ caseResultsFiltersStore, permissionStore }) {
    this.caseResultsFiltersStore = caseResultsFiltersStore;
    this.permissionStore = permissionStore;

    reaction(
      () => ({
        filters: this.stringifiedFiltersForLocalStorage,
        rememberFilters: this.rememberFilters,
      }),
      ({ filters, rememberFilters }) => {
        // Save current filters if rememberFilters is on. Otherwise, clear them.
        if (rememberFilters) {
          localStorage.setItem(KEY, filters);
        } else {
          this.clearStoredFilters();
        }
      }
    );
  }
  // Report settings filters
  pivotOn;
  casesBy = DEFAULT_CASES_BY;
  reportSettingsDateRange = DEFAULT_DATE_RANGE;
  reportSettingsDateAfter;
  reportSettingsDateBefore;
  // Case detail filters.
  owner;
  createdBy;
  group;
  category;
  bundle;
  resolution;
  resolutionDescription;
  resolvedBy;
  actionsTaken;
  actionsDateRange;
  actionsDateAfter;
  actionsDateBefore;
  suspicionScore;
  // User filters.
  userOrganization;
  userDepartment;
  userType;
  userTags;
  // All Filters -- Remembering
  // Set initial value to what is saved in localStorage.
  rememberFilters = rememberFiltersFromStorage();

  // ExcludeFilters
  excludeFilters = null;
  ownerExclude = false;
  createdByExclude = false;
  groupExclude = false;
  bundleExclude = false;
  resolutionExclude = false;
  resolutionDescriptionExclude = false;
  resolvedByExclude = false;
  actionsTakenExclude = false;
  suspicionScoreExclude = false;
  userTypeExclude = false;
  userTagsExclude = false;

  // Report settings
  get pivotOnOptions() {
    return [
      { label: 'None', value: undefined },
      {
        label: 'Group',
        value: 'category.group',
      },
      {
        label: 'Resolution',
        value: 'resolution',
      },
      {
        label: 'Resolution Description',
        value: 'resolutionDescription',
      },
      {
        label: 'Case Type',
        value: 'category.name',
      },
      {
        label: 'Case Creation Type',
        value: 'creationType',
      },
      {
        label: 'Department',
        value: 'department',
      },
      {
        label: 'Case Owner',
        value: 'owner',
      },
      {
        label: 'Role',
        value: 'role',
      },
      {
        label: 'User Type',
        value: 'userType',
      },
    ];
  }

  possibleValues = field => {
    let values = [];
    let excluded = false;
    let filteredValues;

    const filteredGroupIds = commaSeparatedToArray(this.group);
    const products = this.permissionStore.getProducts();

    switch (field) {
      case 'category.group':
        values = this.caseResultsFiltersStore.groups?.map(v => v.name);
        excluded = this.groupExclude;
        filteredValues = this.group
          ? this.caseResultsFiltersStore.groups
              ?.filter(g => filteredGroupIds.includes(g.id))
              ?.map(g => g.name)
          : undefined;
        break;
      case 'resolution':
        values = [undefined, 'VIOLATION', 'NOT_VIOLATION'];
        excluded = this.resolutionExclude;
        filteredValues = this.resolution;
        break;
      case 'resolutionDescription':
        values = [undefined, 'FALSE_POSITIVE', 'GOOD_CATCH', 'POLICY'];
        // these values added if the user has access to corresponding products
        if (products.includes(DIVERSION_PRODUCT)) {
          values.push('DIVERSION');
        }
        if (products.includes(PRIVACY_PRODUCT)) {
          values.push('PRIVACY');
        }
        filteredValues = this.resolutionDescription;
        excluded = this.resolutionDescriptionExclude;
        break;
      case 'category.name':
        values = this.caseResultsFiltersStore.alertCategoryNames?.map(
          v => v.name
        );
        filteredValues = this.category;
        break;
      case 'creationType':
        values = ['Manually Created', 'Bluesight Created'];
        excluded = this.createdByExclude;
        // creation type requires some more complex logic
        if (this.createdBy) {
          filteredValues = [];
          // coerce single value filter to an array
          const selection =
            typeof this.createdBy === 'string'
              ? [this.createdBy]
              : this.createdBy;
          // determine how many app users (non-Bluesight System) were selected
          const selectedAppUsers = selection.filter(v => v !== 'null').length;
          if (selectedAppUsers !== selection.length) {
            // Bluesight System was one of the filter values so add it
            filteredValues.push('Bluesight Created');
          }

          if (excluded) {
            // determine how many possible case creators there are
            const possibleCreators = this.caseResultsFiltersStore.caseCreators.filter(
              v => v.id !== 'null'
            ).length;
            if (selectedAppUsers === possibleCreators) {
              // manually created should be an option UNLESS the user has excluded every possible case creator
              filteredValues.push('Manually Created');
            }
          } else if (selectedAppUsers > 0) {
            // at least one app user was selected so include Manually Created as an option
            filteredValues.push('Manually Created');
          }
        }
        break;
      default:
        break;
    }

    if (typeof filteredValues === 'string') filteredValues = [filteredValues];

    return values.filter(v => {
      // undefined value options are captured as the string 'null' for filters
      const normalizedVal = v === undefined ? 'null' : v;
      // not filtering by anything, all values pass through
      if (!filteredValues || filteredValues?.length === 0) return true;
      // not excluding and the currently filtered value list contains the value
      else if (!excluded && filteredValues.includes(normalizedVal)) return true;
      // excluding and the currently filtered value does not contain the value
      else if (excluded && !filteredValues.includes(normalizedVal)) return true;
      return false;
    });
  };

  get casesByOptions() {
    return [
      { label: 'Case Creation Date', value: CREATED },
      { label: 'Last Modified Date', value: LAST_MODIFIED },
      { label: 'Event Date', value: EVENT_DATE },
      { label: 'Resolution Date', value: RESOLUTION_DATE },
    ];
  }

  // Case detail filters.
  get displayOwner() {
    return prepForDisplay(this.owner, this.caseResultsFiltersStore.caseOwners);
  }

  get displayCreatedBy() {
    return prepForDisplay(
      this.createdBy,
      this.caseResultsFiltersStore.caseCreators
    );
  }

  get allowedGroupsFromSelection() {
    return commaSeparatedToArray(this.group).filter(id =>
      (this.caseResultsFiltersStore.groups || []).find(group => group.id === id)
    );
  }

  get displayGroup() {
    return prepForDisplay(
      this.allowedGroupsFromSelection,
      this.caseResultsFiltersStore.groups
    );
  }

  get displayCategory() {
    return prepForDisplay(
      this.category,
      this.caseResultsFiltersStore.alertCategoryNames
    );
  }

  get displayBundle() {
    return prepForDisplay(
      this.bundle,
      this.caseResultsFiltersStore.caseBundles
    );
  }

  get displayResolution() {
    return prepForDisplay(
      this.resolution,
      this.caseResultsFiltersStore.resolutions
    );
  }

  get displayResolutionDescription() {
    return prepForDisplay(
      this.resolutionDescription,
      this.caseResultsFiltersStore.resolutionDescriptionOptions
    );
  }

  get displayResolvedBy() {
    return prepForDisplay(
      this.resolvedBy,
      this.caseResultsFiltersStore.caseResolvers
    );
  }

  get displayActions() {
    return prepForDisplay(
      this.actionsTaken,
      this.caseResultsFiltersStore.caseActions
    );
  }

  get displayOrganization() {
    return prepForDisplay(
      this.userOrganization,
      this.caseResultsFiltersStore.userOrganizations
    );
  }

  get displayDepartment() {
    return prepForDisplay(
      this.userDepartment,
      this.caseResultsFiltersStore.userDepartments
    );
  }

  get displayUserType() {
    return prepForDisplay(
      this.userType,
      this.caseResultsFiltersStore.userTypes
    );
  }

  get displayTags() {
    return prepForDisplay(this.userTags, this.caseResultsFiltersStore.userTags);
  }

  get defaultFilters() {
    return {
      casesBy: DEFAULT_CASES_BY,
      reportSettingsDateRange: DEFAULT_DATE_RANGE,
    };
  }

  setQuery = query => {
    // Case report filters
    this.pivotOn = query.pivotOn;
    this.casesBy = query.casesBy || DEFAULT_CASES_BY;
    this.reportSettingsDateRange =
      query.reportSettingsDateRange || DEFAULT_DATE_RANGE;
    this.reportSettingsDateAfter = query.reportSettingsDateAfter;
    this.reportSettingsDateBefore = query.reportSettingsDateBefore;

    // Case detail filters.
    this.createdBy = query.createdBy;
    this.owner = query.owner;
    this.group = query.group;
    this.category = query.category;
    this.bundle = query.bundle;
    this.resolution = query.resolution;
    this.resolutionDescription = query.resolutionDescription;
    this.resolvedBy = query.resolvedBy;
    this.actionsTaken = query.actionsTaken;
    this.actionsDateRange = query.actionsDateRange;
    this.actionsDateAfter = query.actionsDateAfter;
    this.actionsDateBefore = query.actionsDateBefore;
    this.suspicionScore = query.suspicionScore;

    // User filters.
    this.userOrganization = query.userOrganization;
    this.userDepartment = query.userDepartment;
    this.userType = query.userType;
    this.userTags = query.userTags;

    // Reset all exclusions, then set any that are explicitly requested.
    Object.keys(this).forEach(key => {
      if (key.endsWith('Exclude')) {
        this[key] = false;
      }
    });

    const excludeFilters = query.excludeFilters || [];
    if (typeof excludeFilters === 'string') {
      this.excludeFilters = [excludeFilters];
      this[`${excludeFilters}Exclude`] = true;
    } else {
      this.excludeFilters = excludeFilters.slice();
      this.excludeFilters.forEach(filter => (this[`${filter}Exclude`] = true));
    }
  };

  // Action
  toggleRememberFilters = () => {
    this.rememberFilters = !this.rememberFilters;
    localStorage.setItem(
      'rememberReportFilters',
      JSON.stringify(this.rememberFilters)
    );
  };

  // Computed
  get stringifiedFiltersForLocalStorage() {
    return JSON.stringify({
      // Case report filters
      pivotOn: this.pivotOn,
      casesBy: this.casesBy,
      reportSettingsDateRange: this.reportSettingsDateRange,
      reportSettingsDateAfter: this.reportSettingsDateAfter,
      reportSettingsDateBefore: this.reportSettingsDateBefore,
      // Case detail filters.
      createdBy: this.createdBy,
      owner: this.owner,
      group: this.group,
      category: this.category,
      bundle: this.bundle,
      resolution: this.resolution,
      resolutionDescription: this.resolutionDescription,
      resolvedBy: this.resolvedBy,
      actionsTaken: this.actionsTaken,
      actionsDateRange: this.actionsDateRange,
      actionsDateAfter: this.actionsDateAfter,
      actionsDateBefore: this.actionsDateBefore,
      suspicionScore: this.suspicionScore,
      // User filters.
      userOrganization: this.userOrganization,
      userDepartment: this.userDepartment,
      userType: this.userType,
      userTags: this.userTags,
    });
  }

  get filtersFromLocalStorage() {
    try {
      return JSON.parse(localStorage.getItem(KEY));
    } catch (e) {
      return {};
    }
  }

  clearStoredFilters = () => {
    localStorage.removeItem(KEY);
  };

  /**
   * Computed
   * Values that will be used in data requests for the various charts.
   *
   * @return {Object}
   */
  get filterValuesForChartAndTable() {
    const fieldOverrides = {
      group: 'allowedGroupsFromSelection',
    };

    return [
      'pivotOn',
      'casesBy',
      'reportSettingsDateRange',
      'reportSettingsDateAfter',
      'reportSettingsDateBefore',
      'createdBy',
      'owner',
      'group',
      'category',
      'bundle',
      'resolution',
      'resolutionDescription',
      'resolvedBy',
      'actionsTaken',
      'actionsDateRange',
      'actionsDateAfter',
      'actionsDateBefore',
      'suspicionScore',
      'userOrganization',
      'userDepartment',
      'userType',
      'userTags',
      'excludeFilters',
    ].reduce((values, field) => {
      const value = this[fieldOverrides[field] || field];
      if (value) {
        values[field] = value;
      }
      return values;
    }, {});
  }

  queryForSelectionChange = (filter, values) =>
    queryForSelectionChange(filter, values, this.excludeFilters);

  queryForToggle = (filter, forFilter) =>
    queryForToggle(filter, forFilter, this);
}

decorate(CaseReportsFilterSelectionsStore, {
  createdBy: observable,
  owner: observable,
  group: observable,
  category: observable,
  bundle: observable,
  resolution: observable,
  resolutionDescription: observable,
  resolvedBy: observable,
  actionsTaken: observable,
  actionsDateRange: observable,
  actionsDateAfter: observable,
  actionsDateBefore: observable,
  suspicionScore: observable,
  userOrganization: observable,
  userDepartment: observable,
  userType: observable,
  userTags: observable,

  pivotOn: observable,
  casesBy: observable,
  reportSettingsDateRange: observable,
  reportSettingsDateAfter: observable,
  reportSettingsDateBefore: observable,
  rememberFilters: observable,

  excludeFilters: observable,
  ownerExclude: observable,
  createdByExclude: observable,
  groupExclude: observable,
  bundleExclude: observable,
  resolutionExclude: observable,
  resolutionDescriptionExclude: observable,
  resolvedByExclude: observable,
  actionsTakenExclude: observable,
  suspicionScoreExclude: observable,
  userTypeExclude: observable,
  userTagsExclude: observable,

  pivotOnOptions: computed,
  casesByOptions: computed,

  allowedGroupsFromSelection: computed,
  displayOwner: computed,
  displayCreatedBy: computed,
  displayGroup: computed,
  displayCategory: computed,
  displayBundle: computed,
  displayResolution: computed,
  displayResolutionDescription: computed,
  displayResolvedBy: computed,
  displayActions: computed,
  displayOrganization: computed,
  displayDepartment: computed,
  displayUserType: computed,
  displayTags: computed,
  stringifiedFiltersForLocalStorage: computed,
  filtersFromLocalStorage: computed,
  filterValuesForChartAndTable: computed,

  toggleRememberFilters: action,
  queryForToggle: action,
});
