import React from 'react';
import { observer } from 'mobx-react';
import { decorate, autorun, computed, observable } from 'mobx';
import PropTypes from 'prop-types';
import { SingletonStore, LoginClient, LoginStore } from 'common';

import Input from '../../ui/Input';

const QRCodeStore = class extends SingletonStore {
  constructor() {
    super();

    autorun(() => {
      this.refresh();
    });
  }

  // Computed
  get valid() {
    return LoginStore.loggedIn;
  }

  // Computed
  get imgSrc() {
    return `data:image/png;base64,${this.response}`;
  }

  fetch() {
    if (LoginStore.user && !LoginStore.mfaEnabled) {
      return LoginClient.qrCode();
    }
  }
};

decorate(QRCodeStore, {
  valid: computed,
  imgSrc: computed,
});

const qrCodeStore = new QRCodeStore();

const VerificationStore = class extends SingletonStore {
  constructor() {
    super();

    autorun(() => {
      this.refresh();
    });
  }

  // Observable
  code = null;
  success = false;

  // Computed
  get valid() {
    return LoginStore.loggedIn;
  }

  // Computed
  get failureMessage() {
    if (this.code) {
      if (this.failureStatus === 406) {
        return 'The code you entered could not be verified. Please try again.';
      } else if (this.failureStatus === 500) {
        return 'An internal server error occurred. Please try again, or contact support.';
      } else if (this.failureStatus) {
        return `The server responded with code ${this.code}. Please try again, or contact support.`;
      }
    }
    return null;
  }

  fetch() {
    if (this.code) {
      return LoginStore.enableMFA(this.code).then(jwt => {
        if (jwt) this.success = true;
      });
    }
  }
};

decorate(VerificationStore, {
  code: observable,
  failureMessage: computed,
  success: observable,
  valid: computed,
});

const verificationStore = new VerificationStore();

const QrCode = observer(
  class QrCode extends React.Component {
    static propTypes = {
      children: PropTypes.node,
      nextValue: PropTypes.string,
      onBackClick: PropTypes.func,
      onNextClick: PropTypes.func,
      step: PropTypes.string,
      type: PropTypes.string,
      page: PropTypes.string,
      phone: PropTypes.string,
    };

    static defaultProps = {
      nextValue: '',
      onBackClick: () => {},
      onNextClick: () => {},
      step: '',
      type: '',
      page: '',
      phone: '',
    };

    // Observable
    verificationCode = '';

    componentWillUnmount() {
      verificationStore.success = false;
      verificationStore.code = null;
    }

    handleTokenChange = text => {
      this.verificationCode = text;
    };

    handleSubmit = e => {
      const { nextValue, onNextClick } = this.props;
      e.preventDefault();
      if (nextValue === 'Verify') {
        verificationStore.code = this.verificationCode;
      } else if (nextValue === 'Next') {
        onNextClick();
        return;
      }
    };

    handleBackClick = () => {
      const { onBackClick } = this.props;

      verificationStore.code = null;
      onBackClick();
    };

    handleSuccessClick = () => {
      LoginStore.mfaLogin();
    };

    renderTitle() {
      const { step, page } = this.props;
      const title = '2-Step Verification';

      if (step === 'picture') {
        return (
          <h1 className="no-margin-bot">
            {title} <span className="normal-weight">{this.props.phone}</span>
          </h1>
        );
      } else if (!page && verificationStore.success) {
        return (
          <h1 className="no-margin-bot">
            {title} <span className="normal-weight">Success</span>
          </h1>
        );
      }
      return <h1 className="no-margin-bot">{title}</h1>;
    }

    renderQrCode() {
      const { type, step } = this.props;
      if (type === 'shortcut' || (type === 'step' && step === 'picture')) {
        return (
          <img
            className="qrCode"
            alt="QR code"
            style={{ width: 200, height: 200 }}
            src={qrCodeStore.imgSrc}
          />
        );
      }
      return null;
    }

    renderText() {
      const { children } = this.props;
      return children;
    }

    renderInput() {
      const { type, step } = this.props;

      if (type === 'shortcut' || (type === 'step' && step === 'code')) {
        return (
          <ul className="verification-code">
            <Input
              darkBackground
              borderRadius="md"
              error={!!verificationStore.failureMessage}
              label="Verification Code"
              maxLength={6}
              onChange={this.handleTokenChange}
              value={this.verificationCode}
            />
          </ul>
        );
      }
    }

    renderSuccess() {
      const { page } = this.props;
      if (!page) {
        return (
          <span className="success--message">
            <p>
              <strong>You’ve successfully enabled 2-Step Verification! </strong>
            </p>
            <p>
              This setup process will only need to be completed the first time
              you log in to your Bluesight site, or if you get a new mobile
              device.
            </p>
            <p>
              <strong>NOTE: </strong>
              All login attemps after this will ask you for your Username,
              Password, and the 6-digit vericiation code from the
              <strong> Google Authenticator </strong>
              app.
            </p>
            <input
              className="button"
              value="Enter Bluesight"
              readOnly
              onClick={this.handleSuccessClick}
            />
          </span>
        );
      }
    }

    render() {
      const { nextValue, children } = this.props;

      return (
        <form>
          {this.renderTitle()}
          {verificationStore.success && verificationStore.code ? (
            this.renderSuccess()
          ) : (
            <div className="mfa-qr">
              {this.renderQrCode()}
              {children}
              <p className="login-form--error">
                <strong>{verificationStore.failureMessage}</strong>
              </p>
              {this.renderInput()}
              <p className="mfa-buttons">
                <input
                  className="button button-back"
                  value="Back"
                  onClick={() => this.handleBackClick('Back')}
                  type="button"
                />
                <input
                  className="button"
                  value={nextValue}
                  type="submit"
                  onClick={this.handleSubmit}
                />
              </p>
            </div>
          )}
        </form>
      );
    }
  }
);

decorate(QrCode, {
  verificationCode: observable,
});

QrCode.displayName = 'QrCode';

export default QrCode;
