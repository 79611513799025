import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { autorun, computed, decorate, observable } from 'mobx';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { DropdownList } from 'react-widgets';
import classnames from 'classnames';

import {
  AlertCategoryStore,
  Authenticated,
  HalUtils,
  RouterContainer,
} from 'common';

import NotificationStore from '../../stores/NotificationStore';
import AuthorizationPermissionStore from '../stores/AuthorizationPermissionStore';

import {
  Button,
  DatePicker,
  DeleteForm,
  DocumentTitle,
  Input,
  Modal,
  MultilineInput,
} from '../../ui';
import RevokeForm from '../RevokeForm';
import CaseLI from '../../search/CaseLI/CaseCard';
import UserCardContainer from '../../people/cards/UserCardContainer';
import PatientCardContainer from '../../people/cards/PatientCardContainer';
import { toLocalDateString } from '../../utils/LocalDateUtils';

const DetailView = observer(
  class DetailView extends React.Component {
    constructor(props) {
      super(props);
      this.mobxAutorun = autorun(() => {
        this.load();
      });
    }

    static propTypes = {
      match: PropTypes.shape({
        params: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
      }),
    };

    // Observable
    startDate = moment();
    startDateValid = true;
    endDate = moment();
    endDateValid = true;
    reason = null;
    authType = null;

    // Computed
    get startDateDirty() {
      return !moment(this.authorization.start).isSame(this.startDate);
    }

    // Computed
    get endDateDirty() {
      return !moment(this.authorization.requestedEnd).isSame(this.endDate);
    }

    // Computed
    get reasonDirty() {
      return this.reason !== this.authorization.reason;
    }

    // Computed
    get typeDirty() {
      return this.authType !== this.authorization.type;
    }

    // Computed
    get dirty() {
      return (
        this.startDateDirty ||
        this.endDateDirty ||
        this.reasonDirty ||
        this.typeDirty
      );
    }

    // Computed
    get valid() {
      return this.startDateValid && this.endDateValid;
    }

    // Computed
    get user() {
      return AuthorizationPermissionStore.user;
    }

    // Computed
    get patient() {
      return AuthorizationPermissionStore.patient;
    }

    // Computed
    get relatedCase() {
      return AuthorizationPermissionStore.relatedCase;
    }

    // Computed
    get authorization() {
      return AuthorizationPermissionStore.result;
    }

    // Computed
    get view() {
      return this.relatedCase ? 'Temporary Permission' : 'Authorization';
    }

    // Computed
    get authorizationId() {
      const link = HalUtils.getLink(this.authorization, 'self');
      return HalUtils.getId(link);
    }

    get isActive() {
      return !this.isRevoked && !this.isExpired;
    }

    get isRevoked() {
      return this.authorization.status === 'REVOKED';
    }

    get isExpired() {
      return this.authorization.status === 'EXPIRED';
    }

    get futureRevocation() {
      return this.isActive && Boolean(this.authorization.revocationDate);
    }

    load() {
      const {
        start,
        requestedEnd,
        reason,
        type,
      } = AuthorizationPermissionStore.result;

      this.startDate = moment(start);
      this.startDateValid = true;
      this.endDate = moment(requestedEnd);
      this.endDateValid = true;
      this.reason = reason;
      this.authType = type;
    }

    componentDidMount() {
      AuthorizationPermissionStore.id = this.props.match.params.id;
    }

    componentWillUnmount() {
      this.mobxAutorun();
    }

    onStartChange = (date, valid) => {
      this.startDateValid = valid;
      if (valid) this.startDate = date;
    };

    onEndChange = (date, valid) => {
      this.endDateValid = valid;
      if (valid) this.endDate = date;
    };

    onReasonChange = reason => {
      this.reason = reason;
    };

    onTypeChange = type => {
      this.authType = type.value;
    };

    handleSubmit(e) {
      e.preventDefault();

      const updated = {};
      if (this.startDateDirty)
        updated.start = toLocalDateString(this.startDate);
      if (this.endDateDirty)
        updated.requestedEnd = toLocalDateString(this.endDate);
      if (this.reasonDirty) updated.reason = this.reason;
      if (this.typeDirty) updated.type = this.authType;

      const type =
        this.view === 'Authorization'
          ? 'authorized access'
          : 'temporary permission';

      AuthorizationPermissionStore.update(updated).then(
        () => {
          const content = (
            <span>
              <i className="material-icons icon-check_circle" />
              Successfully updated {type}
            </span>
          );
          NotificationStore.add({ level: 'success', content });
        },
        () => {
          const content = (
            <span>
              <i className="material-icons icon-warning" />
              There was a problem updating this {type}
            </span>
          );
          NotificationStore.add({ level: 'warning', content });
        }
      );
    }

    revokeAuthorization() {
      Modal.show(
        <Modal closable>
          <RevokeForm authId={this.authorizationId} view={this.view} />
        </Modal>
      );
    }

    destroyRecord() {
      const deletePromise = () =>
        AuthorizationPermissionStore.delete().then(() => {
          const link = this.relatedCase
            ? '/authorizations/temporary'
            : '/authorizations';
          RouterContainer.go(link);
        });

      const type =
        this.view === 'Authorization'
          ? 'authorized access'
          : 'temporary permission';

      Modal.show(
        <Modal closable>
          <DeleteForm
            modal
            description={`This ${type} will be permanently deleted and cannot be reauthorized.`}
            subject={this.view}
            submit={deletePromise}
          />
        </Modal>
      );
    }

    renderStatus() {
      if (this.authorizationId) {
        return (
          <h3>
            <span
              className={classnames('label label-danger', {
                hidden: !this.futureRevocation,
              })}
            >
              Upcoming Revocation &ndash;{' '}
              {moment(this.authorization.revocationDate).format('l')}
            </span>
            <span
              className={classnames('label label-danger', {
                hidden: !this.isRevoked,
              })}
            >
              Revoked &ndash;{' '}
              {moment(this.authorization.revocationDate).format('l')}
            </span>

            <span
              className={classnames('label label-danger', {
                hidden: !this.isExpired,
              })}
            >
              Expired &ndash;{' '}
              {moment(this.authorization.requestedEnd).format('l')}
            </span>
          </h3>
        );
      }
      return null;
    }

    renderCards() {
      const cards = [];
      const user = this.user;
      const patient = this.patient;
      const relatedCase = this.relatedCase;

      if (user) {
        cards.push(<UserCardContainer key="userCard" user={user} />);
      }

      if (patient) {
        cards.push(
          <PatientCardContainer key="patientCard" patient={patient} />
        );
      }

      if (relatedCase) {
        cards.push(
          <CaseLI
            key="caseCard"
            type="case"
            case={relatedCase}
            omitDetails
            useGroups={AlertCategoryStore.useGroups}
          />
        );
      }

      return (
        <article className="card_holder full_width margin_collapse">
          {cards}
        </article>
      );
    }

    renderUpdateBtn() {
      const disabled = !this.dirty;

      return (
        <Authenticated permission="ACCESS_AUTH_MODIFY">
          <Button
            className="button--cta"
            type="button"
            value={`Update ${this.view}`}
            onClick={this.handleSubmit.bind(this)}
            disabled={disabled}
            data-cy="update-patient-emr"
          />
        </Authenticated>
      );
    }

    renderCancelBtn() {
      if (this.dirty) {
        return (
          <Button
            type="button"
            value="Cancel"
            className="button--link"
            onClick={this.load.bind(this)}
          />
        );
      } else {
        return null;
      }
    }

    renderDeleteBtn() {
      return (
        <Authenticated permission="ACCESS_AUTH_DELETE">
          <span
            onClick={this.destroyRecord.bind(this)}
            className="float-right prot-a"
            data-cy="delete-patient-emr"
          >
            <i className="material-icons icon-delete text-danger" />
          </span>
        </Authenticated>
      );
    }

    renderActions() {
      if (this.isActive && !this.futureRevocation) {
        return (
          <li className="form__actions">
            {this.renderUpdateBtn()}

            <Authenticated permission="ACCESS_AUTH_REVOKE">
              <Button
                type="button"
                value={`Revoke ${this.view}`}
                className="button--danger"
                onClick={this.revokeAuthorization.bind(this)}
              />
            </Authenticated>

            {this.renderCancelBtn()}
            {this.renderDeleteBtn()}
          </li>
        );
      }
      return (
        <li className="form__actions">
          {this.renderUpdateBtn()}
          {this.renderCancelBtn()}
          {this.renderDeleteBtn()}
        </li>
      );
    }

    renderType() {
      if (this.relatedCase) return null;
      const options = AuthorizationPermissionStore.authTypes;

      let authType = options[0];

      options.forEach(i => {
        if (i.value === this.authType) authType = i;
      });

      if (AuthorizationPermissionStore.readOnly) {
        return (
          <Input label="Authorization Type" value={authType.label} readOnly />
        );
      }
      return (
        <DropdownList
          name="type"
          data={options}
          value={authType}
          onChange={this.onTypeChange}
          readOnly={AuthorizationPermissionStore.readOnly}
          valueField="value"
          textField="label"
        />
      );
    }

    renderForm() {
      if (this.authorizationId) {
        return (
          <form className="record__create-form record__form">
            <ul className="row no-gutter">
              <DatePicker
                name="startDate"
                className="split-two"
                value={this.startDate}
                onChange={this.onStartChange}
                placeholder={`${this.view} Start Date - MM/DD/YYYY`}
                disabled={AuthorizationPermissionStore.readOnly}
                label={`${this.view} Start Date`}
                maxDate={this.endDate}
              />

              <DatePicker
                name="endDate"
                className="split-two"
                onChange={this.onEndChange}
                value={this.endDate}
                placeholder={`${this.view} End Date - MM/DD/YYYY`}
                disabled={AuthorizationPermissionStore.readOnly}
                label={`${this.view} End Date`}
                minDate={this.startDate}
              />

              <li>
                <MultilineInput
                  value={this.reason}
                  label={`${this.view} Reason`}
                  placeholder={
                    AuthorizationPermissionStore.readOnly
                      ? ''
                      : `${this.view} Reason - Optional`
                  }
                  readOnly={AuthorizationPermissionStore.readOnly}
                  onChange={this.onReasonChange}
                  bordered
                />
              </li>

              <li className="input-container">{this.renderType()}</li>

              {this.renderActions()}
            </ul>
          </form>
        );
      }
      return null;
    }

    pageTitle() {
      let base = '/authorizations';
      let baseTitle = 'Authorized Accesses';
      let current = 'Authorization Details';

      if (this.relatedCase) {
        base = '/authorizations/temporary';
        baseTitle = 'Temporary Permissions';
        current = 'Temporary Permission Details';
      }

      const sectionLink = <Link to={base}>{baseTitle}</Link>;

      return (
        <span>
          {sectionLink}
          <i className="breadcrumbs__separator material-icons icon-keyboard_arrow_right" />
          {current}
        </span>
      );
    }

    render() {
      return (
        <Authenticated permission="ACCESS_AUTH_VIEW">
          <DocumentTitle
            text={
              RouterContainer.path && RouterContainer.path.includes('temporary')
                ? 'Temporary Permission'
                : 'Access Authorization'
            }
          />
          <div className="view-content">
            <div className="row no-gutter margin_collapse">
              <div className="col-xs-10 col-xs-offset-1">
                <section
                  className={classnames('view-content__body', {
                    hidden: AuthorizationPermissionStore.isLoading,
                  })}
                >
                  <header className="page-header">
                    <ul className="list-inline">
                      <li>
                        <h2>{this.pageTitle()}</h2>
                      </li>
                      <li className="record__status">{this.renderStatus()}</li>
                    </ul>
                  </header>
                  {this.renderCards()}
                  {this.renderForm()}
                </section>
              </div>
            </div>
          </div>
        </Authenticated>
      );
    }
  }
);

decorate(DetailView, {
  // Observables
  endDate: observable,
  endDateValid: observable,
  reason: observable,
  startDate: observable,
  startDateValid: observable,
  authType: observable,
  // Computeds
  authorization: computed,
  authorizationId: computed,
  dirty: computed,
  endDateDirty: computed,
  patient: computed,
  reasonDirty: computed,
  startDateDirty: computed,
  typeDirty: computed,
  user: computed,
  valid: computed,
  view: computed,
});

DetailView.displayName = 'DetailView';

export default DetailView;
