import React from 'react';
import PropTypes from 'prop-types';
import { CaseExportMenu, Spinner, TabBar } from '../../ui';
import CaseIconItem from '../CaseIconItem';
import moment from 'moment';
import { computeAssessmentAddedDate } from '../subViews/Caseflow/helper';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import styles from './index.module.scss';

const CaseTabs = ({
  analyticAssessments,
  analyticAssessmentLogs,
  caseCreatedDate,
  caseId,
  caseType,
  downloadCSV,
  eventTypes,
  isMergeUOA,
  isExportAvail,
  isUserDetails,
  links,
  mergeUOA,
  patientId,
  setMergeUOA,
  lastViewedByUser,
  medicationIncidents,
  caseIncidentsLoading,
  incidentsTabActive,
}) => {
  const cb = () => caseType === 'privacy' && downloadCSV('Accesses');
  const dateReducer = (latest, date) => (date > latest ? date : latest);
  const initVal = new Date(0).toISOString();

  // gets most recent timestamp for incident created, we want null for Privacy/GRC cases
  const lastIncidentDate =
    caseType === 'diversion'
      ? medicationIncidents.map(aa => aa.created).reduce(dateReducer, initVal)
      : null;

  // gets most recent timestamp for assessment dateAdded, we want null for Privacy/GRC cases
  const lastAssessmentDate =
    caseType === 'diversion'
      ? analyticAssessments
          .map(aa =>
            computeAssessmentAddedDate(
              caseCreatedDate,
              aa.minEventDate,
              aa.maxEventDate,
              analyticAssessmentLogs
            ).toISOString()
          )
          .reduce(dateReducer, initVal)
      : null;

  const showNewIcon = (date, gracePeriod) => {
    return (
      date &&
      (!lastViewedByUser ||
        moment(date)
          .add(gracePeriod ? 1 : 0, 'hour')
          .isAfter(moment(lastViewedByUser)))
    );
  };

  const showLoadingIcon = () => (
    <span className={styles.spinnerWrapper}>
      {incidentsTabActive && caseIncidentsLoading ? (
        <Spinner />
      ) : (
        <Spinner dark />
      )}
    </span>
  );

  if (!caseId) return null;

  const userTabLabel =
    caseType === 'diversion' ? 'User Details' : 'User & Patient Details';

  const skipRedirect = window.location.href.includes('?skipRedirect=true');
  const getRoute = url => {
    return skipRedirect ? `${url}?skipRedirect=true` : url;
  };

  return (
    <TabBar>
      <TabBar.Tab label="Caseflow" route={getRoute(`/case/${caseId}`)} exact />
      <TabBar.Tab
        label="Incidents"
        hidden={caseType === 'privacy'}
        route={`/case/${caseId}/incidents`}
        count={
          caseIncidentsLoading ? showLoadingIcon() : medicationIncidents.length
        }
        lastViewedByUser={lastViewedByUser}
        medicationIncidents={medicationIncidents}
        showNewIcon={showNewIcon(lastIncidentDate, true)}
      />
      <TabBar.Tab
        label="Accesses"
        hidden={
          // if diversion or a user only privacy case
          caseType === 'diversion' || !patientId
        }
        route={getRoute(`/case/${caseId}/accesses`)}
      />
      <TabBar.Tab
        label="Assessments"
        route={getRoute(`/case/${caseId}/assessments`)}
        count={analyticAssessments.length}
        lastViewedByUser={lastViewedByUser}
        analyticAssessments={analyticAssessments}
        showNewIcon={showNewIcon(lastAssessmentDate)}
      />

      <TabBar.Tab
        label={userTabLabel}
        route={getRoute(`/case/${caseId}/userDetails`)}
      />
      <TabBar.Actions>
        {isMergeUOA && (
          <CaseIconItem
            rotate={mergeUOA}
            tooltipContent={`${
              mergeUOA ? 'Exclude User Only Events' : 'Include User Only Events'
            }`}
            icon={`${mergeUOA ? 'icon-call_split' : 'icon-call_merge'}`}
            onClick={() => setMergeUOA(!mergeUOA)}
          />
        )}
        {isExportAvail && (
          <CaseExportMenu
            buttonClass="icon"
            iTip="Export CSV"
            exportCb={downloadCSV}
            cb={cb}
            eventTypes={eventTypes}
            menuDisabled={caseType === 'privacy'}
          />
        )}
        {isUserDetails &&
          (patientId ? (
            <>
              <CaseIconItem
                tooltipContent="EMR Workflow"
                icon="icon-local_hospital"
                route={links.userPatientActivity}
                data-cy="emr-icon"
              />
              <CaseIconItem
                tooltipContent="Compare View"
                icon="icon-compare_arrows"
                route={links.userPatient}
              />
              <CaseIconItem
                tooltipContent="Patient Careflow"
                icon="icon-folder_shared"
                route={links.patientUserActivity}
              />
            </>
          ) : (
            <CaseIconItem
              tooltipContent="EMR Workflow"
              icon="icon-local_hospital"
              route={links}
              data-cy="emr-icon"
            />
          ))}
      </TabBar.Actions>
    </TabBar>
  );
};

CaseTabs.propTypes = {
  analyticAssessments: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({})
  ),
  analyticAssessmentLogs: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({})
  ),
  caseCreatedDate: PropTypes.string,
  caseId: PropTypes.string,
  caseType: PropTypes.string.isRequired,
  downloadCSV: PropTypes.func.isRequired,
  eventTypes: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  isExportAvail: PropTypes.bool,
  isMergeUOA: PropTypes.bool,
  isUserDetails: PropTypes.bool,
  lastViewedByUser: PropTypes.string,
  links: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  medicationIncidents: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  mergeUOA: PropTypes.bool,
  patientId: PropTypes.string,
  employeeId: PropTypes.string,
  setMergeUOA: PropTypes.func,
  caseIncidentsLoading: PropTypes.bool,
  incidentsTabActive: PropTypes.bool,
};

CaseTabs.defaultProps = {
  caseId: null,
  eventTypes: [],
  isMergeUOA: false,
  isExportAvail: false,
  isUserDetails: false,
  incidentsTabActive: false,
  links: {},
  mergeUOA: false,
  patientId: '',
  setMergeUOA: () => {},
};

export default CaseTabs;
