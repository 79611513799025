import { decorate, observable, action, computed, reaction } from 'mobx';
import { HalUtils, IncidentClient, PermissionStore } from 'common';
import NotificationStore from '../../stores/NotificationStore';

class IncidentNoteAndResolutionStore {
  constructor({
    incidentsStore,
    permissionStore,
    notificationStore,
    incidentClient,
  }) {
    this.incidentsStore = incidentsStore;
    this.permissionStore = permissionStore;
    this.notificationStore = notificationStore;
    this.incidentClient = incidentClient;

    reaction(
      () => incidentsStore.loading,
      loading => {
        if (!loading) {
          // Data has refreshed. Reset the dirty map.
          this.reset();
        }
      }
    );
  }

  // Observable
  dirtyMap = {};

  // computed
  get hasUnsavedNotes() {
    return Object.values(this.dirtyMap).filter(Boolean).length > 0;
  }

  // computed
  get loading() {
    return this.incidentsStore.loading;
  }

  resolve = (incident, resolution) => {
    if (
      this.permissionStore.hasAny([
        'MEDICATION_INCIDENT_MODIFY_RESOLUTION',
        'INCIDENT_MODIFY_RESOLUTION',
      ])
    ) {
      this.incidentClient.resolve(HalUtils.getId(incident), resolution).then(
        res => {
          this.notificationStore.addSuccess('Incident resolution updated');
          this.incidentsStore.updateIncidentList(res);
        },
        () => {
          this.notificationStore.addError(
            'There was a problem updating the incident resolution'
          );
          this.incidentsStore.updateIncidentList({ id: 'refresh' });
        }
      );
    }
  };

  reopen = incident => {
    if (
      this.permissionStore.hasAny([
        'MEDICATION_INCIDENT_MODIFY_RESOLUTION',
        'INCIDENT_MODIFY_RESOLUTION',
      ])
    ) {
      this.incidentClient.reopen(HalUtils.getId(incident)).then(
        res => {
          this.notificationStore.addSuccess('Incident resolution updated');
          this.incidentsStore.updateIncidentList(res);
        },
        () => {
          this.notificationStore.addError(
            'There was a problem updating the incident resolution'
          );
          this.incidentsStore.updateIncidentList({ id: 'refresh' });
        }
      );
    }
  };

  // action
  setIncidentNoteDirty = (id, isDirty) => {
    this.dirtyMap = { ...this.dirtyMap, [id]: isDirty };
  };

  // action
  addNote = (id, note) => {
    if (
      this.permissionStore.hasAny([
        'MEDICATION_INCIDENT_MODIFY_NOTES',
        'INCIDENT_MODIFY_NOTES',
      ])
    ) {
      return this.incidentClient
        .addIncidentNote(id, note)
        .then(res => {
          this.incidentsStore.updateIncidentList(res);
          this.notificationStore.addSuccess('Note added successfully');
          this.setIncidentNoteDirty(id, false);
        })
        .catch(() => {
          this.notificationStore.addError(
            'There was a problem adding the incident note'
          );
        });
    }

    return Promise.reject();
  };

  // action
  deleteNote = (id, noteIndex) => {
    if (
      this.permissionStore.hasAny([
        'MEDICATION_INCIDENT_MODIFY_NOTES',
        'INCIDENT_MODIFY_NOTES',
      ])
    ) {
      return this.incidentClient
        .deleteIncidentNote(id, noteIndex)
        .then(res => {
          this.incidentsStore.updateIncidentList(res);
          this.notificationStore.addSuccess('Note deleted successfully');
        })
        .catch(() => {
          this.notificationStore.addError(
            'There was a problem deleting the incident note'
          );
        });
    }

    return Promise.reject();
  };

  // action
  reset = () => {
    this.dirtyMap = {};
  };
}

decorate(IncidentNoteAndResolutionStore, {
  //Computeds
  hasUnsavedNotes: computed,
  loading: computed,
  // Observables
  dirtyMap: observable,
  // Actions
  setIncidentNoteDirty: action,
  addNote: action,
  deleteNote: action,
  reset: action,
});

export { IncidentNoteAndResolutionStore };
export default ({ incidentsStore }) => {
  return new IncidentNoteAndResolutionStore({
    incidentsStore,
    permissionStore: PermissionStore,
    notificationStore: NotificationStore,
    incidentClient: IncidentClient,
  });
};
