import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { autorun, computed, decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { Authenticated, Pandy } from 'common';
import RegistryContainer from '../../ui/registry/RegistryContainer';
import ActionNav from '../../navbar/ActionNav';

const PeopleByTagListView = observer(
  class PeopleByTagListView extends React.Component {
    static propTypes = {
      peopleStore: PropTypes.object.isRequired,
      tagsStore: PropTypes.object.isRequired,
      sectionTitle: PropTypes.string.isRequired,
      viewPath: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(RegExp),
      ]).isRequired,
      createPath: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(RegExp),
      ]),
      detailsView: PropTypes.object,
      children: PropTypes.node,
      pageTitle: PropTypes.shape({}),
      tag: PropTypes.shape({
        name: PropTypes.string,
      }),
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
      }).isRequired,
    };

    // Observable
    tag;

    // Computed
    get isView() {
      const {
        viewPath,
        location: { pathname },
      } = this.props;

      if (viewPath instanceof RegExp) {
        return pathname.match(viewPath);
      } else {
        return pathname === viewPath;
      }
    }

    /**
     * COMPUTED
     * If available in the data store, return a download function.
     * Will return null if no function is available in the data store.
     */
    get downloadFn() {
      const { tagsStore } = this.props;

      if (tagsStore.downloadCSV) {
        /**
         * Create a downloadable blob of the response from the CSV endpoint.
         * @param {Object} e The Event Object
         */
        const downloadFn = e => {
          e.preventDefault();
          tagsStore.downloadCSV();
        };

        return downloadFn;
      }

      return null;
    }

    disposers = [
      autorun(() => {
        this.tag = this.props && this.props.tag;
      }),
    ];

    componentWillUnmount() {
      this.disposers.forEach(d => d());
    }

    renderPersonDetails() {
      const { detailsView, peopleStore } = this.props;

      if (this.isView && detailsView && peopleStore.activeUser)
        return detailsView;
    }

    render() {
      const { children, peopleStore, pageTitle, createPath, tag } = this.props;
      const shouldShow = !peopleStore.loading && peopleStore.size === 0;
      const tagName = tag ? tag.name : null;

      return (
        <Authenticated permission="PERSON_TAG_VIEW">
          <div
            className={classnames('tags__registry registry--flex', {
              'with-aside': this.isView,
            })}
          >
            <section className="panel__main">
              <ActionNav
                secondary
                createPath={createPath}
                createPermission={['PERSON_TAG_ASSIGN']}
                createIcon="add_circle"
                title={`${tagName} User`}
                createLabel={"Manage a User's Tags"}
                downloadCSV={this.downloadCSV}
                count={peopleStore.totalElements}
              />
              <RegistryContainer
                store={peopleStore}
                title={pageTitle}
                className="registry"
                pandyText={`${tagName} Tagged Users`}
              >
                <Pandy visible={shouldShow} mood="happy">
                  <div className="speechBubble">
                    <span>There are no people with this tag.</span>
                  </div>
                  <Authenticated permission="PERSON_TAG_ASSIGN">
                    <Link to={createPath} className="button button--cta">
                      Assign Tags
                    </Link>
                  </Authenticated>
                </Pandy>
                {children}
              </RegistryContainer>
            </section>
            {this.renderPersonDetails()}
          </div>
        </Authenticated>
      );
    }
  }
);

decorate(PeopleByTagListView, {
  tag: observable,
  isView: computed,
  downloadFn: computed,
});

PeopleByTagListView.displayName = 'PeopleByTagListView';

export default withRouter(PeopleByTagListView);
