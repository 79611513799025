import moment from 'moment-timezone';
import { autorun, computed, decorate } from 'mobx';

import { DateHelpers, HalUtils, AccessClient, SingletonStore } from 'common';

import Store from '../Store';

/**
 * Generic superclass for the two concrete stores. Standardizes the fetch call and parameters.
 */
class SingleAccessStore extends SingletonStore {
  constructor() {
    super();
    autorun(() => {
      this.refresh();
    });
  }

  // Computed
  get access() {
    const data = HalUtils.getData(this.result);
    return data && data[0];
  }

  fetch() {
    const { patientIdParam, userIdParam, timezone } = Store;
    if (patientIdParam && userIdParam) {
      const fp = Store.filterParams;

      const [
        shiftedFromDate,
        shiftedToDate,
      ] = DateHelpers.getActivityShiftedDatePair(
        this.fromDate,
        this.toDate,
        timezone
      );

      // have to subtract 1 from fromDate because the AccessClient considers the range inclusive
      return AccessClient.getByUsersAndPatient(
        [userIdParam],
        patientIdParam,
        shiftedFromDate,
        shiftedToDate,
        false,
        fp.filterField,
        fp.filterValues,
        this.sort,
        2
      );
    }
  }
}

decorate(SingleAccessStore, {
  access: computed,
});

/**
 * Store for the most recent past access between the selected patient and user in the activity view.
 */
class PastStore extends SingleAccessStore {
  sort = ['dateOfAccess,desc'];

  fromDate = moment('1971-01-01');

  // Computed
  get toDate() {
    return Store.fromDate.clone().subtract(1, 'days');
  }
}

decorate(PastStore, {
  toDate: computed,
});

const pastStore = new PastStore();

/**
 * Store for the closest future access between the selected patient and user in the activity view.
 */
class FutureStore extends SingleAccessStore {
  sort = ['dateOfAccess,asc'];

  // Computed
  get fromDate() {
    return Store.toDate.clone().add(1, 'days');
  }

  toDate = moment('2050-01-01');
}

decorate(FutureStore, {
  fromDate: computed,
});

const futureStore = new FutureStore();

/**
 * Just export the attributes needed by the visualization.
 */
class Stats {
  get pastLoading() {
    return pastStore.loading;
  }
  get past() {
    return pastStore.access;
  }
  get futureLoading() {
    return futureStore.loading;
  }
  get future() {
    return futureStore.access;
  }
}

decorate(Stats, {
  pastLoading: computed,
  past: computed,
  futureLoading: computed,
  future: computed,
});

export default new Stats();
