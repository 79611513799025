import { action, computed, observable, decorate, reaction } from 'mobx';
import moment from 'moment';
import {
  BaseURL,
  DateHelpers,
  DownloadUtils,
  IncidentClient,
  IncidentDescriptionStore,
  PagedStore,
  PermissionStore,
} from 'common';
import IncidentReportFilterStore from '../IncidentReportFilterStore';
import {
  getTypeMatchValue,
  updatedIncidentList,
} from '../../../utils/IncidentUtils';
import commaSeparatedToArray from '../../../utils/commaSeparatedToArray';
import {
  INCIDENT_SUMMARY_AND_EVENT_DETAILS_URL,
  INCIDENT_SUMMARY_URL,
} from '../consts';
import PinnedTimezoneStore from '../../../stores/PinnedTimezoneStore';

const {
  TIME_PERIOD_VALUES: { WEEK_EXACT },
} = DateHelpers;
const PAGE_SIZE = 20;

class IncidentReportStore extends PagedStore {
  constructor({
    incidentClient,
    incidentDescriptionStore = {},
    incidentReportFilterStore,
    pinnedTimezoneStore = {},
    permissionStore,
    downloadUtils,
  }) {
    super();

    this.incidentClient = incidentClient;
    this.incidentDescriptionStore = incidentDescriptionStore;
    this.incidentReportFilterStore = incidentReportFilterStore;
    this.permissionStore = permissionStore;
    this.pinnedTimezoneStore = pinnedTimezoneStore;
    this.updatedIncidentList = updatedIncidentList;
    this.downloadUtils = downloadUtils;

    reaction(
      // Stringify the fetchParams so the comparer tells us only when the values
      // change.
      () =>
        JSON.stringify([
          this.fetchParams,
          this.incidentDescriptionStore.defsMap,
          this.pinnedTimezoneStore.storedTimezone,
        ]),
      () => {
        this.refresh();
      }
    );

    reaction(
      () => this.loading,
      () => this.checkLoadingTime()
    );
  }

  //observable
  showSlowLoadingPrompt = false;
  loadingTimeout = null;

  // computed
  // A wrapper around loading states that offers a better UX than only showing
  // the loader component on the initial data load.
  get doneShowingLoader() {
    return !this.loading || this.paging;
  }

  // computed
  get readyToFetch() {
    const { query } = this.incidentReportFilterStore;
    return Object.values(query).length > 0;
  }

  // computed
  get fetchParams() {
    const query = { ...this.incidentReportFilterStore.query };
    const customDate = [query.rangeAfter, query.rangeBefore];
    const timezone = this.pinnedTimezoneStore.storedTimezone;
    if (!query.range) query.range = WEEK_EXACT; // This is not a default but for Array.map exception
    const [startDate, endDate] = DateHelpers.getDateParamsFromRange(
      query.range,
      customDate
    );
    const {
      userFullNames,
      patientFullNames,
      medications,
      medication,
      station,
      resolution,
      incidentIds,
      type,
      sort,
      groupBy,
      group,
      excludeFilters,
    } = query;
    const fetchParams = Object.entries({
      startTimeAfter: DateHelpers.formatDateWithISOOffset(startDate, timezone),
      endTimeBefore: DateHelpers.formatDateWithISOOffset(endDate, timezone),
      userFullNames: commaSeparatedToArray(userFullNames),
      patientFullNames: commaSeparatedToArray(patientFullNames),
      medications: commaSeparatedToArray(medications),
      medication,
      station: commaSeparatedToArray(station),
      resolution,
      group: commaSeparatedToArray(group),
      incidentId: commaSeparatedToArray(incidentIds),
      sort: `${groupBy},${sort}`,
      size: PAGE_SIZE,
      excludeFilters,
    }).reduce((params, [key, value]) => {
      // Do not include the param if the value is not set. We're careful not to
      // only check truthiness since an explicit value of false/0 may matter at
      // some point.
      if (value == null) return params;
      return { ...params, [key]: value };
    }, {});

    if (type) {
      let descriptionTypes = [];
      commaSeparatedToArray(type).forEach(item => {
        const mappedItem = Object.entries(this.incidentDescriptionStore.defsMap)
          .filter(([, description]) => description === item)
          .map(([rawType]) => rawType);

        descriptionTypes = [...descriptionTypes, ...mappedItem];
      });

      fetchParams.type = descriptionTypes;
    }

    if (resolution) {
      fetchParams.resolution = commaSeparatedToArray(resolution);
    }

    return fetchParams;
  }

  urlParamToHeaderType(param) {
    const urlToHeaderComponent = {
      station: 'ADC',
      user: 'User',
      patient: 'Patient',
      range: 'Date',
    };
    return urlToHeaderComponent[param] || 'Date';
  }

  // action
  updateIncidentList = async item => {
    // An incident has been updated with a new resolution.
    // If we don't find the id, just refresh the whole list.
    // If we aren't filtering by a resolution, update the item in place.
    // Otherwise refresh the store pages containing this item and those after.
    const index = this.results.findIndex(incident => incident.id === item.id);
    if (index < 0) {
      this.refresh();
    } else if (!this.fetchParams.resolution) {
      this.results = this.updatedIncidentList({
        item,
        incidents: this.results,
      });
    } else {
      const numCurrentlyLoadedPages = Math.ceil(
        this.results.length / PAGE_SIZE
      );
      const pageNumberWithChangedIncident = Math.floor(index / PAGE_SIZE);

      // Refresh the page containing the update and each one after. Go in
      // reverse order so we don't try to render duplicate incidents.
      for (
        let pageNumber = numCurrentlyLoadedPages - 1;
        pageNumber >= pageNumberWithChangedIncident;
        pageNumber--
      ) {
        await this.reloadPage(pageNumber);
      }
    }
  };

  // computed
  get urlGroupByParam() {
    const { query } = this.incidentReportFilterStore;
    return query.groupBy || 'range';
  }

  // computed
  get incidents() {
    return this.results instanceof Array ? this.results : [];
  }

  // computed
  get groupedIncidents() {
    let previousIncidentValue = false;
    const groupedIncidents = [];
    this.incidents.forEach(currentIncident => {
      const currentIncidentValue = getTypeMatchValue(
        currentIncident,
        this.urlGroupByParam
      );

      // the incidents come sorted from services, if the current incident value is not the same as the previous we have hit a new group
      if (currentIncidentValue === previousIncidentValue) {
        groupedIncidents[groupedIncidents.length - 1].incidents.push(
          currentIncident
        );
      } else {
        // we have hit the point where a new group needs to be made
        const newGroup = {
          type: this.urlGroupByParam,
          headerData: {
            topText: this.urlParamToHeaderType(this.urlGroupByParam),
            bottomText: currentIncidentValue,
          },
          incidents: [currentIncident],
        };

        // patient type is the only one with the extra text value
        if (this.urlGroupByParam === 'patient') {
          newGroup.headerData.extraText = `${(currentIncident?.patient
            ?.medicalRecordNumbers?.length &&
            currentIncident.patient.medicalRecordNumbers[0]) ||
            'No MRN'} - ${
            currentIncident?.patient?.dateOfBirth
              ? moment(currentIncident.patient.dateOfBirth).format('L')
              : 'No Date of Birth'
          }`;
        }
        previousIncidentValue = currentIncidentValue;
        groupedIncidents.push(newGroup);
      }
    });

    return groupedIncidents;
  }

  checkLoadingTime = () => {
    if (this.loading) {
      this.loadingTimeout = setTimeout(() => {
        this.showSlowLoadingPrompt = true;
      }, 5000);
    } else {
      clearTimeout(this.loadingTimeout);
      this.showSlowLoadingPrompt = false;
    }
  };

  fetch = () => {
    if (
      this.permissionStore.hasAny([
        'MEDICATION_INCIDENT_VIEW',
        'INCIDENT_VIEW',
      ]) &&
      this.readyToFetch
    ) {
      return this.incidentClient.getReport(this.fetchParams);
    }
  };

  downloadCSVIncidentSummary = () => {
    const url = BaseURL.service(
      INCIDENT_SUMMARY_URL,
      DateHelpers.paramDatesToISO(this.fetchParams, true)
    );
    const { startTimeAfter, endTimeBefore } = this.fetchParams;
    this.downloadUtils.downloadFromServer(
      url,
      !startTimeAfter
        ? `All Incidents Summary.csv`
        : `Incident Summary from ${moment(startTimeAfter).format(
            'MM-DD-YYYY'
          )} to ${moment(endTimeBefore).format('MM-DD-YYYY')}.csv`
    );
  };

  downloadCSVIncidentSummaryAndDetails = () => {
    const url = BaseURL.service(
      INCIDENT_SUMMARY_AND_EVENT_DETAILS_URL,
      DateHelpers.paramDatesToISO(this.fetchParams, true)
    );
    const { startTimeAfter, endTimeBefore } = this.fetchParams;
    this.downloadUtils.downloadFromServer(
      url,
      !startTimeAfter
        ? `All Incidents Summary & Event Details.csv`
        : `Incident Summary & Event Details from ${moment(
            startTimeAfter
          ).format('MM-DD-YYYY')} to ${moment(endTimeBefore).format(
            'MM-DD-YYYY'
          )}.csv`
    );
  };
}

decorate(IncidentReportStore, {
  // Observable
  loadingTimeout: observable,
  showSlowLoadingPrompt: observable,
  // Action
  updateIncidentList: action,
  checkLoadingTime: action,
  // Computeds
  doneShowingLoader: computed,
  fetchParams: computed,
  readyToFetch: computed,
  groupedIncidents: computed,
  incidents: computed,
  urlGroupByParam: computed,
});

export { IncidentReportStore };
export default new IncidentReportStore({
  incidentClient: IncidentClient,
  incidentDescriptionStore: IncidentDescriptionStore,
  incidentReportFilterStore: IncidentReportFilterStore,
  permissionStore: PermissionStore,
  pinnedTimezoneStore: PinnedTimezoneStore,
  downloadUtils: DownloadUtils,
});
