import { computed, decorate, reaction } from 'mobx';

import { DateHelpers, HalUtils, SingletonStore, TimeEntryClient } from 'common';
import MetalogStore from '../MetalogStore';
import Store from '../Store';

class TimeEntryStore extends SingletonStore {
  constructor() {
    super();

    reaction(
      () => [this.shouldFetch, this.fetchParams],
      () => this.refresh()
    );
  }

  fetch() {
    if (this.shouldFetch) {
      return TimeEntryClient.getByUsers(...this.fetchParams);
    }
  }

  // Computed
  get shouldFetch() {
    return (
      Store.isUserFocus &&
      Store.timeEntriesVisible &&
      MetalogStore.filteredMetalogs.length > 0
    );
  }

  // Computed
  get fetchParams() {
    const shiftedFromDate = DateHelpers.formatDateWithISOOffset(
      Store.fromDate.clone(),
      Store.timezone
    );

    const shiftedToDate = DateHelpers.formatDateWithISOOffset(
      Store.toDate.clone().add(1, 'days'),
      Store.timezone
    );

    return [Store.activeUsers.split(), shiftedFromDate, shiftedToDate];
  }

  // Computed
  get timeEntries() {
    return HalUtils.getData(this.result);
  }
}

decorate(TimeEntryStore, {
  timeEntries: computed,
  shouldFetch: computed,
  fetchParams: computed,
});

export default new TimeEntryStore();
