import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import classnames from 'classnames';
import Tooltip from '../../ui/Tooltip';

const BrushChart = class extends React.Component {
  static propTypes = {
    allowStartCut: PropTypes.bool,
    allowEndCut: PropTypes.bool,
    xScale: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired,
    isHidden: PropTypes.bool,
    selectedDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
    onSelectDates: PropTypes.func.isRequired,
    onCut: PropTypes.func.isRequired,
  };

  static defaultProps = {
    allowStartCut: false,
    allowEndCut: false,
    isHidden: false,
  };

  svgRef = createRef();
  ourSelectedDates = [null, null];
  brushExtentMargin = 15;

  componentDidMount() {
    this.drawSvg();
  }

  componentDidUpdate(prevProps) {
    // Only redraw SVG if the newly provided selected dates are different from
    // what we've kept track of or if the width has changed.
    const { selectedDates, width } = this.props;

    if (
      +selectedDates[0] !== +this.ourSelectedDates[0] ||
      +selectedDates[1] !== +this.ourSelectedDates[1] ||
      width !== prevProps.width
    ) {
      this.drawSvg();
    }
  }

  brushing = () => {
    const { xScale, onSelectDates } = this.props;
    if (d3.event && !d3.event.sourceEvent) return; // only transition after input

    const { selection } = d3.event;
    const [x0, x1] = selection;
    const selectedDates = [x0, x1].map(xScale.invert);

    if (x0 + 25 >= x1) {
      selectedDates[1] = xScale.invert(x0 + 25);
    }

    // if empty when rounded, use floor & ceil instead
    if (selectedDates[0] >= selectedDates[1]) {
      selectedDates[0] = d3.timeDay.floor(selectedDates[0]);
      selectedDates[1] = d3.timeDay.ceil(selectedDates[1]);
    }

    // Keep track of the dates we are showing, then notify the store.
    // This component is not the only thing that can update these selected
    // dates, so we do not assume that our selected dates are the source of
    // truth. But we do not want to automatically update dates when given new
    // ones, otherwise we'll trigger an infinite loop.
    this.ourSelectedDates = selectedDates;
    onSelectDates(selectedDates);

    this.updateCustomHandles(selection);
  };

  brushEnd() {
    if (d3.event.selection === null) {
      // If we've just clicked on a portion of the timeline outside of the
      // current selection, set our selection to be the clicked point in time.
      const [mouseX] = d3.mouse(this);
      const brush = d3.event.target;
      d3.select(this).call(brush.move, [mouseX, mouseX]);
    }
  }

  updateCustomHandles = selection => {
    // Prevent the knob from going off the rail
    let [x0, x1] = selection;
    const { width } = this.props;

    if (x0 < 0) x0 = 0;
    if (x1 < 0) x1 = 0;
    if (x0 > width - this.brushExtentMargin)
      x0 = width - this.brushExtentMargin; // Covers right-end of the brush
    if (x1 > width - this.brushExtentMargin)
      x1 = width - this.brushExtentMargin;

    selection = [x0, x1];

    this.customHandles.attr(
      'transform',
      (d, i) => `translate(${[selection[i] - 7, -2]})`
    );
  };

  drawSvg() {
    const { width, xScale, selectedDates } = this.props;
    try {
      const svg = d3.select(this.svgRef.current);

      svg.attr('width', Math.max(0, width));
      svg.select('g.x-axis.bottom').remove();

      const xAxisEl = svg
        .select('g.axis')
        .append('g')
        .attr('transform', 'translate(2, 10)')
        .classed('x-axis', true)
        .classed('bottom', true);

      const brush = (this.brush = d3
        .brushX()
        .extent([
          [0, 0],
          [
            Math.max(this.brushExtentMargin, width) - this.brushExtentMargin,
            10,
          ],
        ])
        .on('start brush', this.brushing)
        .on('end', this.brushEnd));

      const gBrush = xAxisEl
        .append('g')
        .attr('class', 'brush')
        .call(brush)
        .call(brush.move, selectedDates.map(xScale));

      gBrush
        .select('.overlay')
        .attr('rx', 5)
        .attr('ry', 5);

      this.customHandles = gBrush
        .selectAll('.handle--custom')
        .data([{ type: 'w' }, { type: 'e' }])
        .enter()
        .append('rect')
        .attr('class', d => `handle--custom handle--${d.type}`)
        .attr('width', 14)
        .attr('height', 15)
        .attr('rx', '50%')
        .attr('ry', '50%');

      this.updateCustomHandles(selectedDates.map(xScale));
    } catch (e) {
      console.warn('Tried to update unmounted component, BrushChart');
    }
  }

  render() {
    const { allowStartCut, allowEndCut, width, isHidden, onCut } = this.props;
    const transStart = 'translate(-2, 22)',
      transStartBG = 'translate(3, 17)',
      startClass = classnames('material-icons', {
        hidden: !allowStartCut,
      }),
      startClassBG = classnames({
        hidden: !allowStartCut,
      }),
      transEnd = `translate(${width - 5}, 12) rotate(180)`,
      transEndBG = `translate(${width - 10}, 17)`,
      endClass = classnames('material-icons', {
      hidden: !allowEndCut,
      }),
      endClassBG = classnames({
        hidden: !allowEndCut,
      });
    const brush = classnames('chart', 'brush_chart', {
      active: allowStartCut || allowEndCut,
      hidden: isHidden,
    });

    return (
      <svg className={brush} style={{ height: '45px' }} ref={this.svgRef}>
        <defs>
          <mask id="extentMask">
            <rect
              width={Math.max(width, 0)}
              height="6"
              transform="translate(0, 2)"
              fill="#ffffff"
            />
          </mask>
          <linearGradient id="gradientBar">
            <stop offset="0%" stopColor="rgba(0, 150, 186, 0.5)" />
            <stop offset="100%" stopColor="rgba(4, 74, 130, 0.8)" />
          </linearGradient>
        </defs>
        <g className="axis" />
        <Tooltip content="Trim Timeline">
          <g onClick={onCut} className="tooltips">
            <circle
              className={startClassBG}
              r="10"
              transform={transStartBG}
              fill="#044a82"
            />
            <text className={startClass} transform={transStart} fill="white">
              content_cut
            </text>
          </g>
        </Tooltip>
        <Tooltip content="Trim Timeline">
          <g onClick={onCut}>
            <circle
              className={endClassBG}
              r="10"
              transform={transEndBG}
              fill="#044a82"
            />
            <text className={endClass} transform={transEnd} fill="white">
              content_cut
            </text>
          </g>
        </Tooltip>
      </svg>
    );
  }
};

BrushChart.displayName = 'BrushChart';

export default BrushChart;
