import React from 'react';
import { computed, decorate, observable, reaction, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { DropdownList } from 'react-widgets';
import classnames from 'classnames';

import {
  AlertCategoryStore,
  EnumValueStore,
  GroupStore,
  HalUtils,
  CategoryComponent,
} from 'common';

import Button from '../../ui/Button';
import CaseStore from '../stores/CaseStore';
import NotificationStore from '../../stores/NotificationStore';

const TypeRoleForm = observer(
  class extends React.Component {
    constructor() {
      super();

      reaction(
        () => [this.isHiddenCategory],
        () => {
          if (this.isHiddenCategory) {
            const content = (
              <span>
                <i className="material-icons icon-warning" />
                By changing the Case Type you will lose visibility to this case
                based on your group settings or permissions.
              </span>
            );
            NotificationStore.add({
              level: 'warning',
              content,
              toastId: 'hiddenCase',
            });
          }
        }
      );
    }

    // Observable
    submitting = false;

    // Computed
    get isHiddenCategory() {
      return (
        CaseStore.isCaseTypeDirty &&
        CaseStore.newTypeCategory &&
        CaseStore.newTypeCategory.group &&
        !GroupStore.groups.find(
          g => g.id === CaseStore.newTypeCategory.group.id
        )
      );
    }

    onSubmit = e => {
      e.preventDefault();
      e.stopPropagation();

      if (CaseStore.isCaseTypeDirty || CaseStore.isUserRoleDirty) {
        this.submitting = true;

        const updated = {};
        if (CaseStore.isCaseTypeDirty)
          updated.category = HalUtils.getId(CaseStore.newTypeCategory);
        if (CaseStore.isUserRoleDirty)
          updated.userType = HalUtils.getId(CaseStore.newUserType);

        CaseStore.updateCase(updated)
          .then(() => {
            this.submitting = false;
          })
          .catch(() => {
            const content = (
              <span>
                <i className="material-icons icon-warning" />
                There was an issue updating this case. Please check your network
                connection and try again or contact the{' '}
                <a
                  href="https://help.protenus.com/hc/en-us"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Bluesight Help Center.
                </a>
              </span>
            );
            NotificationStore.add({ level: 'error', content });
          });
      }
    };

    renderUserRole() {
      const defaultValue = CaseStore.userType || {
        name: 'Select an option',
      };
      const value = CaseStore.newUserType || defaultValue;

      return (
        <div className="item">
          <h5>User Type</h5>
          <ul className="list-unstyled">
            <li>
              <DropdownList
                data={EnumValueStore.activeUserTypes}
                textField="name"
                valueField="id"
                value={value}
                readOnly={!CaseStore.canModifyUserType}
                onChange={CaseStore.setNewUserType}
              />
            </li>
          </ul>
        </div>
      );
    }

    renderCategoryWarning() {
      return (
        <p className="small text-center">
          By changing the Case Type you will lose visibility to this case based
          on your group settings or permissions.
        </p>
      );
    }

    render() {
      const buttonClass = classnames('list-unstyled', 'expando', {
        hidden: !(CaseStore.isCaseTypeDirty || CaseStore.isUserRoleDirty),
      });

      const defaultValue = CaseStore.typeCategory || {
        name: 'Select an Option',
      };
      const value = CaseStore.newTypeCategory || defaultValue;

      return (
        <form onSubmit={this.onSubmit}>
          <section>
            <div className="item">
              <h5>Case Type</h5>
              <ul className="list-unstyled" data-cy="case-type--dropdown">
                <li>
                  <DropdownList
                    data={toJS(AlertCategoryStore.categories)}
                    value={value}
                    readOnly={!CaseStore.canModifyCategory}
                    textField="name"
                    valueField="id"
                    valueComponent={CategoryComponent}
                    itemComponent={CategoryComponent}
                    onChange={CaseStore.setNewTypeCategory}
                  />
                </li>
              </ul>
            </div>
            {CaseStore.type !== 'GRC' &&
              EnumValueStore.activeUserTypes.length > 0 &&
              this.renderUserRole()}
          </section>
          <div className={buttonClass}>
            <ul>
              <li>
                <Button
                  type="submit"
                  disabled={this.submitting}
                  value="Update Case"
                />
              </li>
            </ul>
          </div>
          {this.isHiddenCategory && this.renderCategoryWarning()}
        </form>
      );
    }
  }
);

decorate(TypeRoleForm, {
  submitting: observable,
  isHiddenCategory: computed,
});

TypeRoleForm.displayName = 'TypeRoleForm';

export default TypeRoleForm;
