import React from 'react';
import PropTypes from 'prop-types';
import CaseIntegrationTable from '../../CaseIntegration/Table';
import { TABLE_CELL_TYPE } from '../../CaseIntegration/const';
import styles from './index.module.scss';

const protenusFromOrigami = [
  [
    { type: TABLE_CELL_TYPE.lock, text: ' Origami Incident ID' },
    {
      type: TABLE_CELL_TYPE.source,
      text: 'Incident ID',
      arrowBack: false,
    },
  ],
];

const OrigamiIntegrationTable = () => {
  const protenusToOrigami = [
    [
      { type: TABLE_CELL_TYPE.lock, text: 'Event Description' },
      { type: TABLE_CELL_TYPE.source, text: 'Case ID & Case Title' },
    ],
    [
      { type: TABLE_CELL_TYPE.lock, text: 'Employee Number' },
      { type: TABLE_CELL_TYPE.source, text: 'User ID' },
    ],
    [
      { type: TABLE_CELL_TYPE.lock, text: 'Employee Name' },
      { type: TABLE_CELL_TYPE.source, text: 'User Name' },
    ],
    [
      { type: TABLE_CELL_TYPE.lock, text: 'Department Name' },
      { type: TABLE_CELL_TYPE.source, text: `User's Department` },
    ],
    [
      { type: TABLE_CELL_TYPE.lock, text: 'First Name' },
      { type: TABLE_CELL_TYPE.source, text: 'Patient First Name' },
    ],
    [
      { type: TABLE_CELL_TYPE.lock, text: 'Last Name' },
      { type: TABLE_CELL_TYPE.source, text: 'Patient Last Name' },
    ],
    [
      { type: TABLE_CELL_TYPE.lock, text: 'MRN' },
      { type: TABLE_CELL_TYPE.source, text: 'Patient MRN' },
    ],
    [
      { type: TABLE_CELL_TYPE.lock, text: 'Birth Date' },
      { type: TABLE_CELL_TYPE.source, text: 'Patient DOB' },
    ],
    [
      { type: TABLE_CELL_TYPE.lock, text: 'Loss Description' },
      { type: TABLE_CELL_TYPE.source, text: 'Case Assessment' },
    ],
    [
      { type: TABLE_CELL_TYPE.lock, text: 'Discovery Date' },
      { type: TABLE_CELL_TYPE.source, text: 'Case Creation Date' },
    ],
    [
      { type: TABLE_CELL_TYPE.lock, text: 'Loss Date' },
      { type: TABLE_CELL_TYPE.source, text: 'Date of Access' },
    ],
    [
      { type: TABLE_CELL_TYPE.lock, text: 'Incident Notes & Emails' },
      { type: TABLE_CELL_TYPE.source, text: 'Case Notes & Emails' },
    ],
  ];

  return (
    <div
      className={styles.origamiIntegrationTable}
      data-cy="integration-origami-table"
    >
      <div className={styles.topSpacing}>
        <p>Fields to be added to Bluesight from Origami Incident:</p>
        <CaseIntegrationTable
          data={protenusFromOrigami}
          headers={['Origami Incident Field', 'Added to Bluesight Data As']}
        />
        <p className={styles.topSpacing}>
          Fields to be added to Origami Incident from Bluesight:
        </p>
        <CaseIntegrationTable
          data={protenusToOrigami}
          headers={['Origami Incident Field', 'Bluesight Source']}
        />
      </div>
    </div>
  );
};

OrigamiIntegrationTable.propTypes = {
  caseSyncSettings: PropTypes.shape({
    importId: PropTypes.bool,
    name: PropTypes.bool,
    description: PropTypes.bool,
    incidentDate: PropTypes.bool,
    dateReceived: PropTypes.bool,
    responsibleParty: PropTypes.bool,
    caseNotes: PropTypes.bool,
    caseActions: PropTypes.bool,
    caseResolution: PropTypes.bool,
    caseAttachments: PropTypes.bool,
    caseEmails: PropTypes.bool,
    caseFinalAssessment: PropTypes.bool,
    emrUserName: PropTypes.string,
  }),
  setCaseSyncSettings: PropTypes.func,
};

export default OrigamiIntegrationTable;
