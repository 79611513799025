import React from 'react';
import moment from 'moment';
import { autorun, computed, decorate } from 'mobx';

import {
  CaseClient,
  EmployeeCasesStore,
  SingletonStore,
  HalUtils,
  VisibleSystemStore,
  RouterContainer,
} from 'common';

import Store from '../Store';
import EventDetailedStore from '../../stores/EventDetailedStore';
import DiversionActivityStore from '../../stores/DiversionActivityStore';
import NotificationStore from '../../../stores/NotificationStore';
import RelationshipStore from '../../../stores/RelationshipStore';
import Utils from '../../../utils/CaseUtils';

class CaseStore extends SingletonStore {
  constructor({
    activityStore,
    diversionActivityStore,
    employeeCasesStore,
    eventDetailedStore,
    notificationStore,
    relationshipStore,
    visibleSystemStore,
  }) {
    super();

    this.activityStore = activityStore;
    this.diversionActivityStore = diversionActivityStore;
    this.employeeCasesStore = employeeCasesStore;
    this.eventDetailedStore = eventDetailedStore;
    this.notificationStore = notificationStore;
    this.relationshipStore = relationshipStore;
    this.visibleSystemStore = visibleSystemStore;

    autorun(() => {
      this.refresh();
    });
  }

  fetch() {
    if (this.activityStore.focus === 'patient') {
      const id = this.activityStore.patientIdParam;
      return CaseClient.findByPatient(id, undefined, 1000, 'summary');
    }
    if (
      this.activityStore.focus === 'employee' ||
      this.activityStore.focus === 'employee_accesses'
    ) {
      return this.activityStore.activeUsers
        ? CaseClient.findByUser(
            this.activityStore.activeUsers.split(','),
            undefined,
            1000,
            'summary'
          )
        : undefined;
    }

    return [];
  }

  createCase = newCase => {
    CaseClient.create(newCase)
      .then(caseId => {
        return `/case/${caseId}`;
      })
      .then(redirect => {
        this.eventDetailedStore.clearFlags();
        this.diversionActivityStore.clearFlags();
        Utils.synchronize();
        RouterContainer.go(redirect, {});
      })
      .catch(() => {
        const content = (
          <span>
            <i className="material-icons icon-warning" />
            There was an issue creating this case. Please check your network
            connection and try again or contact the{' '}
            <a
              href="https://help.protenus.com/hc/en-us"
              target="_blank"
              rel="noopener noreferrer"
            >
              Bluesight Help Center.
            </a>
          </span>
        );
        this.notificationStore.add({ level: 'error', content });
      });
  };

  addAssessmentsToDiversionCase = assessments => {
    const formattedAssessmentIDs = `diversionStatistics=${assessments
      .map(({ id }) => id)
      .join('&diversionStatistics=')}`;

    CaseClient.addAssessmentsToCase(formattedAssessmentIDs)
      .then(({ id }) => {
        this.eventDetailedStore.clearFlags();
        this.diversionActivityStore.clearFlags();
        Utils.synchronize();
        RouterContainer.go(`/case/${id}`, {});
      })
      .catch(() => {
        const content = (
          <span>
            <i className="material-icons icon-warning" />
            There was an issue adding assessment(s) to this case. Please check
            your network connection and try again or contact the{' '}
            <a
              href="https://help.protenus.com/hc/en-us"
              target="_blank"
              rel="noopener noreferrer"
            >
              Bluesight Help Center.
            </a>
          </span>
        );
        this.notificationStore.add({ level: 'error', content });
      });
  };

  addRanges = (keys, c, keyFunc, events, dateField) => {
    (events || []).forEach(e => {
      // hide case label if source is filtered, otherwise add label
      if (
        !e?.source ||
        !this.visibleSystemStore.filteredSources.includes(e?.source)
      ) {
        const key = keyFunc(c, e);
        if (!keys[key]) keys[key] = [];

        const date = moment(
          typeof dateField === 'string' ? e[dateField] : dateField(e)
        );

        if (
          date.isAfter(this.activityStore.fromDate) &&
          date.isBefore(moment(this.activityStore.toDate).add(1, 'day'))
        ) {
          keys[key].push({
            resolution: c.resolution,
            id: c.id + date.toDate(),
            caseId: c.id,
            dateOfEvent: date,
          });
        }
      }
    });
  };

  processCaseEvents = (keys, c, keyFunc, eventField, dateField) => {
    if (eventField === 'accesses')
      this.addRanges(keys, c, keyFunc, c[eventField], dateField);
    // non access events are nested inside incidents
    else
      (c.incidents || []).forEach(inc =>
        this.addRanges(keys, c, keyFunc, inc[eventField], dateField)
      );
  };

  // Computed
  get cases() {
    return HalUtils.getData(this.result) || [];
  }

  // Computed
  get userAliases() {
    const userId = this.activityStore.userId;
    if (!userId) return [];
    const linkRegex = /[/]users[/]/;
    const relationships =
      this.relationshipStore.userRelationships.get(userId) || [];
    return relationships.filter(
      r =>
        r.relationships.includes('alias') &&
        r.ids.every(({ _links: { self: { href } } }) => linkRegex.test(href))
    );
  }

  // Computed
  get openDiversionCasesByUserId() {
    const userId = this.activityStore.user?.id;
    // Grab the user's id as well as all ids from the aliases. Ensure uniqueness.
    const allRelevantUserIds = Array.from(
      new Set(
        [
          userId,
          ...this.userAliases.flatMap(a => a.ids.map(({ id }) => id)),
        ].filter(Boolean)
      ).values()
    );

    return allRelevantUserIds.reduce((casesById, userId) => {
      casesById[userId] = (
        this.employeeCasesStore.infoById.get(userId) || []
      ).filter(c => !c.resolution && c.type === 'diversion');
      return casesById;
    }, {});
  }

  // Computed
  get eventMap() {
    // these sub-maps should align with the types in the detailed store
    const eventMap = new Map();
    eventMap.set('access', new Map());
    eventMap.set('administration', new Map());
    eventMap.set('discrepancy', new Map());
    eventMap.set('handling', new Map());
    eventMap.set('order', new Map());
    eventMap.set('timeEntry', new Map());
    this.cases.forEach(c => {
      const caseObj = { caseId: c.id, resolution: c.resolution };
      if (c.accesses)
        c.accesses.forEach(a => eventMap.get('access').set(a.id, caseObj));
      if (c.incidents) {
        c.incidents.forEach(inc => {
          (inc.administrations || []).forEach(a =>
            eventMap.get('administration').set(HalUtils.getId(a), caseObj)
          );
          (inc.discrepancies || []).forEach(a =>
            eventMap.get('discrepancy').set(HalUtils.getId(a), caseObj)
          );
          (inc.handlings || []).forEach(a =>
            eventMap.get('handling').set(HalUtils.getId(a), caseObj)
          );
          (inc.orders || []).forEach(a =>
            eventMap.get('order').set(HalUtils.getId(a), caseObj)
          );
          (inc.timeEntries || []).forEach(a =>
            eventMap.get('timeEntry').set(HalUtils.getId(a), caseObj)
          );
        });
      }
    });
    return eventMap;
  }

  // Computed
  get rangesByYKey() {
    const m = {};
    this.cases
      .filter(c => c && c.userSummary && c.userSummary.id)
      .forEach(c => {
        this.processCaseEvents(
          m,
          c,
          (c, e) => (c.patientSummary?.id ? c.patientSummary?.id : e?.patient?.id || '') + c.userSummary.id,
          'accesses',
          'dateOfAccess'
        );
        this.processCaseEvents(
          m,
          c,
          (c, e) => ((e.patient && e.patient.id) || '') + c.userSummary.id,
          'administrations',
          'startTime'
        );
        this.processCaseEvents(
          m,
          c,
          c => c.userSummary.id,
          'discrepancies',
          d => d.resolutionTime
        );
        this.processCaseEvents(
          m,
          c,
          (c, e) => ((e.patient && e.patient.id) || '') + c.userSummary.id,
          'handlings',
          'eventTime'
        );
        this.processCaseEvents(
          m,
          c,
          (c, e) => ((e.patient && e.patient.id) || '') + c.userSummary.id,
          'orders',
          'startTime'
        );
      });
    return m;
  }
}

decorate(CaseStore, {
  cases: computed,
  userAliases: computed,
  openDiversionCasesByUserId: computed,
  eventMap: computed,
  rangesByYKey: computed,
});

export { CaseStore };
export default new CaseStore({
  activityStore: Store,
  diversionActivityStore: DiversionActivityStore,
  employeeCasesStore: EmployeeCasesStore,
  eventDetailedStore: EventDetailedStore,
  notificationStore: NotificationStore,
  relationshipStore: RelationshipStore,
  visibleSystemStore: VisibleSystemStore,
});
