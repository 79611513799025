import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IncidentPropTypes from './PropTypes';

import { Authenticated, MedicationUtils } from 'common';

import ResolutionSelect from './ResolutionSelect';
import NoteAction from './NoteAction';
import IncidentNotes from './IncidentNotes';

import styles from './index.module.scss';
import { Anchor, Tooltip } from '../index';

const IncidentReason = ({
  incident,
  link,
  reopen,
  resolve,
  addNote,
  deleteNote,
  setIncidentNoteDirty,
  loading,
  notes,
}) => {
  const summary = MedicationUtils.formatIncidentSummary(incident);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isAddingNote, setIsAddingNote] = useState(false);

  useEffect(() => {
    if (incident.forPrint) setIsExpanded(true);
  }, [incident.forPrint]);

  const toggleNotesExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div className={styles.reason} data-cy="incident-card-reason">
        <div>
          <Anchor tag={'a'} href={link} data-cy="incident-card-reason-link">
            <h3>{summary.description}</h3>
            {summary.value}
          </Anchor>
          {summary.value === '0 Changes' && (
            <Tooltip
              content="Cannot display proper change of custody sequence due to early administration."
              placement="top"
            >
              <span className={styles.tooltip}>{' Why?'}</span>
            </Tooltip>
          )}
          {incident.incidentId && (
            <div className={styles.idContainer}>
              <span className={styles.idText}>Incident</span>{' '}
              <span className={styles.idValue}>{incident.incidentId}</span>
            </div>
          )}
        </div>
        <div className={styles.actionsWrapper}>
          <Authenticated
            permission={[
              'MEDICATION_INCIDENT_MODIFY_NOTES',
              'INCIDENT_MODIFY_NOTES',
            ]}
            anyPermission
          >
            <NoteAction
              onInitializeNote={() => setIsAddingNote(true)}
              toggleExpanded={toggleNotesExpanded}
              isExpanded={isExpanded}
              notes={notes}
              openNotesDisabled={isAddingNote || loading || !notes?.length}
              addNoteDisabled={isAddingNote || loading}
            />
          </Authenticated>
          <Authenticated
            permission={[
              'MEDICATION_INCIDENT_MODIFY_RESOLUTION',
              'INCIDENT_MODIFY_RESOLUTION',
            ]}
            anyPermission
          >
            <ResolutionSelect
              onReopen={() => reopen(incident)}
              onResolve={resolution => resolve(incident, resolution)}
              resolution={incident.computedResolution}
            />
          </Authenticated>
        </div>
      </div>
      <IncidentNotes
        id={incident.id}
        notes={notes}
        onAddNote={(id, text) =>
          addNote(id, text).then(() => setIsAddingNote(false))
        }
        onCancelAddNote={() => {
          setIncidentNoteDirty(incident.id, false);
          setIsAddingNote(false);
        }}
        onDeleteNote={deleteNote}
        onDirtied={() => setIncidentNoteDirty(incident.id, true)}
        isAddingNote={isAddingNote}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
      />
    </>
  );
};

IncidentReason.propTypes = {
  incident: PropTypes.shape({
    id: PropTypes.string,
    incidentId: PropTypes.string,
    administrations: IncidentPropTypes.administrations,
    handlings: IncidentPropTypes.handlings,
    type: PropTypes.string.isRequired,
    resolution: PropTypes.string,
    computedResolution: PropTypes.string,
    forPrint: PropTypes.bool,
  }).isRequired,
  loading: PropTypes.bool,
  setIncidentNoteDirty: PropTypes.func.isRequired,
  reopen: PropTypes.func.isRequired,
  resolve: PropTypes.func.isRequired,
  addNote: PropTypes.func.isRequired,
  deleteNote: PropTypes.func.isRequired,
  link: PropTypes.string,
  notes: PropTypes.shape({
    length: PropTypes.number,
  }),
};

IncidentReason.defaultProps = {
  incident: PropTypes.shape({ forPrint: false }),
  link: '/',
  loading: false,
};

export default IncidentReason;
