import Tooltip from '../../ui/Tooltip';
import { Link } from 'react-router-dom';

const HomeItem = () => (
  <Tooltip content="Home" placement="bottom" offset={[0, 0]}>
    <li data-cy="nav-item">
      <Link to="/">
        <img alt="Home" src="/assets/img/logo_white.svg" />
      </Link>
    </li>
  </Tooltip>
);

export default HomeItem;
