import { VictoryTheme } from 'victory';

// Colors
export const allColors = [
  '#D9F4F8',
  '#CEDFFC',
  '#FCE7D1',
  '#FBD9D9',
  '#E8F8E7',
  '#D8D1FC',
  '#CFCED3',
  '#B4EAF1',
  '#9EBEF9',
  '#F8CFA3',
  '#F7B4B4',
  '#D2F0D0',
  '#B1A3F8',
  '#9F9DA7',
  '#8FE0EB',
  '#6E9FF7',
  '#F6B875',
  '#F38F8F',
  '#BCEAB9',
  '#8B75F6',
  '#706C7C',
  '#69D5E4',
  '#3D7EF4',
  '#F2A047',
  '#EF6969',
  '#A5E3A1',
  '#6447F2',
  '#403B50',
];

const standardColors = ['#f24335', '#16b434', '#e5e5e5', '#a5e3a1'];
const darkGrey = '#252525';
const grey = '#dad9dd';

// Typography
const sansSerif =
  "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif";

const letterSpacing = 'normal';
const fontSize = 14;

// Layout
const baseProps = {
  width: 450,
  height: 300,
  padding: 50,
  colorScale: standardColors,
};

// Labels
const baseLabelStyles = {
  fontFamily: sansSerif,
  fontSize,
  letterSpacing,
  padding: 10,
  fill: darkGrey,
  stroke: 'transparent',
};
const centeredLabelStyles = Object.assign(
  { textAnchor: 'middle' },
  baseLabelStyles
);

// Strokes
const strokeLinecap = 'round';
const strokeLinejoin = 'round';

// Put it all together...
export const StandardTheme = Object.assign(VictoryTheme.material, {
  axis: Object.assign(
    {
      style: {
        axis: {
          fill: 'transparent',
          stroke: grey,
          strokeWidth: 1,
          strokeLinecap,
          strokeLinejoin,
        },
        axisLabel: Object.assign({}, centeredLabelStyles, {
          padding: 25,
        }),
        grid: {
          fill: 'none',
          stroke: grey,
          pointerEvents: 'painted',
        },
        ticks: {
          fill: 'transparent',
          size: 1,
          stroke: 'transparent',
        },
        tickLabels: baseLabelStyles,
      },
    },
    baseProps
  ),
  bar: Object.assign(
    {
      domainPadding: 15,
    },
    baseProps
  ),
  group: Object.assign(
    {
      colorScale: standardColors,
    },
    baseProps
  ),
  legend: {
    colorScale: standardColors,
    gutter: 10,
    orientation: 'horizontal',
    titleOrientation: 'top',
    style: {
      data: {
        type: 'circle',
      },
      labels: baseLabelStyles,
      title: Object.assign({}, baseLabelStyles, { padding: 5 }),
    },
  },
  pie: {
    style: {
      data: {
        padding: 10,
        stroke: 'transparent',
        strokeWidth: 1,
      },
      labels: Object.assign({}, baseLabelStyles, { padding: 20 }),
    },
    colorScale: standardColors,
    width: 400,
    height: 400,
    padding: 50,
  },
  stack: Object.assign(
    {
      colorScale: standardColors,
    },
    baseProps
  ),
});

export default { StandardTheme, allColors };
