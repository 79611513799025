import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { action, computed, decorate, observable, when, reaction } from 'mobx';
import cn from 'classnames';

import Input from '../../../ui/Input';
import Checkbox from '../../../ui/Checkbox';
import TemporaryPermissionsSettingsStore from '../../../stores/TemporaryPermissionsSettingsStore';

const RADIOS = [
  <span key="radio-item-1">
    Create the Temporary Permission as soon as a case is resolved Not a
    Violation (False Positive) for the set amount of default time
  </span>,
  <span key="radio-item-2">
    Prompt me with options when the case is closed Not a Violation (False
    Positive) so I can make a decision for each case
  </span>,
];

const TemporaryPermissions = observer(
  class extends React.Component {
    static propTypes = {
      onDirtyChange: PropTypes.func.isRequired,
    };

    constructor() {
      super();
      // when there is a result object in Temporary permissions store, fill the local values
      when(
        () => TemporaryPermissionsSettingsStore.result,
        () => {
          this.active = TemporaryPermissionsSettingsStore.enabled;
          this.localPrompt = TemporaryPermissionsSettingsStore.creationPrompt;
          this.days = TemporaryPermissionsSettingsStore.duration;
        }
      );

      reaction(
        () => [this.cannotSubmit],
        ([cannotSubmit]) => {
          const { onDirtyChange } = this.props;
          onDirtyChange(!cannotSubmit);
        }
      );
    }

    // Observable
    active = TemporaryPermissionsSettingsStore.enabled;
    days = TemporaryPermissionsSettingsStore.duration;
    localPrompt = TemporaryPermissionsSettingsStore.creationPrompt;
    validDays = true;

    // Computed
    get cannotSubmit() {
      return (
        this.active === TemporaryPermissionsSettingsStore.enabled &&
        this.localPrompt === TemporaryPermissionsSettingsStore.creationPrompt &&
        this.days === TemporaryPermissionsSettingsStore.duration
      );
    }

    // Action
    toggleChecked(val) {
      this[val] = !this[val];
    }

    // Action
    changeDay(days) {
      this.validDays = true;
      this.days = days;
    }

    submit = () => {
      if (this.cannotSubmit) return;

      let days = this.days;
      if (days !== TemporaryPermissionsSettingsStore.duration) {
        // if the duration has been updated, make sure that it is a valid amount.
        if (!/^[1-9][0-9]*$/.test(days)) {
          // only bother validating the days if they have active Temp permissions
          if (this.active) {
            this.validDays = false;
            return;
          }
          // if the temp permissions aren't active just reset the messed with days to whatever it was before
          days = TemporaryPermissionsSettingsStore.duration;
        }
      }

      const params = Object.assign(
        TemporaryPermissionsSettingsStore.properties,
        {
          enabled: this.active,
          creationPrompt: this.localPrompt,
          duration: days,
        }
      );

      TemporaryPermissionsSettingsStore.update(params);
    };

    renderRadioButton(buttonText, idx) {
      // the first and second radio buttons should have flipped logic for checked and unchecked status
      let icon = this.localPrompt ? 'checked' : 'unchecked';
      if (!idx) icon = this.localPrompt ? 'unchecked' : 'checked';

      return (
        <p
          key={idx}
          role="radio"
          aria-checked={icon}
          className="toggle prot-a"
          onClick={() => this.toggleChecked('localPrompt')}
        >
          <i className={`material-icons icon-radio_button_${icon}`} />
          {buttonText}
        </p>
      );
    }

    renderOptions() {
      if (this.active) {
        return (
          <React.Fragment>
            <h2 className="options-header">Options:</h2>
            {RADIOS.map(this.renderRadioButton.bind(this))}
            {!this.validDays && (
              <div className="error">Please input a whole number above 0</div>
            )}
            <Input
              bordered
              className="duration-input"
              error={!this.validDays}
              label="Default Duration (Days)"
              name="limit"
              noToggle
              onChange={v => this.changeDay(v)}
              placeholder="30"
              type="number"
              value={this.days}
            />
          </React.Fragment>
        );
      }
    }

    render() {
      return (
        <div id="settings__wrapper-Temporary" className="wrapper">
          <div className="card_header">
            <h1>Temporary Permissions</h1>
            <span
              className={cn('prot-a', { muted: this.cannotSubmit })}
              onClick={this.submit}
            >
              Save Changes
            </span>
          </div>
          <div className="content">
            <p>
            Bluesight can create a temporary permission between a User and
              Patient when a case is resolved as Not a Violation (False
              Positive). This prevents additional cases from being created for
              this User and Patient until the Permission expires.
            </p>
            <Checkbox
              checked={this.active}
              tabIndex={0}
              color={'secondary'}
              onChange={() => this.toggleChecked('active')}
            >
              Enable Temporary Permissions
            </Checkbox>
            {this.renderOptions()}
          </div>
        </div>
      );
    }
  }
);

decorate(TemporaryPermissions, {
  toggleChecked: action,
  changeDay: action,
  cannotSubmit: computed,
  active: observable,
  days: observable,
  localPrompt: observable,
  validDays: observable,
});

TemporaryPermissions.displayName = 'TemporaryPermissions';

export default TemporaryPermissions;
