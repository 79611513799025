import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { DateHelpers } from 'common';
import { Tooltip, Source } from '../../ui';
import EncounterStore from '../../stores/EncounterStore';

const EncounterTooltip = observer(
  ({ encounterId, encounter, children, timezone }) => {
    const app = encounter || EncounterStore.infoById.get(encounterId);
    let content = 'Loading...';

    if (app) {
      content = app.type ? app.type : 'Encounter';
      if (app.startTime)
        content += ` on ${DateHelpers.formatDates(app, timezone)
          .join(' ')
          .trim()}`;

      if (app.department && app.department.name) {
        content += ` in ${app.department.name}`;
        if (app.department.other && app.department.other.facility) {
          content += ` (${app.department.other.facility})`;
        }
      }

      if (app.source) {
        content = (
          <div>
            {content}
            <br />
            <Source
              source={app.source}
              instance={app.instance}
              className="tooltip-small"
              raw
            />
          </div>
        );
      }

      // poorly populated encounter, have not ingested the full data yet so let the user know
      if (Object.keys(app).length < 4)
        content = 'Encounter (Details still to be processed)';
    }

    return <Tooltip content={content}>{children}</Tooltip>;
  }
);

EncounterTooltip.propTypes = {
  encounterId: PropTypes.string,
  encounter: PropTypes.object,
  children: PropTypes.node.isRequired,
};

EncounterTooltip.defaultProps = {
  encounterId: null,
  encounter: null,
};

export default EncounterTooltip;
