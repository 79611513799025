import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import classnames from 'classnames';
import styles from './index.module.scss';
import ResizeWatcher from '../../utils/ResizeWatcher';
import { validateEmail } from 'common';

const margin = { top: 15, right: 20, bottom: 10, left: 60 };

const fillInZeroes = (ticks, arr) => {
  return ticks.map(bucket => {
    return (
      arr.find(elt => elt.date.getTime() === bucket.getTime()) || {
        date: bucket,
        count: 0,
      }
    );
  });
};

const AuditLogChart = ({ data, selectedUser }) => {
  const svgRef = useRef();
  const containerRef = useRef();
  const resizeWatcher = useRef({});
  const x = useRef(null);
  const y = useRef(null);
  const xAxis = useRef(null);
  const yAxis = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const line = useRef(null);
  const hasContainerRef = !!containerRef.current;

  const resizeChart = useCallback(() => {
    setWidth(containerRef.current.offsetWidth - margin.left - margin.right);
    setHeight(containerRef.current.offsetHeight - margin.top - margin.bottom);

    x.current = d3.scaleTime().range([0, width]);
    y.current = d3.scaleLinear().range([height, 0]);

    xAxis.current = d3.axisBottom(x.current);
    yAxis.current = d3.axisLeft(y.current);

    // have to offset the x by 12 hours so that it appears in the middle of the time range
    line.current = d3
      .line()
      .x(d => x.current(d.date))
      .y(d => y.current(d.count));
  }, [height, width]);

  const updateChartData = useCallback(() => {
    const svg = d3.select(svgRef.current);

    x.current.domain(
      d3.extent(data, function(d) {
        return d.date;
      })
    );
    x.current.ticks(d3.timeDay, 1);
    y.current.domain([
      0,
      d3.max(data, function(d) {
        return d.count;
      }),
    ]);

    const dataByUser = d3
      .nest()
      .key(d => d.createdBy.username)
      .entries(data)

      .map(entry => ({
        ...entry,
        values: fillInZeroes(x.current.ticks(d3.timeDay, 1), entry.values),
      }));

    svg
      .select('g.x_axis')
      .attr('transform', 'translate(' + margin.left + ',' + height + ')')
      .call(xAxis.current);
    svg
      .select('g.y_axis')
      .attr('transform', 'translate(' + margin.left + ',0)')
      .call(yAxis.current);
    svg.select('g.main').attr('transform', 'translate(' + margin.left + ',0)');

    const selection = svg
      .select('g.main')
      .selectAll(`path.${styles.line}`)
      .data(dataByUser, d => d.key);

    selection
      .enter()
      .append('path')
      .attr('class', styles.line)
      .attr('stroke', d => {
        if (d.key === 'all') return '#000';
        // d.key is the username, which is either their email or something like
        // "admin" for testing.
        // If the person appears to be internal, we'll show their line in purple.
        if (validateEmail(d.key) && !isBluesightEmail(d.key)) {
          return '#ff9c40';
        }
        return '#643ef6';
      })
      .merge(selection)
      .transition()
      .attr('class', function(d) {
        return classnames(styles.line, {
          [styles.selected]: d.key && selectedUser?.username === d.key,
        });
      })
      .attr('d', function(d) {
        return line?.current(d.values);
      });

    selection.exit().remove();
  }, [data, height, selectedUser]);

  useEffect(() => {
    if (hasContainerRef) {
      resizeWatcher.current = new ResizeWatcher(containerRef.current, () => {
        resizeChart();
        updateChartData();
      });
      return () => resizeWatcher.current.off();
    }
  }, [hasContainerRef, resizeChart, updateChartData]);

  useEffect(() => {
    resizeChart();
    updateChartData();
  }, [data, selectedUser, resizeChart, updateChartData]);

  return (
    <div className={styles.container} ref={containerRef}>
      <svg
        ref={svgRef}
        className={styles.chart}
        width={width + margin.left + margin.right}
        height={height + margin.top + margin.bottom}
      >
        <g className="x_axis" />
        <g className="y_axis" />
        <g className="main" />
      </svg>
    </div>
  );
};
AuditLogChart.propTypes = {
  data: PropTypes.array,
  selectedUser: PropTypes.object,
};

export default AuditLogChart;
