import { VictoryTheme } from 'victory';
import styles from './index.module.scss';

// Colors
export const reportsColors = [
  '#163b58',
  '#245d8a',
  '#368bd0',
  '#86bee8',
  '#d6e8f7',
  '#820000', // <- till here are colors in the mock below are in case it goes over
  '#009f00',
  '#2340e5',
  '#e811ea',
  '#9f9500',
  '#007d94',
  '#030078',
  '#9f4000',
  '#A5E3A1',
  '#c47eff',
  '#403B50',
];

// get the current window's rem size
const remSizeInPx = parseInt(
  getComputedStyle(document.documentElement).fontSize
);

// convert string rem to float, i.e 10.6rem -> 10.6
const tdFontSizeRem = parseFloat(styles.tdFontSize);

export const ReportsBarChartTheme = {
  ...VictoryTheme.material,
  axis: {
    ...VictoryTheme.material.axis,
    style: {
      ...VictoryTheme.material.axis.style,
      axis: {
        stroke: styles.white500,
      },
      grid: {
        ...VictoryTheme.material.axis.style.grid,
        stroke: styles.white450,
      },
      tickLabels: {
        // This should be 11.52, or whatever the table's font size is
        fontSize: remSizeInPx * tdFontSizeRem,
        fontFamily: styles.primaryFont,
        padding: 6,
      },
    },
  },
  group: Object.assign({
    colorScale: reportsColors,
    style: { padding: 10 },
  }),
};

/**
 * Make a structure for rendering out custom legends.
 *
 * @param labelsArrays {string[][]} Nested array of labels.
 * @return {{labels: string[], color: string}[]}
 */
export function makeLegendData(labelsArrays, color) {
  return labelsArrays.map((labels, i) => ({
    labels,
    color: color || reportsColors[i],
  }));
}

export default { ReportsBarChartTheme };
