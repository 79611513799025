import React from 'react';
import { computed, decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import CaseUtils from '../../../utils/CaseUtils';
import { Button, MultilineInput } from '../../../ui';
import CaseStore from '../../stores/CaseStore';
import AssignForm from '../AssignForm';
import NotificationStore from '../../../stores/NotificationStore';

const AssignResolveForm = observer(
  class extends React.Component {
    // Observable
    submitting = false;
    note = '';

    // the previous button text. this is used to make sure the text doesn't change as the expando
    // animates closed
    lastButtonText = null;

    // Computed
    get buttonText() {
      let txt = this.lastButtonText;
      if (CaseStore.isOwnerDirty) {
        if (CaseStore.newOwner === 'Unassigned') txt = 'Unassign Case';
        else txt = 'Assign Case';
      }

      this.lastButtonText = txt;
      return txt;
    }

    onSubmit = e => {
      e.preventDefault();
      e.stopPropagation();

      if (this.submitting) return;

      let promise;

      if (CaseStore.isOwnerDirty) {
        this.submitting = true;
        if (CaseStore.newOwner === 'Unassigned') {
          promise = CaseStore.unassign();
        } else {
          const note = this.note;
          promise = CaseStore.assign(CaseStore.newOwner, note);
        }
      }

      if (promise) {
        promise
          .catch(xhr => {
            if (
              xhr.responseJSON &&
              xhr.responseJSON.message &&
              xhr.responseJSON.message.indexOf('amazonaws') !== -1
            ) {
              const content = (
                <span>
                  <i className="material-icons icon-warning" />
                  Case action completed. However, the case owner could not be
                  notified via email.
                </span>
              );
              NotificationStore.add({ level: 'warning', content });
            } else {
              const content = (
                <span>
                  <i className="material-icons icon-warning" />
                  There was an issue updating this case. Please check your
                  network connection and try again or contact the{' '}
                  <a
                    href="https://help.protenus.com/hc/en-us"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Bluesight Help Center.
                  </a>
                </span>
              );
              NotificationStore.add({ level: 'error', content });
            }
          })
          .always(() => {
            this.submitting = false;
            CaseStore.reopeningCaseFromTimeline = false;

            CaseUtils.synchronize();
          });
      }
    };

    renderAssignConfirm() {
      const expando = classnames('list-unstyled', 'expando', {
        'expando-hidden': !CaseStore.isOwnerDirty,
      });

      return (
        <div className={expando}>
          <MultilineInput
            value={this.note}
            placeholder="Add a note to the notification email (optional). Reminder: Do not include any PHI or PII in case assignment communications."
            onChange={val => (this.note = val)}
            bordered
          />
          <Button
            type="submit"
            disabled={this.submitting}
            value={this.buttonText}
            className="expando"
          />
        </div>
      );
    }

    render() {
      return (
        <form onSubmit={this.onSubmit}>
          <section>
            <AssignForm readOnly={CaseStore.isResolutionDirty} />
          </section>
          {this.renderAssignConfirm()}
        </form>
      );
    }
  }
);

decorate(AssignResolveForm, {
  submitting: observable,
  note: observable,
  buttonText: computed,
});

AssignResolveForm.displayName = 'AssignResolveForm';

export default AssignResolveForm;
