import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { DropdownList, Multiselect } from 'react-widgets';

import {
  AsidePanel,
  Checkbox,
  Button,
  Input,
  PromptWrapper,
} from '../../../ui';
import styles from './index.module.scss';

const EnumsManageForm = ({
  enumValueFields,
  onUpdateEnumValueField,
  saveEnumValue,
  categories,
  isValid,
  isDirty,
  failure,
  selectedEnumValueId,
  onChangeSelectedEnumValueId,
  helperText,
  groups,
  disabledGroups,
  onCloseForm,
}) => {
  useEffect(() => {
    onChangeSelectedEnumValueId(selectedEnumValueId);
    return () => onChangeSelectedEnumValueId(null);
  }, [onChangeSelectedEnumValueId, selectedEnumValueId]);

  const title = `${selectedEnumValueId ? 'Update' : 'Create'} Value`;

  return (
    <AsidePanel title={title} isOpen onClose={onCloseForm}>
      <form
        className={styles.form}
        onSubmit={e => {
          e.preventDefault();
          isValid && saveEnumValue();
        }}
      >
        {selectedEnumValueId
          ? null
          : categories.length > 0 && (
              <>
                <DropdownList
                  className={styles.dropdownWrapper}
                  valueField="id"
                  textField="name"
                  data={categories.slice()}
                  placeholder="Select a Category"
                  value={enumValueFields.category}
                  defualtValue={categories[0]}
                  onChange={value => onUpdateEnumValueField('category', value)}
                />
                <div className={styles.helpText}>
                  {enumValueFields.category && helperText.create
                    ? helperText.create[enumValueFields.category]
                    : null}
                </div>
              </>
            )}
        <Input
          bordered
          label="Name"
          margin="md"
          onChange={value => onUpdateEnumValueField('name', value)}
          placeholder="Enter name of enum value"
          value={enumValueFields.name}
        />
        <Input
          bordered
          label="Description"
          margin="md"
          onChange={value => onUpdateEnumValueField('description', value)}
          placeholder="Enter description"
          value={enumValueFields.description}
        />
        {groups.length > 0 && (
          <Multiselect
            data={groups.slice()}
            disabled={disabledGroups.slice()}
            placeholder="Visible to All"
            textField="name"
            valueField="id"
            value={enumValueFields.groups.slice()}
            onChange={value => onUpdateEnumValueField('groups', value)}
          />
        )}
        {selectedEnumValueId && (
          <div className={styles.checkboxWrapper}>
            <Checkbox
              label="Deprecated"
              checked={enumValueFields.deprecated}
              onChange={() =>
                onUpdateEnumValueField(
                  'deprecated',
                  !enumValueFields.deprecated
                )
              }
            />
          </div>
        )}
        {failure && <p className={styles.failure}>{failure}</p>}
        <Button
          className="button--cta"
          type="submit"
          value={selectedEnumValueId ? 'Update' : 'Create'}
          disabled={!isValid || !isDirty}
        />
        {selectedEnumValueId ? (
          <p className={styles.note}>{helperText.update.note}</p>
        ) : (
          <p className={styles.note}>{helperText.create.note}</p>
        )}
      </form>
      <PromptWrapper when={isDirty} />
    </AsidePanel>
  );
};

EnumsManageForm.propTypes = {
  enumValueFields: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    category: PropTypes.string,
    groups: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
    deprecated: PropTypes.bool,
  }).isRequired,
  onUpdateEnumValueField: PropTypes.func.isRequired,
  saveEnumValue: PropTypes.func.isRequired,
  categories: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.string)
    .isRequired,
  groups: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  disabledGroups: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  isValid: PropTypes.bool.isRequired,
  isDirty: PropTypes.bool.isRequired,
  failure: PropTypes.string,
  selectedEnumValueId: PropTypes.string,
  onChangeSelectedEnumValueId: PropTypes.func.isRequired,
  helperText: PropTypes.shape({
    create: PropTypes.shape({
      note: PropTypes.string,
    }),
    update: PropTypes.shape({
      note: PropTypes.string,
    }),
  }),
  onCloseForm: PropTypes.func,
};

EnumsManageForm.defaultProps = {
  failure: null,
  selectedEnumValueId: null,
  groups: [],
  disabledGroups: [],
  helperText: {
    create: {
      user: '',
      caseAction: '',
      note: '',
    },
    groups: [],
    update: { note: '' },
  },
  onCloseForm: () => {},
};
export default EnumsManageForm;
