import React from 'react';
import { observer } from 'mobx-react';
import { DropdownList } from 'react-widgets';
import { Authenticated } from 'common';
import NavBarItem from '../../navbar/NavBarItem';
import NavBarSpacer from '../../navbar/NavBarSpacer';
import TopNavBar from '../../navbar/TopNavBar';
import SearchStore from '../stores/SearchStore';
import { Spinner } from '../../ui';
import styles from './index.module.scss';

const SearchViewTopNav = observer(
  class SearchViewTopNav extends React.Component {
    handleSortChange = obj => {
      SearchStore.cases.sort = obj.value;
    };

    renderSort() {
      const data = [
        { value: 'created,desc', name: 'Created' },
        { value: 'lastModified,desc', name: 'Last modified' },
        { value: 'score,desc', name: 'Relevance' },
        { value: 'resolution,asc', name: 'Resolution State' },
        { value: 'suspicionScore,desc', name: 'Suspicion Score' },
      ].filter(d => d);

      if (SearchStore.type === 'cases') {
        return (
          <NavBarItem className="advanced-sort__sort-list-item">
            <DropdownList
              data={data}
              valueField="value"
              textField="name"
              defaultValue={data[2]}
              onChange={this.handleSortChange}
            />
          </NavBarItem>
        );
      }
    }

    renderCount(store) {
      return store.loading ? (
        <span className={styles.spinnerWrapper}>
          <Spinner />
        </span>
      ) : (
        <strong>
          {store.totalElements ? store.totalElements : 0}
          {store.maxed ? '+' : ''}
        </strong>
      );
    }

    render() {
      return (
        <TopNavBar filterBar>
          <Authenticated permission="USER_SEARCH">
            <NavBarItem route={SearchStore.path('users')}>
              EMR Users {this.renderCount(SearchStore.users)}
            </NavBarItem>
          </Authenticated>
          <Authenticated permission="PATIENT_SEARCH">
            <NavBarItem route={SearchStore.path('patients')}>
              Patients {this.renderCount(SearchStore.patients)}
            </NavBarItem>
          </Authenticated>
          <Authenticated permission="CASE_SEARCH">
            <NavBarItem route={SearchStore.path('cases')}>
              Cases {this.renderCount(SearchStore.cases)}
            </NavBarItem>
          </Authenticated>
          <Authenticated permission="CASE_SEARCH">
            <NavBarItem route={SearchStore.path('comments')}>
              Comments {this.renderCount(SearchStore.comments)}
            </NavBarItem>
          </Authenticated>
          <Authenticated permission="ACCESS_AUTH_SEARCH_ALL">
            <NavBarItem route={SearchStore.path('authorizations')}>
              Authorizations & Permissions {this.renderCount(SearchStore.authorizations)}
            </NavBarItem>
          </Authenticated>
          <NavBarSpacer />
          {this.renderSort()}
        </TopNavBar>
      );
    }
  }
);

SearchViewTopNav.displayName = 'SearchViewTopNav';

export default SearchViewTopNav;
