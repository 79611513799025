import React from 'react';
import { Authenticated, RouterContainer } from 'common';
import CreateCaseForm from '../CreateCaseForm';
import { Modal } from '../../ui';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import styles from './index.module.scss';
import AddToCase from '../AddToCase';
import { createCase } from './helpers';

const CasePromotion = ({
  addAssessments,
  categories,
  createCaseType,
  flaggedAssessments,
  flaggedEvents,
  hasOverlappingCaseAssessments,
  onSubmit,
  openDiversionCasesByUserId,
  permissions,
}) => {
  const userForFlaggedAssessmentsHasOpenDiversionCase =
    flaggedAssessments.length > 0 &&
    (openDiversionCasesByUserId[flaggedAssessments[0].user.id] || []).length >
      0;
  const buttonText =
    userForFlaggedAssessmentsHasOpenDiversionCase &&
    createCaseType === 'DiversionCase'
      ? 'Add to Case'
      : 'Create New Case';

  const handleCreateCaseNew = caseType => {
    Modal.show(
      <Modal closable>
        <CreateCaseForm
          buttonText={buttonText}
          categories={categories}
          onSubmit={category =>
            onSubmit(
              createCase(caseType, category, flaggedAssessments, flaggedEvents)
            )
          }
        />
      </Modal>
    );
  };

  const handleAddToCase = () => {
    const [caseToAddTo] = openDiversionCasesByUserId[
      flaggedAssessments[0].user.id
    ];
    const overlapText = {
      header: 'Overlapping Assessment Dates',
      p:
        'At least one of the assessments you’ve selected has dates that overlap with existing assessments in this case.  Please confirm that you would like to continue.',
    };

    const noOverlapText = {
      header: 'Heads Up!',
      p:
        'The assessment(s) you have selected will be added to the existing open case for this user.',
    };

    Modal.show(
      <Modal closable>
        <AddToCase
          link={RouterContainer.href(`/case/${caseToAddTo?.id}`)}
          headerText={
            hasOverlappingCaseAssessments
              ? overlapText.header
              : noOverlapText.header
          }
          pText={
            hasOverlappingCaseAssessments ? overlapText.p : noOverlapText.p
          }
          onClick={() => addAssessments(flaggedAssessments)}
        />
      </Modal>
    );
  };

  return (
    <Authenticated permission={permissions} key="create-case">
      <div className={styles.createCase} data-cy="case-promotion-button">
        <div
          onClick={() =>
            userForFlaggedAssessmentsHasOpenDiversionCase
              ? handleAddToCase(createCaseType)
              : handleCreateCaseNew(createCaseType)
          }
          className="button button--mini button--cta"
        >
          {buttonText}
        </div>
      </div>
    </Authenticated>
  );
};

CasePromotion.displayName = 'CasePromotion';

export default CasePromotion;

CasePromotion.propTypes = {
  /* Diversion Case Props */
  addAssessments: PropTypes.func,
  flaggedAssessments: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      suspicionScore: PropTypes.number,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
      reasons: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
    })
  ),
  openDiversionCasesByUserId: PropTypes.shape(),
  /* Privacy Case Props */
  flaggedEvents: PropTypes.shape({
    accessBucketId: PropTypes.string,
    patient: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  hasOverlappingCaseAssessments: PropTypes.bool,
  /* Shared Props */
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  createCaseType: PropTypes.string,
  onSubmit: PropTypes.func,
  permissions: PropTypes.arrayOf(PropTypes.string),
  patient: PropTypes.object,
};

CasePromotion.defaultProps = {
  /* Diversion Case Props */
  addAssessments: () => {},
  flaggedAssessments: [],
  hasOverlappingCaseAssessments: false,
  openDiversionCasesByUserId: {},
  /* Privacy Case Props */
  flaggedEvents: {},
  /* Shared Props */
  categories: [],
  createCaseType: '',
  onSubmit: () => {},
  patient: {},
  permissions: [],
};
