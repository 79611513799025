import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import * as d3 from 'd3';

import drawVectorIcons from '../XAxisIcons';
import drawTicks from '../XAxisTicks';

const TIME_ENTRY_ICON = (
  <g className="icon_wrapper" style={{ opacity: 0 }}>
    <path
      d="M7.49291 0.125C3.58291 0.125 0.416664 3.29833 0.416664 7.20833C0.416664 11.1183 3.58291 14.2917 7.49291 14.2917C11.41 14.2917 14.5833 11.1183 14.5833 7.20833C14.5833 3.29833 11.41 0.125 7.49291 0.125Z"
      fill="white"
    />
    <path
      d="M7.49291 0.125C3.58291 0.125 0.416664 3.29833 0.416664 7.20833C0.416664 11.1183 3.58291 14.2917 7.49291 14.2917C11.41 14.2917 14.5833 11.1183 14.5833 7.20833C14.5833 3.29833 11.41 0.125 7.49291 0.125ZM7.5 12.875C4.36916 12.875 1.83333 10.3392 1.83333 7.20833C1.83333 4.0775 4.36916 1.54167 7.5 1.54167C10.6308 1.54167 13.1667 4.0775 13.1667 7.20833C13.1667 10.3392 10.6308 12.875 7.5 12.875Z"
      fill="rgba(0, 150, 184, 0.65)"
    />
    <path
      d="M7.85416 2.95834H6.79166V7.20834L10.5104 9.43959L11.0417 8.56834L7.85416 6.67709V2.95834Z"
      fill="rgba(0, 150, 184, 0.65)"
    />
  </g>
);
const ENCOUNTER_ICON = (
  <g className="icon_wrapper" style={{ opacity: 0 }}>
    <path
      d="M7.49292 0.416656C3.58292 0.416656 0.416672 3.58999 0.416672 7.49999C0.416672 11.41 3.58292 14.5833 7.49292 14.5833C11.41 14.5833 14.5833 11.41 14.5833 7.49999C14.5833 3.58999 11.41 0.416656 7.49292 0.416656Z"
      fill="white"
    />
    <path
      d="M7.49291 0.416656C3.58291 0.416656 0.409576 3.58999 0.409576 7.49999C0.409576 11.41 3.58291 14.5833 7.49291 14.5833C11.4029 14.5833 14.5762 11.41 14.5762 7.49999C14.5762 3.58999 11.4029 0.416656 7.49291 0.416656ZM10.05 4.90749C10.8079 4.90749 11.4171 5.51666 11.4171 6.27457C11.4171 7.03249 10.8079 7.64166 10.05 7.64166C9.29208 7.64166 8.68291 7.03249 8.68291 6.27457C8.67583 5.51666 9.29208 4.90749 10.05 4.90749ZM5.79999 3.78832C6.72083 3.78832 7.47166 4.53916 7.47166 5.45999C7.47166 6.38082 6.72083 7.13166 5.79999 7.13166C4.87916 7.13166 4.12833 6.38082 4.12833 5.45999C4.12833 4.53207 4.87208 3.78832 5.79999 3.78832ZM5.79999 10.2554V12.9117C4.09999 12.3804 2.75416 11.07 2.15916 9.39832C2.90291 8.60499 4.75874 8.20124 5.79999 8.20124C6.17541 8.20124 6.64999 8.25791 7.14583 8.35707C5.98416 8.97332 5.79999 9.78791 5.79999 10.2554ZM7.49291 13.1667C7.30166 13.1667 7.11749 13.1596 6.93333 13.1383V10.2554C6.93333 9.24957 9.01583 8.74666 10.05 8.74666C10.8079 8.74666 12.1183 9.02291 12.77 9.56124C11.9412 11.665 9.89416 13.1667 7.49291 13.1667Z"
      fill="rgba(0, 150, 184, 0.65)"
    />
  </g>
);

// responsible for housing updated props
class XAxisChart extends React.Component {
  // Ref
  xAxis = React.createRef();

  componentDidMount() {
    this.redraw(true);
  }

  componentDidUpdate() {
    this.redraw();
  }

  redraw(useDelay = false) {
    const {
      brushedXScale,
      width,
      tickFormat,
      margin,
      detailView,
      encounters,
      timeEntries,
      timezone,
    } = this.props;
    const svg = d3.select(this.xAxis.current);

    // Draw encounters or time entries.
    // The setTimeout here is necessary for first render.
    const renderingEncounters = encounters.length > 0;
    setTimeout(
      () => {
        drawVectorIcons(
          svg,
          renderingEncounters ? encounters : timeEntries,
          renderingEncounters ? ENCOUNTER_ICON : TIME_ENTRY_ICON,
          renderingEncounters ? 'encounters' : 'timeEntries',
          brushedXScale,
          timezone
        );
      },
      useDelay ? 200 : 0
    );

    // draw ticks, dates
    drawTicks(svg, brushedXScale, width, tickFormat, margin);
  }

  render() {
    const { width, padding, margin } = this.props;
    const trans = 'translate(' + margin.left + ',0)',
      xAxisWidth = Math.max(0, width - padding.left - padding.right);
    return (
      <div className="x_axis_element">
        <p className="chartLabel">Showing events from:</p>
        <svg
          ref={this.xAxis}
          className="chart x_axis x_axis_chart"
          width={xAxisWidth}
          height="100%"
          overflow="auto"
        >
          <defs>
            <clipPath id="vectorIconsClipPath">
              <rect
                width={width - margin.left - padding.left - padding.right}
                height="999"
                transform={`translate(${margin.left + padding.left},-99)`}
              />
            </clipPath>
          </defs>
          <g className="axis" />
          <g clipPath="url(#vectorIconsClipPath)">
            <g className="vector_icons" transform={trans} />
          </g>
        </svg>
      </div>
    );
  }
}

XAxisChart.propTypes = {
  width: PropTypes.number,
  padding: PropTypes.shape({
    left: PropTypes.number,
    right: PropTypes.number,
  }),
  margin: PropTypes.shape({
    left: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
  }),
  brushedXScale: PropTypes.func,
  tickFormat: PropTypes.func,
  detailView: PropTypes.string,
  encounters: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  timeEntries: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
};

XAxisChart.displayName = 'XAxisChart';

export default XAxisChart;
