import React from 'react';

export default function LoginFooter() {
  return (
    <footer className="row">
      <div className="col-xs-12 col-sm-3 footer-card">
        <a href="https://help.protenus.com/hc/en-us/sections/1500000091181">
          <div>
            <img alt="Release Notes" src="/assets/img/releaseNotes.svg" />
            <h3> Release Notes </h3>
          </div>
          <p>
            {' '}
            Want to see what we&apos;ve been working on? Check out the release
            notes for our bi-weekly product updates.{' '}
          </p>
        </a>
      </div>
      <div className="col-xs-12 col-sm-3 footer-card">
        <a href="https://academy.protenus.com/">
          <div>
            <img alt="Bluesight Academy" src="/assets/img/school.svg" />
            <h3> Bluesight Academy </h3>
          </div>
          <p>
            {' '}
            Access on-demand training to learn about working in the Bluesight
            platform.{' '}
          </p>
        </a>
      </div>
      <div className="col-xs-12 col-sm-3 footer-card">
        <a href="https://pandas.protenus.com/">
          <div>
            <img alt="PANDAS Community" src="/assets/img/community.svg" />
            <h3> PANDAS Community </h3>
          </div>
          <p>
            {' '}
            Find out about future events or discuss questions with your PANDAS
            colleagues.{' '}
          </p>
        </a>
      </div>
    </footer>
  );
}
