import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { computed, decorate } from 'mobx';
import { observer } from 'mobx-react';
import * as d3 from 'd3';

import { ChartCanvas, DonutChart } from '../../ui/charts';
import LegendItem from '../LegendItem';
import BundleDataStore from '../stores/BundleDataStore';

const BundleDataVisualization = observer(
  class BundleDataVisualization extends Component {
    static propTypes = {
      bundle: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
    };
    /**
     * COMPUTED
     * Compute the total value of items in the chart
     * @return {Number} the calculated value.
     */
    get total() {
      return BundleDataStore.data.reduce(
        (total, d) =>
          d && Object.prototype.hasOwnProperty.call(d, 'count')
            ? total + d.count
            : total,
        0
      );
    }

    // Computed
    get range() {
      return BundleDataStore.data
        .map(d => d.count && d.color)
        .filter(c => Boolean(c));
    }

    /**
     * Event handler for mouseover event. Highlights the hovered-over segment and
     * sets the value of that segment in the store as the "active" segment. Sets
     * the other segments to a semi-transparent state and updates the large
     * "total" value in the chart.
     * @param {Object} d - the data object for the active segment
     */
    mouseover = d => {
      BundleDataStore.active = d.data.group;
      d3.selectAll(
        `.chart__donut-segment:not([data-group='${d.data.group}'])`
      ).style('opacity', 0.3);

      d3.select('.chart__donut-total-value').text(d.data.count);
    };

    /**
     * Event handler for mouseout event. Resets the store, other chart segments,
     * and "total" value to their non-active values.
     */
    mouseout = () => {
      BundleDataStore.active = null;
      d3.selectAll('.chart__donut-segment').style('opacity', 1);

      d3.select('.chart__donut-total-value').text(this.total);
    };

    renderChart() {
      const { bundle } = this.props;
      const canvasSize = 150;

      if (bundle) {
        return (
          <div className="chart__container gradient">
            <ChartCanvas>
              <DonutChart
                label="Cases"
                colors={{
                  UNRESOLVED: '#fff',
                  NOT_VIOLATION: '#245d8a',
                  VIOLATION: '#163b58',
                }}
                canvasSize={canvasSize}
                store={BundleDataStore}
                ringWidth={20}
                mouseover={this.mouseover}
                mouseout={this.mouseout}
              />
            </ChartCanvas>
            <ul className="bundle__chart-legend">
              {BundleDataStore.data.map(d => (
                <LegendItem
                  key={d.type}
                  type={d.type}
                  store={BundleDataStore}
                />
              ))}
            </ul>
          </div>
        );
      }
    }

    render() {
      return <section className="chart">{this.renderChart()}</section>;
    }
  }
);

decorate(BundleDataVisualization, {
  total: computed,
  range: computed,
});

export default BundleDataVisualization;
