import { action, computed, decorate, observable, reaction } from 'mobx';
import moment from 'moment';

import { EncounterClient, PagedStore } from 'common';

class PatientEncountersStore extends PagedStore {
  constructor() {
    super();
    reaction(
      () => [this.patientId, this.timebase],
      () => this.refresh()
    );
  }

  // Observable
  patientId = '';
  // Observable; a reference date to determine which encounters are future vs
  // past. Set using a case created date and only when we are looking at a case.
  _timebase = null;

  // Action
  setPatientId = patientId => {
    this.patientId = patientId;
  };

  // Action
  setTimebase = timebase => {
    this._timebase = timebase;
  };

  // Computed
  get encounters() {
    return this.results || [];
  }

  // Computed
  get timebase() {
    // If there is an existing timebase (e.g. case created), use the start of that day.
    // Otherwise, use the current timestamp.
    return this._timebase ? moment(this._timebase).startOf('day') : moment();
  }

  // Computed
  get futureEncounters() {
    return this.encounters
      .filter(i => {
        return moment(i.startTime).diff(this.timebase) > 0;
      })
      .sort((a, b) => (moment(a.startTime).diff(b.startTime) > 0 ? 1 : -1));
  }

  // Computed
  get pastEncounters() {
    return this.encounters.filter(
      i => moment(i.startTime).diff(this.timebase) <= 0
    );
  }

  // Computed
  get mostRecent() {
    return this.pastEncounters && this.pastEncounters[0];
  }

  fetch() {
    if (this.patientId && this.timebase) {
      return Promise.all([
        EncounterClient.getByPatientAndStartTimeLessThanEqual(
          this.patientId,
          this.timebase
        ),
        EncounterClient.getByPatientAndStartTimeGreaterThanEqual(
          this.patientId,
          this.timebase
        ),
      ]).then(result => {
        return result.reduce((acc, result) => {
          return acc.concat(result._embedded.encounter);
        }, []);
      });
    } else {
      Promise.resolve();
    }
  }
}

decorate(PatientEncountersStore, {
  patientId: observable,
  _timebase: observable,
  setPatientId: action,
  setTimebase: action,
  encounters: computed,
  futureEncounters: computed,
  pastEncounters: computed,
  mostRecent: computed,
  timebase: computed,
});

export default new PatientEncountersStore();
