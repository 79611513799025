import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import {
  AsidePanel,
  Checkbox,
  Button,
  Input,
  PromptWrapper,
} from '../../../ui';
import Label from '../../../ui/Label';
import { Authenticated } from 'common';
import styles from './index.module.scss';
import { DropdownList, Multiselect } from 'react-widgets';
import { ACCOUNT_STATUS_OPTIONS, CREATE_PATH } from '../const';
import ActionLink from './ActionLink';
import AccountStatusDropdownItem from './AccountStatusDropdownItem';
import classnames from 'classnames';
import pluralize from '../../../utils/pluralize';

const CustomerAdministeredAccountsManageForm = ({
  availableRoles,
  editedFields,
  emailValidityProperties,
  groups,
  history,
  isFormDirty,
  isFormValid,
  isSamlEnabled,
  isSavedUserEnabled,
  isSavedUserTriage,
  isUserLocked,
  licenseLimit,
  licensesRemaining,
  licenseLimitReachedFromEdit,
  resetMFA,
  save,
  saveError,
  sendWelcomeEmail,
  setField,
  setUserId,
  unlockAccount,
  userId,
  viewRoute,
}) => {
  useEffect(() => {
    // If the current path changes, either we selected another user, closed the aside, or opened the create
    // aside. This block will load the user ID based on the new path
    if (history.location.pathname === CREATE_PATH) {
      setUserId(undefined);
    } else {
      const userId = history.location.pathname.substring(
        history.location.pathname.lastIndexOf('/') + 1
      );
      setUserId(userId);
    }

    return () => {
      setUserId(undefined);
    };
  }, [history.location.pathname, setUserId]);

  const {
    isSavedUserDomainInvalid,
    isEmailInvalid,
    emailErrorMessage,
  } = emailValidityProperties;

  const makeLicenseLabel = () => {
    if (licenseLimitReachedFromEdit) {
      // We allow certain edits/creations even if you are at the limit.
      // When this condition is true, you're either creating a new person who
      // violates the limit or editing somebody to violate the limit.
      return (
        <Label
          value={`${
            userId ? 'Changes made to' : 'Creating'
          } this user account will exceed the license limit of ${licenseLimit}.`}
          className={classnames(
            styles.licenseLabel,
            styles.licenseLimitReached
          )}
        />
      );
    }

    if (licenseLimit === null) {
      return (
        <Label
          value={`You have unlimited licenses remaining.`}
          className={styles.licenseLabel}
        />
      );
    }

    return (
      <Label
        data-cy="create-app-user--license-limit--text"
        value={`You have ${licensesRemaining} ${pluralize(
          licensesRemaining,
          'license'
        )} remaining.\nLicense limit: ${licenseLimit}`}
        className={styles.licenseLabel}
      />
    );
  };

  return (
    <AsidePanel
      title={`${userId ? 'Update' : 'Create'} App User`}
      isOpen
      onClose={() => history.push(viewRoute)}
    >
      {makeLicenseLabel()}

      <form
        className={styles.form}
        onSubmit={e => {
          e.preventDefault();
          isFormValid && save();
        }}
      >
        <h3 className={styles.header}>User Details</h3>

        <Input
          bordered
          disabled={isSavedUserDomainInvalid}
          label="First Name"
          margin="md"
          onChange={value => setField('firstName', value)}
          placeholder="First Name"
          value={editedFields.firstName}
          name="create-app-user--first-name--input"
        />

        <Input
          bordered
          disabled={isSavedUserDomainInvalid}
          label="Last Name"
          margin="md"
          onChange={value => setField('lastName', value)}
          placeholder="Last Name"
          value={editedFields.lastName}
          name="create-app-user--last-name--input"
        />

        <Input
          bordered
          disabled={isSavedUserDomainInvalid}
          label="Username"
          margin="md"
          onChange={value => setField('username', value)}
          placeholder="Username"
          value={editedFields.username}
          name="create-app-user--username--input"
        />

        {!isSamlEnabled && (
          <Input
            bordered
            disabled={isSavedUserDomainInvalid}
            label={userId ? 'New Password' : 'Initial Password'}
            margin="md"
            onChange={value => setField('password', value)}
            placeholder={userId ? 'New Password' : 'Initial Password'}
            type="password"
            value={editedFields.password}
            name="create-app-user--password--input"
          />
        )}

        <Input
          bordered
          disabled={isSavedUserDomainInvalid}
          error={!isSavedUserDomainInvalid && isEmailInvalid}
          errorMessage={emailErrorMessage}
          label="Email"
          margin="md"
          onChange={value => setField('email', value)}
          placeholder="Email"
          type="email"
          value={editedFields.email}
          name="create-app-user--email--input"
        />

        {isSavedUserEnabled && !isSavedUserTriage && !isSavedUserDomainInvalid && (
          <ActionLink
            iconClass="icon-email"
            onClick={() => {
              window.confirm(
                'Are you sure you want to send a welcome email?'
              ) && sendWelcomeEmail();
            }}
            text="Send Welcome Email"
            data-cy="app-users--welcome-email--link"
          />
        )}

        <h3
          className={classnames(styles.header, styles.topMargin)}
          data-cy="aside-panel--account-settings--text"
        >
          Account Settings
        </h3>

        <div className={styles.accountStatusContainer}>
          <DropdownList
            data={ACCOUNT_STATUS_OPTIONS}
            disabled={isSavedUserDomainInvalid}
            itemComponent={({ item }) => (
              <AccountStatusDropdownItem item={item} />
            )}
            onChange={({ value }) => setField('enabled', value)}
            placeholder="Account Status"
            textField="label"
            value={ACCOUNT_STATUS_OPTIONS.find(
              option => option.value === editedFields.enabled
            )}
            valueComponent={({ item }) => (
              <AccountStatusDropdownItem
                item={item}
                renderLabel
                label="Account Status"
                data-cy="create-app-user--account-status--dropdown"
              />
            )}
            valueField="value"
          />
          {isUserLocked && !isSavedUserDomainInvalid && (
            <div className={styles.lockContainer}>
              <span className={styles.lockText}>
                This user account is locked.
              </span>
              <ActionLink
                iconClass="icon-lock"
                onClick={() => {
                  window.confirm(
                    'Are you sure you want to unlock this account?'
                  ) && unlockAccount();
                }}
                text="Unlock Account"
              />
            </div>
          )}
        </div>

        <div className={styles.multiselectLabel}>
          <span>Roles</span>
        </div>

        <Multiselect
          data={availableRoles}
          placeholder="Roles"
          value={editedFields.roles?.slice()}
          onChange={value =>
            setField(
              'roles',
              value.map(role => role.name)
            )
          }
          textField="name"
          valueField="name"
          itemComponent={({ item }) => (
            <span>
              <strong>{item.name}</strong> - {item.description}
            </span>
          )}
          className="create-app-user--roles--multiselect"
        />

        <div className={styles.multiselectLabel}>
          <span>Groups</span>
        </div>

        {groups.length > 0 && (
          <Multiselect
            data={
              editedFields.groups?.length < groups.length
                ? [{ name: 'SELECT ALL GROUPS', id: 'ALL' }, ...groups]
                : groups.slice()
            }
            placeholder="Groups"
            textField="name"
            valueField="id"
            value={editedFields.groups?.slice()}
            onChange={value => setField('groups', value)}
            className="create-app-user--groups--multiselect"
          />
        )}

        {isSavedUserEnabled &&
          !isSavedUserTriage &&
          !isSavedUserDomainInvalid &&
          !isSamlEnabled && (
            <ActionLink
              iconClass="icon-shield"
              onClick={() => {
                window.confirm('Are you sure you want to reset MFA?') &&
                  resetMFA();
              }}
              text="Reset Multi-Factor Auth Settings"
            />
          )}

        <Authenticated permission={'APP_USER_SET_TRIAGE'}>
          <h3 className={classnames(styles.header, styles.topMargin)}>
            Triage
          </h3>

          <div className={styles.triageWrapper}>
            <Checkbox
              label="Triage User"
              disabled={isSavedUserTriage || isSavedUserDomainInvalid}
              checked={!!editedFields.triage}
              onChange={() => setField('triage', !editedFields.triage)}
            />
          </div>
        </Authenticated>

        {saveError && <p className={styles.failure}>{saveError}</p>}
        <Button
          disabled={
            !isFormValid ||
            !isFormDirty ||
            (!isSavedUserDomainInvalid && isEmailInvalid) ||
            licenseLimitReachedFromEdit
          }
          type="submit"
          value={userId ? 'Update' : 'Create'}
          className={classnames(styles.saveButton, "button--cta")}
          data-cy="create-app-user--submit-button"
        />

        <PromptWrapper when={isFormDirty} />
      </form>
    </AsidePanel>
  );
};

CustomerAdministeredAccountsManageForm.propTypes = {
  availableRoles: PropTypes.array,
  editedFields: PropTypes.object,
  emailValidityProperties: PropTypes.object,
  groups: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object),
  history: PropTypes.object,
  isFormDirty: PropTypes.bool,
  isFormValid: PropTypes.bool,
  isSamlEnabled: PropTypes.bool,
  isSavedUserEnabled: PropTypes.bool,
  isSavedUserTriage: PropTypes.bool,
  isUserLocked: PropTypes.bool,
  licenseLimit: PropTypes.number,
  licensesRemaining: PropTypes.number,
  licenseLimitReachedFromEdit: PropTypes.bool,
  resetMFA: PropTypes.func,
  save: PropTypes.func,
  saveError: PropTypes.string,
  sendWelcomeEmail: PropTypes.func,
  setField: PropTypes.func,
  setUserId: PropTypes.func,
  unlockAccount: PropTypes.func,
  userId: PropTypes.string,
  viewRoute: PropTypes.string,
};

export default CustomerAdministeredAccountsManageForm;
