import UserMenu from '../UserMenu';
import { FlagContext } from '../../ui/context';
import { observerWrappedContainerFactory } from '../../utils/observerWrappedContainerFactory';
import { ApprovedDomainsStore, LoginStore, RouterContainer } from 'common';
import VersionStore from '../../stores/VersionStore/VersionStore';

export const mapProps = ({
  ApprovedDomainsStore,
  LoginStore,
  RouterContainer,
  flags,
  setFlags,
  VersionStore,
}) => {
  return {
    amIOnAnApprovedDomain: ApprovedDomainsStore.amIOnAnApprovedDomain,
    logout: () => LoginStore.logout(),
    adminHref: RouterContainer.href(undefined, undefined, '/admin/index.html'),
    flags,
    setFlags,
    servicesVersionInfo: VersionStore.servicesVersionInfo,
  };
};

const UserMenuContainer = observerWrappedContainerFactory({
  Context: FlagContext,
  mapProps: mapProps,
  PresentationalComponent: UserMenu,
  globalStores: {
    ApprovedDomainsStore,
    LoginStore,
    RouterContainer,
    VersionStore,
  },
});

UserMenuContainer.displayName = 'UserMenuContainer';

export default UserMenuContainer;
