import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { Authenticated } from 'common';

import SearchForm from '../../ui/registry/RegistrySearch';
import CreateAuthForm from '../CreateAuthForm';
import UserCardContainer from '../../people/cards/UserCardContainer';
import PatientCardContainer from '../../people/cards/PatientCardContainer';

import { getAddButtonText, getNextLink } from './helpers';
import { useCollection, useAuthAndSearchStoresFromParams } from './hooks';

export const Card = ({ data, getEndpoint }) => {
  return (
    <article className="card_holder full_width margin_collapse">
      {getEndpoint(data) === 'users' ? (
        <UserCardContainer user={data} openLinkNew />
      ) : (
        <PatientCardContainer patient={data} openLinkNew />
      )}
    </article>
  );
};

Card.propTypes = {
  data: PropTypes.shape({}),
  getEndpoint: PropTypes.func.isRequired,
};

const CreateAuthView = ({
  pathname,
  params,
  user,
  patient,
  selectedPatient,
  selectedPatientId,
  selectedUser,
  selectedUserId,
  setType,
  getEndpoint,
  reset,
  resetSearchStore,
  authStore,
  removePatient,
  removeUser,
  authStoreSetPatient,
  authStoreSetUser,
  isLoading,
}) => {
  const collection = useCollection({ patient, user, pathname });

  useAuthAndSearchStoresFromParams({
    patientId: params?.patientId,
    employeeId: params?.employeeId,
    userId: params?.userId,
    user,
    patient,
    setType,
    removePatient,
    removeUser,
    reset,
    resetSearchStore,
    authStoreSetPatient,
    authStoreSetUser,
  });

  return (
    <Authenticated permission="ACCESS_AUTH_CREATE">
      <div className="view-content">
        <section
          className={classnames('view-content__body registry', {
            hidden: isLoading,
          })}
        >
          <div className="row no-gutter">
            <div className="col-xs-10 col-xs-offset-1">
              <header className="page-header">
                <h2>Add Authorization</h2>
                <p
                  className={classnames({
                    hidden: collection.length > 1,
                  })}
                >
                  Add an EMR User and Patient to create an authorization.
                </p>
              </header>
              <div
                className={classnames({
                  hidden: !collection.length,
                })}
              >
                {collection.length
                  ? collection.map((i, idx) => (
                      <Card
                        data={i}
                        getEndpoint={getEndpoint}
                        key={`selected_card_${idx + 1}`}
                      />
                    ))
                  : null}
              </div>
              <div
                className={classnames({
                  hidden: collection.length > 1,
                })}
              >
                <SearchForm sourceStore={authStore} />
                <ul className="registry__search-actions list-inline">
                  <li>
                    <Link
                      data-cy="add-patient-or-emr"
                      to={getNextLink({
                        selectedUserId,
                        selectedPatientId,
                        pathname,
                      })}
                      onClick={() => {
                        if (selectedUser) authStoreSetUser(selectedUserId);
                        if (selectedPatient)
                          authStoreSetPatient(selectedPatientId);
                        resetSearchStore();
                      }}
                      type="button"
                      className="button button--cta"
                      disabled={!selectedPatient && !selectedUser}
                    >
                      {getAddButtonText({ selectedPatient, selectedUser })}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/authorizations"
                      onClick={reset}
                      className="button button--link"
                    >
                      Cancel
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                className={classnames({
                  hidden: collection.length < 2,
                })}
              >
                <CreateAuthForm store={authStore} />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Authenticated>
  );
};

CreateAuthView.propTypes = {
  pathname: PropTypes.string.isRequired,
  params: PropTypes.shape({
    patientId: PropTypes.string,
    employeeId: PropTypes.string,
    userId: PropTypes.string,
  }),
  user: PropTypes.shape({ id: PropTypes.string }),
  patient: PropTypes.shape({ id: PropTypes.string }),
  selectedPatient: PropTypes.shape({}),
  selectedPatientId: PropTypes.string,
  selectedUser: PropTypes.shape({}),
  selectedUserId: PropTypes.string,
  setType: PropTypes.func.isRequired,
  getEndpoint: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  resetSearchStore: PropTypes.func.isRequired,
  authStore: PropTypes.shape({}),
  removePatient: PropTypes.func.isRequired,
  removeUser: PropTypes.func.isRequired,
  authStoreSetPatient: PropTypes.func.isRequired,
  authStoreSetUser: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default CreateAuthView;
