import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import EmployeeBoxContainer from '../../../employee/EmployeeBoxContainer';
import PatientBoxContainer from '../../../patient/PatientBoxContainer';
import classnames from 'classnames';
import PrintTitle from '../../../ui/PrintTitle';
import styles from './index.module.scss';
import { DETAILS_NONE } from '../../print/stores/CaseCustomPrintStore/consts';

const UserPatientDetails = ({
  isDiversion,
  titleText,
  selectedDetailsOption,
}) => {
  if (isDiversion) {
    titleText = 'User Details';
  } else {
    titleText = 'User & Patient Details';
  }

  const renderDetails = print => (
    <div
      className={classnames(styles.userPatient, {
        forScreen: !print,
        forPrint: print,
      })}
    >
      <EmployeeBoxContainer forPage="case" />
      {!isDiversion && <PatientBoxContainer forPage="case" />}
    </div>
  );

  return (
    <section className={styles.container}>
      {selectedDetailsOption !== DETAILS_NONE && (
        <PrintTitle>{titleText}</PrintTitle>
      )}
      {renderDetails()}
      {renderDetails(selectedDetailsOption !== DETAILS_NONE)}
    </section>
  );
};

const personType = {
  id: PropTypes.string,
  fullName: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  middleName: PropTypes.string,
  dateOfBirth: PropTypes.string,
  dateOfBirthText: PropTypes.string,
  dateOfDeath: PropTypes.string,
  deceased: PropTypes.bool,
  sex: PropTypes.string,
  source: PropTypes.string,
  addresses: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  contacts: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  phoneNumbers: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  emails: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.string),
};

const userType = {
  departments: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  roles: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  recentRoleName: PropTypes.string,
  recentOrganization: PropTypes.string,
  recentDepartment: PropTypes.string,
  terminationDate: PropTypes.string,
  accounts: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  userId: PropTypes.string,
  instance: PropTypes.string,
  tags: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  ...personType,
};

const patientType = {
  references: PropTypes.oneOfType([
    mobxPropTypes.arrayOrObservableArrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  medicalRecordNumbers: PropTypes.oneOfType([
    mobxPropTypes.arrayOrObservableArrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  patientId: PropTypes.string,
  labels: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  ...personType,
};

UserPatientDetails.propTypes = {
  user: PropTypes.shape(userType),
  userSnapshot: PropTypes.shape(userType),
  patient: PropTypes.shape(patientType),
  patientSnapshots: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape(patientType)),
  personLink: PropTypes.func,
  userId: PropTypes.string,
  patientId: PropTypes.string,
  isDiversion: PropTypes.bool,
  showAuthorizations: PropTypes.bool,
  print: PropTypes.bool,
  titleText: PropTypes.string,
  selectedDetailsOption: PropTypes.string,
};

UserPatientDetails.defaultProps = {
  user: {},
  userSnapshot: {},
  patient: {},
  patientSnapshots: [],
  personLink: () => {},
  userId: '',
  patientId: '',
  isDiversion: false,
  showAuthorizations: false,
  print: false,
  titleText: '',
  selectedDetailsOption: '',
};

export default UserPatientDetails;
