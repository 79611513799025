import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const getIcon = {
  danger: 'icon-error',
  flag: 'icon-flag',
  lock: 'icon-lock',
  secondary: 'icon-info',
  success: 'icon-check_circle',
  warning: 'icon-warning',
  multiPerson: 'icon-groups',
};

const SysAlert = ({
  children,
  visible,
  hideable,
  // eslint-disable-next-line no-unused-vars
  onHide,
  timeout,
  level,
  position,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [animate, setAnimate] = useState(false);

  const hide = () => {
    setIsVisible(false);
    setAnimate(true);
  };

  useEffect(() => {
    if (timeout) {
      const timer = setTimeout(() => {
        hide();
      }, timeout);

      return () => clearTimeout(timer);
    }
  }, [timeout]);

  const prevVal = useRef();

  useEffect(() => {
    prevVal.current = visible;
  }, [visible]);

  useEffect(() => {
    setIsVisible(visible);
    if (prevVal.current !== visible) setAnimate(true);
  }, [visible, prevVal]);

  const closeAlert = e => {
    e.preventDefault();
    e.stopPropagation();
    hide();
  };

  const renderCloseButton = () => {
    if (hideable) {
      return (
        <span onClick={closeAlert} className="controls">
          <i className="material-icons icon-close" />
        </span>
      );
    }
  };

  const icon = 'material-icons ' + getIcon[level];
  if (!icon) {
    console.warn(level + ' is not a valid level for SysAlert');
  }

  const classes = classnames('sysAlert', level, position, {
    active: isVisible,
    inactive: animate && !isVisible,
  });

  return (
    <div className={classes}>
      <div>
        <p>
          <i className={icon} />
          {children}
        </p>
        {renderCloseButton()}
      </div>
    </div>
  );
};

SysAlert.defaultProps = {
  visible: true,
  hideable: true,
  onHide: function() {},
  level: 'secondary',
};

SysAlert.propTypes = {
  visible: PropTypes.bool,
  level: PropTypes.string,
  position: PropTypes.string,
  hideable: PropTypes.bool,
  onHide: PropTypes.func,
  timeout: PropTypes.number,
  children: PropTypes.node,
};

export default SysAlert;
